import React, { Component } from "react";
import { Modal } from "antd";
import { withRouter } from "react-router-dom";
import IpoForm from "../forms/Ipo/IpoForm/IpoForm";
import NumberFormat from "react-number-format";

class BidIpoModal extends Component {
  state = {
    loading: false,
    visible: false,
  };

  //   handleOk = () => {
  //     this.setState({ loading: true });
  //     setTimeout(() => {
  //       this.setState({ loading: false, visible: false });
  //     }, 3000);
  //   };

  onSubmitIpoBid = (values) => {
    const { onSubmitBid } = this.props;
    onSubmitBid(values);
  };
  render() {
    const {
      visible,
      handleCancel,
      selectEntityOptions,
      editIpoBidHandler,
      showEditIpoBidForm,
      selectedIpo,
      currentUser,
    } = this.props;

    return (
      <div>
        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          {currentUser.role &&
            currentUser.role.some((role) => role.title === "Investor") &&
            currentUser.realestate_approved === "Approved" &&
            selectedIpo.type === "180" &&
            selectedIpo.status === "Active" &&
            !selectedIpo.mybid && (
              <IpoForm
                onSubmit={this.onSubmitIpoBid}
                selectEntityOptions={selectEntityOptions}
                currentDeal={selectedIpo}
                ipo={selectedIpo}
                buttonTitle="Submit"
              />
            )}
          {/* {currentUser.role &&
            currentUser.role.some((role) => role.title === "Investor") &&
            currentUser.realestate_approved === "Approved" &&
            selectedIpo.type === "180" &&
            selectedIpo.status === "Suspended" &&
            !selectedIpo.mybid && (
              <IpoForm
                onSubmit={this.onSubmitIpoBid}
                selectEntityOptions={selectEntityOptions}
                currentDeal={selectedIpo}
                ipo={selectedIpo}
                buttonTitle={
                  selectedIpo && selectedIpo.mybid ? "Update" : "Submit"
                }
              />
            )} */}
          {currentUser.role &&
            currentUser.role.some((role) => role.title === "Investor") &&
            currentUser.realestate_approved === "Approved" &&
            selectedIpo.type === "market" &&
            !selectedIpo.myindication && (
              <IpoForm
                onSubmit={this.onSubmitIpoBid}
                selectEntityOptions={selectEntityOptions}
                currentDeal={selectedIpo}
                buttonTitle={
                  selectedIpo && selectedIpo.myindication ? "Update" : "Submit"
                }
              />
            )}
          {selectedIpo.type === "180" &&
            selectedIpo.mybid &&
            showEditIpoBidForm && (
              <IpoForm
                onSubmit={this.onSubmitIpoBid}
                selectEntityOptions={selectEntityOptions}
                currentDeal={selectedIpo}
                buttonTitle={
                  selectedIpo && selectedIpo.mybid ? "Update" : "Submit"
                }
                initialValues={
                  selectedIpo &&
                  selectedIpo.mybid && {
                    entity_id: selectedIpo.mybid.entity_id,
                    share_count: selectedIpo.mybid.share_count,
                    investment_amount: selectedIpo.mybid.investment_amount,
                  }
                }
              />
            )}
          {selectedIpo.type === "market" &&
            selectedIpo.myindication &&
            showEditIpoBidForm && (
              <IpoForm
                onSubmit={this.onSubmitIpoBid}
                selectEntityOptions={selectEntityOptions}
                currentDeal={selectedIpo}
                buttonTitle={
                  selectedIpo && selectedIpo.myindication ? "Update" : "Submit"
                }
                initialValues={
                  selectedIpo &&
                  selectedIpo.myindication && {
                    investment_amount:
                      selectedIpo.myindication.investment_amount,
                  }
                }
              />
            )}
          {selectedIpo.type === "180" &&
            !showEditIpoBidForm &&
            // !selectedIpo.status &&
            selectedIpo.mybid && (
              <div style={{ height: "200px" }}>
                <h3>Change Bid</h3>
                <div>
                  <b>Share Count:</b> {selectedIpo.mybid.share_count}
                </div>
                <div className="mb-2">
                  <b>Investment Amount:</b>
                  <NumberFormat
                    value={selectedIpo.mybid.investment_amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-medium btn-block mt-4"
                  onClick={() => editIpoBidHandler()}
                >
                  Change Bid
                </button>
              </div>
            )}

          {selectedIpo.type === "market" &&
            !showEditIpoBidForm &&
            selectedIpo.myindication && (
              <div style={{ height: "200px" }}>
                <h3>Change Indication</h3>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-medium btn-block mt-4"
                  onClick={() => editIpoBidHandler()}
                >
                  Change Indication
                </button>
              </div>
            )}

          {currentUser.role &&
            currentUser.role.some((role) => role.title === "Investor") &&
            currentUser.realestate_approved === "Pending" && (
              <div className="md-deal-form">
                <div className="form-normal dealBidMessage">
                  <div className="deal-form-content">
                    <p className="bidMessage">
                      Complete your profile to bid for IPO
                    </p>
                    <button
                      className="btn btn-outline-primary btn-block"
                      onClick={() => this.props.history.push("/user/profile")}
                    >
                      Go to Profile
                    </button>
                  </div>
                </div>
              </div>
            )}
          {selectedIpo ? (
            selectedIpo.status === "Closed" ||
            selectedIpo.status === "Finished" ? (
              <div className="md-deal-form">
                <div className="form-normal dealBidMessage">
                  <div
                    className="deal-form-content"
                    style={{ textAlign: "center" }}
                  >
                    <p
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                      className="bidMessage"
                    >
                      This IPO has closed.
                    </p>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}
          {selectedIpo ? (
            selectedIpo.status === "Suspended" ? (
              <div className="md-deal-form">
                <div className="form-normal dealBidMessage">
                  <div
                    className="deal-form-content"
                    style={{ textAlign: "center" }}
                  >
                    <p
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                      className="bidMessage"
                    >
                      This IPO is already suspended.
                    </p>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default withRouter(BidIpoModal);
