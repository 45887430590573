import React from "react";

export const emailPendingMessageRegister =
  "Thank you for your registration. We are currently reviewing your account. We will notify you by email when the account is approved and ready to upload deals.";

export const renderData = (value) => {
  return `$${value}`;
};

export const renderRefreshText = (deals) => {
  let filtered = [];
  for (let index in deals) {
    if (
      !deals[index].last_price ||
      !deals[index].capital_raise ||
      !deals[index].raise_amount
    ) {
      filtered.push(deals[index]);
    }
  }

  return filtered.length > 0 ? false : true;
};

export const customStyles = {
  rows: {
    style: {
      height: "120px", // override the row height
    },
  },
};

export const renderIndividualName = (entity) => {
  let name = "";
  for (let key in entity.entity_individuals) {
    if (name.length > 0) {
      return (name +=
        " " +
        "&" +
        " " +
        entity.entity_individuals[key].given_name +
        " " +
        entity.entity_individuals[key].sur_name);
    }

    name +=
      entity.entity_individuals[key].given_name +
      " " +
      entity.entity_individuals[key].sur_name;
  }

  return name;
};

export const renderEntity = (entity) => {
  if (entity.entity_company !== null)
    return <p>{entity.entity_company.name}</p>;
  else if (entity.entity_trust !== null)
    return <p>{entity.entity_trust.name}</p>;
  else if (entity.entity_individual !== null)
    return <p>{renderIndividualName(entity)}</p>;
};
