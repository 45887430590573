import React, { Component } from "react";
import ForgotPasswordForm from "./ForgotPassowordForm/ForgotPasswordForm";
import { forgotPassword } from "../../../redux/actions/authActions";
import { connect } from "react-redux";

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = async values => {
    let { forgotPasswordSubmit, history } = this.props;
    await forgotPasswordSubmit(values, history);
  };

  render() {
    return <ForgotPasswordForm onSubmit={this.handleSubmit} />;
  }
}

const mapDispatchToProps = dispatch => ({
  forgotPasswordSubmit: (data, history) =>
    forgotPassword(dispatch, data, history)
});

export default connect(null, mapDispatchToProps)(ForgotPasswordContainer);
