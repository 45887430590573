import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import HomeRegisterForm from "./InvestorRegisterForm";
import { registerUser } from "../../../redux/actions/authActions";
import InvestorRegisterForm from "./InvestorRegisterForm";
import BrokerRegisterForm from "./BrokerRegisterForm";

class HomeRegisterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploaded: []
    };
  }

  handleSubmit = values => {
    values.password_confirmation = values.password;
    const { history, role } = this.props;
    let { uploaded } = this.state;
    if (uploaded) {
      values.logo = uploaded;
    }
    this.props.register({ ...values, role }, history);
  };

  onLogoUpload = files => {
    this.setState({ uploaded: files });
  };

  render() {
    const { role } = this.props;
    return (
      <Fragment>
        {role === "Investor" && (
          <InvestorRegisterForm
            onSubmit={this.handleSubmit}
            role={role}
            onLogoUpload={this.onLogoUpload}
          />
        )}
        {role === "Broker" && (
          <BrokerRegisterForm
            onSubmit={this.handleSubmit}
            role={role}
            onLogoUpload={this.onLogoUpload}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  optedType: state.register.optedType,
  regState: state.register
});

const mapDispatchToProps = dispatch => ({
  register: (payload, history) => registerUser(dispatch, payload, history)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeRegisterContainer);
