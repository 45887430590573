import { ApiUrl } from "./settings";
// import { store } from '../redux/store';
// import actions from '../redux/auth/actions';

const customHeader = () => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: "Bearer " + localStorage.getItem("auth_token") || undefined,
  "app-type": "web",
});

const base = (method, url, data = {}, configMultipart = null) => {
  let body = undefined;
  if (method !== "get" && !configMultipart) {
    body = JSON.stringify(data);
  }

  let headers = customHeader;
  if (configMultipart) {
    headers = configMultipart;
  } else {
    headers = customHeader;
  }

  return fetch(`${ApiUrl}${url}`, {
    method,
    headers: headers(),
    body: body,
  })
    .then((response) => response.json())

    .catch((error) => {
      return { error: "server error" };
    });
};
const SuperFetch = {};
["get", "post", "put", "delete"].forEach((method) => {
  SuperFetch[method] = base.bind(null, method);
});
export default SuperFetch;
