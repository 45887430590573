import React from "react";
import { BaseUrl } from "../../../services/settings";
import moment from "moment";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import DataNewTable from "react-data-table-component";
import { connect } from "react-redux";
// import DealActions from "../../../services/dealService";
import DealActions from "../../../redux/actions/dealActions";

class AllDeals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchAllPublic180Deals();
    this.props.fetchAllPublicMarketDeals();
  }

  render() {
    const columns = [
      {
        name: "Company",
        selector: "company_name",
        sortable: false,
        className: "company no-wrap",
        // style: { minWidth: "120px" },
        cell: (row) => (
          <div className="no-wrap">
            <div className="company-name">
              <span>{row.company_name}</span>
            </div>
          </div>
        ),
      },
      {
        name: "ASX Code",
        selector: "market_capital",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.asx_code}</div>,
      },
      {
        name: "Last Price",
        selector: "last_price",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.last_price}</div>,
      },

      {
        name: "Cap Raise Price",
        selector: "capital_raise",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.capital_raise}</div>,
      },
      {
        name: "Discount",
        selector: "discount",
        sortable: false,
        left: true,
        cell: (row) => <div>{parseInt(row.discount)}</div>,
      },
      {
        name: "Market Capitalisation",
        selector: "market_capital",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.market_capital}</div>,
      },
      {
        name: "Bids Due",
        selector: "bids_due",
        sortable: false,
        left: true,
        cell: (row) => (
          <div>
            {moment(row.bids_due, "DD/MM/YYYY").format("MMM. DD, YYYY")}
          </div>
        ),
      },
    ];
    const { public180Deals } = this.props;
    let Deals180 = public180Deals;
    return (
      <main className="page-main">
        <div className="bl-form-regitester lead_bl-form-regitester">
          <div className="bl-community">
            <div className="container">
              <div className="title">
                <div className="row">
                  <div className="col-sm-9">
                    <h1 className="lead_title">All deals</h1>
                  </div>
                </div>
              </div>
              <div className="md-tabs tab-deals position-relative">
                <div className="tab-nav">
                  <ul className="nav" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link-top active"
                        data-toggle="tab"
                        href="#Placements"
                        role="tab"
                      >
                        180 Placements
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link-top"
                        data-toggle="tab"
                        href="#marketPlacements"
                        role="tab"
                      >
                        Market Placements
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="tab-pane fade show active"
                  id="Placements"
                  role="tabpanel"
                >
                  <DataTable
                    noHeader
                    overflowY
                    highlightOnHover
                    responsive={true}
                    columns={columns}
                    data={
                      this.props.public180Deals && this.props.public180Deals
                    }
                    // selectableRows
                    style={{ width: "100%" }}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="marketPlacements"
                  role="tabpanel"
                >
                  <DataTable
                    noHeader
                    overflowY
                    highlightOnHover
                    responsive={true}
                    columns={columns}
                    data={
                      this.props.publicMarketDeals &&
                      this.props.publicMarketDeals
                    }
                    // selectableRows
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const { fetchAllPublic180Deals, fetchAllPublicMarketDeals } = DealActions;

const mapDispatchToProps = (dispatch) => ({
  fetchAllPublic180Deals: (payload, history) =>
    fetchAllPublic180Deals({ dispatch, payload, history }),
  fetchAllPublicMarketDeals: (payload, history) =>
    fetchAllPublicMarketDeals({ dispatch, payload, history }),
});
const mapStateToProps = (state) => {
  return {
    public180Deals: state.deals.public180Deals,
    publicMarketDeals: state.deals.publicMarketDeals,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllDeals);
