import React, { Component } from "react";
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";
import { resetPassword } from "../../../redux/actions/authActions";
import { connect } from "react-redux";

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = (values) => {
    let { token } = this.props.match.params;
    let { history } = this.props;
    this.props.resetPasswordSubmit({ ...values, token }, history);
  };

  render() {
    return <ResetPasswordForm onSubmit={this.handleSubmit} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetPasswordSubmit: (data, history) =>
    resetPassword(data, history, dispatch),
});

export default connect(null, mapDispatchToProps)(ResetPasswordContainer);
