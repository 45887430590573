import React, { Component } from "react";
import { FormField } from "../layout/FormField";
import { required, email } from "redux-form-validators";
import { Field, reduxForm, Form } from "redux-form";
import NewDropzone from "../common/NewDropzone";
import ButtonLoader from "../common/ButtonLoader";
import { withRouter } from "react-router-dom";

const emailOptions = ["Yes", "No"];

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validate: false,
    };
  }

  validate = () => {
    this.setState({ validate: true });
  };

  render() {
    let { handleSubmit, form } = this.props;

    return (
      <main className="page-signup page-create-account brocker-account ">
        <div className="container">
          <div class="form-normal form-create-account">
            <h1>Profile</h1>
            <Form onSubmit={handleSubmit}>
              <div className="all-fields">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">First Name</label>
                      <div class="field-input">
                        <Field
                          className="form-control"
                          name="first_name"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Last Name</label>
                      <div class="field-input">
                        <Field
                          className="form-control"
                          name="last_name"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Phone</label>
                      <div class="field-input">
                        <Field
                          className="form-control"
                          name="phone"
                          component={FormField}
                          type="number"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Email</label>
                      <div class="field-input">
                        <Field
                          className="form-control"
                          type="text"
                          name="email"
                          component={FormField}
                          validate={[required(), email()]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Company Name</label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="company_name"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="title">Description</label>
                      <div className="field-input">
                        <Field
                          className="form-control form-control-textarea"
                          name="description"
                          component={"textarea"}
                          type="textarea"
                          validate={[required()]}
                        />
                      </div>
                      <p style={{ fontSize: "12px", color: "red " }}>
                        {this.state.validate &&
                          form &&
                          form.ProfileForm &&
                          form.ProfileForm.syncErrors &&
                          form.ProfileForm.syncErrors.description &&
                          `This field is ${form.ProfileForm.syncErrors.description}`}
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    {/* <InputFile
                        onUpload={onLogoUpload}
                        accept="image/*"
                        previewImageUrl={
                          this.props.preview_url ||
                          this.props.currentUser.logo_url
                        }
                      /> */}
                    <NewDropzone
                      multiple={false}
                      buttonLabel="Upload Company Logo"
                      title={"Upload Company Logo"}
                      onUpload={this.props.onLogoUpload}
                      singleDocuments={this.props.currentUser.media}
                      onDocumentDeleteHandler={
                        this.props.onDocumentDeleteHandler
                      }
                      open={this.props.open}
                      close={this.props.close}
                      show={this.props.show}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-8">
                    <div className="field">
                      <label className="title">
                        Receive email notifications for bids
                      </label>
                      <div className="field-input form-group row">
                        {emailOptions.map((element, key) => {
                          return (
                            <Field
                              key={key}
                              name="receive_bids_email"
                              component={FormField}
                              type="radio"
                              value={element}
                              label={element}
                              className={`radio-inline`}
                            />
                          );
                        })}
                      </div>
                      <p style={{ fontSize: "12px", color: "red " }}>
                        {this.state.validate &&
                          form &&
                          form.ProfileForm &&
                          form.ProfileForm.syncErrors &&
                          form.ProfileForm.syncErrors.email_accept &&
                          `This field is ${form.ProfileForm.syncErrors.email_accept}`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="button-group">
                    <div className="col-xs-12 col-sm-6 col-md-6">
                      <ButtonLoader
                        onClick={this.validate}
                        type="submit"
                        className="btn btn-primary btn-medium"
                      >
                        Save Settings
                      </ButtonLoader>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-medium"
                        onClick={() => this.props.history.push("/")}
                      >
                        Go to Main Page
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </main>
    );
  }
}

export default reduxForm({
  form: "ProfileForm",
})(withRouter(ProfileForm));
