import SuperFetch from './superfetch';

class AssetService {

  getAll = async formInputs => {
    return await SuperFetch.get('assets', formInputs).then(response => {
      return response;
    });
  };

}
export default new AssetService();