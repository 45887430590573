import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import dealActions from "../../../../../redux/actions/dealActions";
import EntityActions from "../../../../../redux/actions/entityActions";

class BidSuccessMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    let { fetchAllEntities, getSingleDealData, match } = this.props;

    if (localStorage.getItem("auth_token")) {
      fetchAllEntities();
    }
    getSingleDealData(match.params.uuid);
  };

  hinPinExist = () => {
    const { currentDeal, existingEntity } = this.props;
    return (
      currentDeal &&
      currentDeal.bids &&
      currentDeal.bids.map((bid) => {
        return (
          existingEntity &&
          existingEntity.map((entity) => {
            if (bid.entity_id === entity.id) {
              if (!entity.pid || !entity.hin) {
                return (
                  <p style={{ color: "red" }}>
                    For this entity, you have not provided a PID or HIN number.
                    Please{" "}
                    <Link to={`/user/edit-entity/${entity.uuid}`}>
                      click here
                    </Link>{" "}
                    update your information in order to receive an allocation of
                    shares.
                  </p>
                );
              }
            }
          })
        );
      })
    );
  };

  render() {
    let {
      match: { params },
      currentDeal,
      existingEntity,
    } = this.props;

    return (
      <div>
        <main className="page-main page-signup page-create-account">
          <div className="container">
            <div class="form-normal form-create-account">
              <div style={{ textAlign: "center" }}>
                <h3>
                  {params.type === "indication"
                    ? "Thank you for submitting your bid, we will contact the lead  broker and attempt to get you an allocation."
                    : "Thank you for submitting your bid, we will get back to you shortly with an allocation."}
                </h3>
                {this.hinPinExist()}
                <a href="/user/deals#currentBids">View My Bids</a>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    existingEntity: state.entities.entities,
    currentDeal: state.deals.singleDeal,
  };
};

const { fetchEntities } = EntityActions;

let { singlePublicDeal } = dealActions;

const mapDispatchToProps = (dispatch) => ({
  fetchAllEntities: (payload, history) =>
    fetchEntities({ dispatch, payload, history }),
  getSingleDealData: (payload) => singlePublicDeal(payload, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BidSuccessMessage));
