import React, { Component } from "react";
import { Modal, Button } from "antd";
import DealsViewForm from "../forms/Deals/DealView/DealsViewForm/DealsViewForm";
import { Form, reduxForm, Field } from "redux-form";
import { withRouter } from "react-router-dom";
import IpoForm from "../forms/Ipo/IpoForm/IpoForm";
import { FormField } from "../layout/FormField";
import { required } from "redux-form-validators";
import ButtonLoader from "./ButtonLoader";

class BidDirectorShares extends Component {
  state = {
    loading: false,
    visible: false,
  };

  onSubmitIpoBid = (values) => {
    const { onSubmitBid } = this.props;
    onSubmitBid(values);
  };

  editIpoBidHandler = () => {
    this.setState({ showEditIpoBidForm: true });
  };

  render() {
    const {
      visible,
      handleCancel,
      handleSubmit,
      selectedDirectorShare,
      editIpoBidHandler,
      showEditShareIndicationForm,
    } = this.props;
    return (
      <div>
        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <h3>Indication of interest</h3>
          <p>
            This is not a 180 deal, if you have interest we will contact the
            lead broker and attempt to get you an allocation
          </p>
          <div className="deal-form-content">
            {!selectedDirectorShare.myindication && (
              <Form onSubmit={handleSubmit}>
                <div className="all-fields">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="field">
                        <div className="field-input">
                          <label className="title">Investment Amount</label>
                          <div className="field-input">
                            <Field
                              className="form-control"
                              name="investment_amount"
                              component={FormField}
                              onChange={this.onInvestmentChangeHandler}
                              type="number"
                              validate={[required()]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="button-group">
                    <div className="col-sm-12">
                      <ButtonLoader
                        type="submit"
                        style={{ marginTop: "10px" }}
                        className="btn btn-primary btn-medium"
                      >
                        Submit
                      </ButtonLoader>
                    </div>
                  </div>
                </div>
              </Form>
            )}
            {selectedDirectorShare.myindication && showEditShareIndicationForm && (
              <Form onSubmit={handleSubmit}>
                <div className="all-fields">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="field">
                        <div className="field-input">
                          <label className="title">Investment Amount</label>
                          <div className="field-input">
                            <Field
                              className="form-control"
                              name="investment_amount"
                              component={FormField}
                              onChange={this.onInvestmentChangeHandler}
                              type="number"
                              validate={[required()]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="button-group">
                    <div className="col-sm-12">
                      <ButtonLoader
                        type="submit"
                        style={{ marginTop: "10px" }}
                        className="btn btn-primary btn-medium"
                      >
                        Submit
                      </ButtonLoader>
                    </div>
                  </div>
                </div>
              </Form>
            )}
            {selectedDirectorShare.myindication &&
              !showEditShareIndicationForm && (
                <div style={{ height: "200px" }}>
                  <h3>Change Indication</h3>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-medium btn-block mt-4"
                    onClick={() => editIpoBidHandler()}
                  >
                    Change Indication
                  </button>
                </div>
              )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(
  reduxForm({ form: "BidDirectorShares", enableReinitialize: true })(
    BidDirectorShares
  )
);
