import React, { Component } from "react";
import { Modal } from "antd";
import DealsViewForm from "../forms/Deals/DealView/DealsViewForm/DealsViewForm";
import { Form, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";

class BidNowModal extends Component {
  state = {
    loading: false,
    visible: false,
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  render() {
    const {
      visible,
      handleCancel,
      currentUser,
      onSubmitBid,
      currentDeal,
      bidStatus,
      selectEntityOptions,
      handleSubmit,
      editBidHandler,
      showEditForm,
    } = this.props;

    return (
      <div>
        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          {currentUser.role &&
            currentUser.role.some((role) => role.title === "Investor") &&
            currentUser.realestate_approved === "Approved" &&
            currentDeal.status === "Active" &&
            !currentDeal.myBid && (
              <DealsViewForm
                currentUser={currentUser}
                onSubmit={onSubmitBid}
                selectEntityOptions={selectEntityOptions}
                capRaisePrice={currentDeal && currentDeal.capital_raise}
                currentDeal={currentDeal}
                bidStatus={bidStatus}
                modal={true}
                title={
                  currentDeal && currentDeal.myBid ? "Update Bid" : "Submit Bid"
                }
                buttonTitle={
                  currentDeal && currentDeal.myBid ? "Update" : "Submit"
                }
                initialValues={
                  currentDeal &&
                  currentDeal.myBid && {
                    entity_id: currentDeal.myBid.entity_id,
                    share_count: currentDeal.myBid.share_count,
                    investment_amount: currentDeal.myBid.investment_amount,
                  }
                }
              />
            )}

          {showEditForm && (
            <DealsViewForm
              currentUser={currentUser}
              onSubmit={onSubmitBid}
              selectEntityOptions={selectEntityOptions}
              capRaisePrice={currentDeal && currentDeal.capital_raise}
              currentDeal={currentDeal}
              bidStatus={bidStatus}
              modal={true}
              title={
                currentDeal && currentDeal.myBid ? "Update Bid" : "Submit Bid"
              }
              buttonTitle={
                currentDeal && currentDeal.myBid ? "Update" : "Submit"
              }
              initialValues={
                currentDeal &&
                currentDeal.myBid && {
                  entity_id: currentDeal.myBid.entity_id,
                  share_count: currentDeal.myBid.share_count,
                  investment_amount: currentDeal.myBid.investment_amount,
                }
              }
            />
          )}
          {!showEditForm &&
            currentDeal.myBid &&
            currentDeal.status !== "Closed" && (
              <div style={{ height: "200px" }}>
                <h3>Change Bid</h3>

                <div className="mb-2">
                  <b>Investment Amount:</b>
                  <NumberFormat
                    value={currentDeal.myBid.investment_amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-medium btn-block mt-4"
                  onClick={() => editBidHandler(currentDeal.uuid)}
                >
                  Change Bid
                </button>
              </div>
            )}

         

          {currentUser.role &&
            currentUser.role.some((role) => role.title === "Investor") &&
            currentUser.realestate_approved === "Pending" && (
              <div className="md-deal-form">
                <div className="form-normal dealBidMessage">
                  <div className="deal-form-content">
                    <p className="bidMessage">
                      Complete your profile to bid for deals
                    </p>
                    <button
                      className="btn btn-outline-primary btn-block"
                      onClick={() => this.props.history.push("/user/profile")}
                    >
                      Go to Profile
                    </button>
                  </div>
                </div>
              </div>
            )}
          {currentDeal ? (
            currentDeal.status === "Closed" ||
            currentDeal.status === "Finished" ? (
              <div className="md-deal-form">
                <div className="form-normal dealBidMessage">
                  <div
                    className="deal-form-content"
                    style={{ textAlign: "center" }}
                  >
                    <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                      This deal is now closed.
                    </p>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}
          {currentDeal ? (
            currentDeal.status === "Suspended" ? (
              <div className="md-deal-form">
                <div className="form-normal dealBidMessage">
                  <div
                    className="deal-form-content"
                    style={{ textAlign: "center" }}
                  >
                    <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                      This deal is already suspened.
                    </p>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default withRouter(reduxForm({ form: "BidNowModal" })(BidNowModal));
