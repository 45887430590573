import {
  START_BUTTON_LOADING,
  STOP_BUTTON_LOADING,
  START_LOADER,
  STOP_LOADER,
} from "./constants";

const startButtonLoading = {
  type: START_BUTTON_LOADING,
};

const stopButtonLoading = {
  type: STOP_BUTTON_LOADING,
};

const startLoader = ({ dispatch, type }) => {
  return dispatch({ type: START_LOADER, type });
};

const stopLoader = ({ dispatch, type }) =>
  dispatch({ type: STOP_LOADER, type });

export { startButtonLoading, stopButtonLoading, startLoader, stopLoader };
