import actionTypes from "../actions/entityActions";
var moment = require("moment");

const initialState = {
  entities: [],
  loading: false,
  singleEntity: {},
  beneficial_owners: [],
};

const entity = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.FETCH_ALL_ENTITIES_SUCCESS:
      return {
        ...state,
        entities: payload,
      };

    case actionTypes.FETCH_SINGLE_ENTITY_SUCCESS:
      return {
        ...state,
        singleEntity: { ...state.singleEntity, ...payload.data },
      };

    // case actionTypes.BENEFICIAL_OWNERS:
    //   return {
    //     ...state,
    //     beneficial_owners: [
    //       ...state.beneficial_owners,
    //       ...payload.map((owner) => {
    //         var date = moment(owner.dob, "DD/MM/YYYY").format("YYYY-MM-DD");
    //         owner.dob = new Date(date);
    //         return owner;
    //       }),
    //     ],
    //   };

    case actionTypes.CREATE_ENTITY_SUCCESS:
      return {
        ...state,
        entities: state.entities.push(payload),
      };

    case actionTypes.UNMOUNT_SINGLE_ENTITY_SUCCESS:
      return {
        ...state,
        singleEntity: {},
      };

    default:
      return state;
  }
};

export default entity;
