import React, { Component } from "react";
import { Modal } from "antd";
import moment from "moment";
import NumberFormat from "react-number-format";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FileUpload } from "../../../common/Graphics";
import DealsViewForm from "./DealsViewForm/DealsViewForm";
import { connect } from "react-redux";
import EntityActions from "../../../../redux/actions/entityActions";
import dealActions from "../../../../redux/actions/dealActions";
import { withRouter } from "react-router-dom";
import BidActions from "../../../../redux/actions/bids.Actions";
import { BaseUrl } from "../../../../services/settings";
import ModalComponent from "../../../common/Modal";

const queryString = require("query-string");

class DealView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectEntityOptions: [],
      open: false,
      openLightBox: false,
      modalOpen: false,
      file: "",
    };
  }

  openLightBox = (file) => {
    this.setState({ openLightBox: true, file });
  };

  closeLightBox = () => {
    this.setState({ openLightBox: false });
  };

  modalOpen = () => {
    this.setState({ modalOpen: true });
  };

  modalClose = () => {
    this.setState({ modalOpen: false });
  };

  componentDidMount = () => {
    let {
      getSingleDealData,
      fetchAllEntities,
      match,
      currentUser,
      getDealStatus,
    } = this.props;
    if (currentUser && localStorage.getItem("auth_token")) {
      fetchAllEntities();
      getDealStatus(match.params.uuid);
    }
    getSingleDealData(match.params.uuid);
  };

  renderIndividualName = (entity) => {
    let name = "";
    for (let key in entity.entity_individuals) {
      if (name.length > 0) {
        return (name +=
          " " +
          "&" +
          " " +
          entity.entity_individuals[key].given_name +
          " " +
          entity.entity_individuals[key].sur_name);
      }

      name +=
        entity.entity_individuals[key].given_name +
        " " +
        entity.entity_individuals[key].sur_name;
    }

    return name;
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.existingEntity !== nextProps.existingEntity &&
      nextProps.currentUser.id
    ) {
      this.setState({
        selectEntityOptions:
          nextProps.existingEntity &&
          nextProps.existingEntity.map((entity) => {
            if (entity.entity_company !== null)
              return {
                value: entity.id,
                text: entity.entity_company.name,
                value: entity.id,
              };
            else if (entity.entity_trust !== null)
              return {
                value: entity.id,
                text: entity.entity_trust.name,
                value: entity.id,
              };
            else if (entity.entity_individual !== null)
              return {
                value: entity.id,
                text: this.renderIndividualName(entity),
                value: entity.id,
              };
          }),
      });
    }
  };

  cancelBidHanler = (id) => {
    let { cancelBid, history } = this.props;

    this.setState({ open: false });
    cancelBid({ bidId: id }, history);
  };

  editBidHandler = (id) => {
    const { location } = this.props;

    const parsed = queryString.parse(location.search);
    if (parsed) {
      if (parseInt(parsed.bid) === 1) {
        this.props.history.push(`/user/edit-bid/${id}?bid=1`);
      } else {
        this.props.history.push(`/user/edit-bid/${id}`);
      }
    } else {
      this.props.history.push(`/user/edit-bid/${id}`);
    }
  };

  onSubmitBid = async (values) => {
    let {
      createBidSubmit,
      currentDeal,
      bidStatus,
      history,
      match,
    } = this.props;
    if (!bidStatus.myBid) {
      Modal.confirm({
        title: "Confirm",
        width: 700,
        okButtonProps: {
          type: "primary",
          size: "large",
        },
        cancelButtonProps: {
          size: "large",
        },
        icon: <ExclamationCircleOutlined />,
        content: (
          <p className="pconfirm">
            By submitting a bid, you are making a binding and irrevocable offer
            to acquire the securities, on the terms set out in the information
            available here, in the{" "}
            {currentDeal && currentDeal.term_sheets ? (
              <a
                target="_blank"
                href={`${BaseUrl}storage/${currentDeal.term_sheets.id}/${currentDeal.term_sheets.file_name}`}
              >
                Term sheet
              </a>
            ) : (
              "Term sheet"
            )}{" "}
            and in the{" "}
            {
              <a
                target="_blank"
                href="https://180.instaging.net/investor/terms&conditions"
              >
                Investor Terms
              </a>
            }
            . You confirm you make each of the representations and warranties
            specified in the Investor Terms.Your bid does not guarantee that you
            will receive an allocation. If your bid is successful, you will be
            required to pay to acquire the securities in accordance with your
            bid and the Investor Terms, subject to any scale back.
          </p>
        ),
        okText: "Confirm",
        cancelText: "Cancel",
        async onOk() {
          await createBidSubmit(
            {
              ...values,
              property_id: currentDeal.id,
              deal_uuid: match.params.uuid,
            },
            history
          );
        },
        onCancel() {},
      });
    }
  };

  render() {
    let { currentDeal, currentUser, bidStatus, location } = this.props;
    const parsed = queryString.parse(location.search);

    return (
      <main className="page-deal-add-doc">
        <div className="md-top-deal">
          <div className="container">
            <div className="deal-inner">
              <div className="row">
                <div className="col-md-2">
                  <div className="logo">
                    <div className="inner">
                      <img
                        src={
                          currentDeal &&
                          currentDeal.picture &&
                          `${BaseUrl}/storage/${currentDeal.picture.id}/${currentDeal.picture.file_name}`
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="information">
                    <h1>
                      {currentDeal &&
                        currentDeal.fund_name}
                    </h1>
                    {/* <p className="sub-title">
                      ASX:
                      {currentDeal &&
                        currentDeal.asx_code &&
                        currentDeal.asx_code}
                    </p> */}
                    <div className="detail">
                      <div className="row">
                        {currentDeal.offer && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text"> Raise Amount</p>
                              <p className="number">
                                <NumberFormat
                                  value={currentDeal.offer}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </p>
                            </div>
                          </div>
                        )}
                        {currentDeal && currentDeal.estimate_exit_date && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Est. Exit Date</p>
                              <p className="number">
                                {currentDeal.estimate_exit_date}
                              </p>
                            </div>
                          </div>
                        )}
                        {currentDeal && currentDeal.internal_return_rate && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Int. Rate of Return </p>
                              <p className="number">
                                {currentDeal && currentDeal.internal_return_rate
                                  ? currentDeal.internal_return_rate
                                  : ""}
                              </p>
                            </div>
                          </div>
                        )}
                        {currentDeal && currentDeal.estimate_return && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Est. Rate of Return </p>
                              <p className="number">
                                {currentDeal && currentDeal.estimate_return
                                  ? currentDeal.estimate_return
                                  : ""}
                              </p>
                            </div>
                          </div>
                        )}
                        {currentDeal.current_yield && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Current yield</p>
                              <p className="number">
                                {currentDeal.current_yield}
                              </p>
                            </div>
                          </div>
                        )}

                        {currentDeal.loan_to_value && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Loan to Value</p>
                              <p className="price">
                                {currentDeal.loan_to_value}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md-tabs tab-deal-add-doc">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                {currentDeal && currentDeal.fund_name && (
                  <p>
                    <b>Fund Name: </b> {currentDeal.fund_name}
                  </p>
                )}
                {currentDeal &&
                  currentDeal.manager &&
                  currentDeal.manager.company_name && (
                    <p>
                      <b>Fund Manager: </b> {currentDeal.manager.company_name}
                    </p>
                  )}
                {currentDeal && currentDeal.industry && (
                  <p>
                    <b>Industry: </b> {currentDeal.industry}
                  </p>
                )}
                {currentDeal && currentDeal.geography && (
                  <p>
                    <b>Geography: </b> {currentDeal.geography}
                  </p>
                )}
                {currentDeal && currentDeal.instrument && (
                  <p>
                    <b>Instrument: </b> {currentDeal.instrument}
                  </p>
                )}
                {currentDeal && currentDeal.industry && (
                  <p>
                    <b>Sector: </b> {currentDeal.sector}
                  </p>
                )}
                {currentDeal && currentDeal.property_type && (
                  <p>
                    <b>Property Type: </b> {currentDeal.property_type}
                  </p>
                )}

                {currentDeal && currentDeal.additional_document && (
                  <div className="row mt-5">
                    <div className="col-md-12 mb-4">
                      <div className="smd-file-detail clearfix">
                        <div className="file-image">
                          <img src={FileUpload} alt="" width={42} />
                        </div>
                        <div className="file-meta">
                          <span className="name">
                            {currentDeal.additional_document_name}
                          </span>
                          <div className="view clearfix">
                            <a
                              target="_blank"
                              href={`${BaseUrl}storage/${currentDeal.additional_document.id}/${currentDeal.additional_document.file_name}`}
                            >
                              View Document
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {currentDeal && currentDeal.additional_document_2 && (
                  <div className="row mt-5">
                    <div className="col-md-12 mb-4">
                      <div className="smd-file-detail clearfix">
                        <div className="file-image">
                          <img src={FileUpload} alt="" width={42} />
                        </div>
                        <div className="file-meta">
                          <span className="name">
                            {currentDeal.additional_document_name_2}
                          </span>
                          <div className="view clearfix">
                            <a
                              target="_blank"
                              href={`${BaseUrl}storage/${currentDeal.additional_document_2.id}/${currentDeal.additional_document_2.file_name}`}
                            >
                              View Document
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {currentDeal && currentDeal.additional_document_3 && (
                  <div className="row mt-5">
                    <div className="col-md-12 mb-4">
                      <div className="smd-file-detail clearfix">
                        <div className="file-image">
                          <img src={FileUpload} alt="" width={42} />
                        </div>
                        <div className="file-meta">
                          <span className="name">
                            {currentDeal.additional_document_name_3}
                          </span>
                          <div className="view clearfix">
                            <a
                              target="_blank"
                              href={`${BaseUrl}storage/${currentDeal.additional_document_3.id}/${currentDeal.additional_document_3.file_name}`}
                            >
                              View Document
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {currentDeal && currentDeal.additional_document_4 && (
                  <div className="row mt-5">
                    <div className="col-md-12 mb-4">
                      <div className="smd-file-detail clearfix">
                        <div className="file-image">
                          <img src={FileUpload} alt="" width={42} />
                        </div>
                        <div className="file-meta">
                          <span className="name">
                            {currentDeal.additional_document_name_4}
                          </span>
                          <div className="view clearfix">
                            <a
                              target="_blank"
                              href={`${BaseUrl}storage/${currentDeal.additional_document_4.id}/${currentDeal.additional_document_4.file_name}`}
                            >
                              View Document
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <p style={{ marginTop: 30 }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentDeal && currentDeal.summary,
                    }}
                  />
                </p>
              </div>

              <div className="col-md-5">
                {currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.realestate_approved === "Approved" &&
                  bidStatus &&
                  currentDeal &&
                  !bidStatus.myBid &&
                  !bidStatus.myIndication &&
                  !parsed.bid &&
                  (currentDeal.status === "Active" ? (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <DealsViewForm
                          currentUser={currentUser}
                          onSubmit={this.onSubmitBid}
                          selectEntityOptions={this.state.selectEntityOptions}
                          capRaisePrice={
                            currentDeal && currentDeal.capital_raise
                          }
                          currentDeal={currentDeal}
                          bidStatus={bidStatus}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="md-deal-form">
                      {/* <h3>This Placement has closed</h3> */}
                    </div>
                  ))}

                {currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.realestate_approved === "Approved" &&
                  bidStatus &&
                  currentDeal &&
                  currentDeal.status !== "Closed" &&
                  bidStatus.myBid &&
                  (currentDeal.status === "Active" ||
                  parseInt(parsed.bid) === 1 ? (
                    <div className="md-deal-form">
                      <div className="mb-2">
                        <b>Investment Amount:</b>
                        <NumberFormat
                          value={bidStatus.myBid.investment_amount}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </div>
                      <div className="form-normal">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-medium btn-block"
                          onClick={() => this.editBidHandler(bidStatus.uuid)}
                        >
                          Change Bid
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="md-deal-form">
                      {/* <h3>This Placement has closed</h3> */}
                    </div>
                  ))}

                {currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.realestate_approved === "Approved" &&
                  bidStatus &&
                  currentDeal &&
                  currentDeal.status !== "Closed" &&
                  bidStatus.myIndication && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-medium btn-block"
                          onClick={() => this.editBidHandler(bidStatus.uuid)}
                        >
                          Change Indication
                        </button>
                      </div>
                    </div>
                  )}

                {currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  (currentUser.realestate_approved === "Pending" ||
                    currentUser.realestate_approved === "Rejected") && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div className="deal-form-content">
                          <p className="bidMessage">
                            Complete your profile to bid for deals
                          </p>
                          <button
                            className="btn btn-outline-primary btn-block"
                            onClick={() =>
                              this.props.history.push("/user/profile")
                            }
                          >
                            Go to Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                {currentUser.role &&
                  currentUser.role.some(
                    (role) => role.title !== "Investor"
                  ) && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div className="deal-form-content">
                          <p className="bidMessage">
                            Register as an investor to bid for deal
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                {(currentDeal && !currentUser) ||
                  (currentDeal && !localStorage.getItem("auth_token") && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div
                          className="deal-form-content"
                          style={{ textAlign: "center" }}
                        >
                          <p className="bidMessage">Login to bid for deals</p>
                          <div className="row" style={{ padding: "20px 20px" }}>
                            <div className="col-sm-12">
                              <button
                                className="btn btn-primary btn-medium btn-block"
                                onClick={() =>
                                  this.props.history.push("/auth/login")
                                }
                              >
                                Login
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {(currentDeal && currentDeal.status === "Closed") ||
                (currentDeal && currentDeal.status === "Finished") ? (
                  <div className="md-deal-form">
                    <div
                      className="form-normal dealBidMessage"
                      style={{ minHeight: 250 }}
                    >
                      <div
                        className="deal-form-content"
                        style={{ textAlign: "center" }}
                      >
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "regular",
                            paddingBottom: 10,
                            paddingTop: 50,
                          }}
                        >
                          This Placement has closed.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
                {(currentDeal &&
                  currentDeal.status === "Suspended" &&
                  !parsed) ||
                (currentDeal &&
                  currentDeal.status === "Suspended" &&
                  parsed.bid &&
                  parseInt(parsed.bid) !== 1) ? (
                  <div className="md-deal-form">
                    <div
                      className="form-normal dealBidMessage"
                      style={{ minHeight: 250 }}
                    >
                      <div
                        className="deal-form-content"
                        style={{ textAlign: "center" }}
                      >
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "regular",
                            paddingBottom: 10,
                            paddingTop: 50,
                          }}
                        >
                          This deal is already suspended.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  parseInt(parsed.bid) === 1 &&
                  !bidStatus.myBid && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <DealsViewForm
                          queryString={parsed.bid}
                          currentUser={currentUser}
                          onSubmit={this.onSubmitBid}
                          selectEntityOptions={this.state.selectEntityOptions}
                          capRaisePrice={
                            currentDeal && currentDeal.capital_raise
                          }
                          currentDeal={currentDeal}
                          bidStatus={bidStatus}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    existingEntity: state.entities.entities,
    currentDeal: state.deals.singleDeal,
    currentUser: state.auth.currentUser,
    bidStatus: state.deals.bidStatus,
  };
};

const { fetchEntities } = EntityActions;
let { singlePublicDeal, getSingleDeal } = dealActions;
let { createBid, deleteBid, createIndication } = BidActions;

const mapDispatchToProps = (dispatch) => ({
  fetchAllEntities: (payload, history) =>
    fetchEntities({ dispatch, payload, history }),
  getSingleDealData: (payload) => singlePublicDeal(payload, dispatch),
  getDealStatus: (payload) => getSingleDeal(payload, dispatch),
  createBidSubmit: (payload, history) => createBid(payload, dispatch, history),
  createIndicationSubmit: (payload, history) =>
    createIndication(payload, dispatch, history),
  cancelBid: (payload, history) => deleteBid(payload, dispatch, history),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DealView));
