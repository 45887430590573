import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import AssetsDropdown from "../../../forms/fields/AssetsDropdown";
import InvestorNotifications from "../../../forms/fields/InvestorNotifications";
import {
  InvestmentSmall,
  InvestmentLarge,
} from "../../../forms/fields/Investment";
import { IconType1, IconType3 } from "../../../common/Graphics";
import { connect } from "react-redux";
import {
  updatePreferences,
  clearAlert,
} from "../../../../redux/actions/profileActions";
import assetActions from "../../../../redux/actions/assetActions";
import marketActions from "../../../../redux/actions/marketActions";
import ButtonLoader from "../../../common/ButtonLoader";
import { Form, Dropdown } from "semantic-ui-react";
require("bootstrap-select");

const options = [
  { key: "Facebook", value: "Facebook", text: "Facebook" },
  { key: "Twitter", value: "Twitter", text: "Twitter" },
  { key: "Linkedin", value: "Linkedin", text: "Linkedin" },
  { key: "Google", value: "Google", text: "Google" },
  { key: "Friend", value: "Friend", text: "Friend" },
  { key: "Broker", value: "Broker", text: "Broker" },
  { key: "Other", value: "Other", text: "Other" },
];

class InvestorPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      how_you_know_us: "",
      broker_name: "",
      reference_code: "",
      errors: {},
      showDetailsInput: false,
      details: "",
      realestate_locations: [],
      realestate_other_location: null,
      notifications: [
        {
          key: "onceperweek",
          label: "Once Per Week",
          value: "Once Per Week",
          current: false,
        },
        {
          key: "everyneewdeal",
          label: "Every New Deal",
          value: "Every New Deal",
          current: true,
        },
      ],
      investment_size: [
        { value: "Less Than $10K", label: "<10K", current: false },
        { value: "$10K-$100k", label: "10K - 100k", current: false },
        { value: "$100K-$500K", label: "100K - 500K", current: false },
        { value: "$500K+", label: ">500K", current: false },
      ],
      company_size: [
        {
          value: "Small",
          label: "<10K",
          heading: "Small",
          image: IconType1,
          current: true,
        },
        {
          value: "Medium",
          label: "<20K",
          heading: "Medium",
          image: IconType3,
          current: false,
        },
        {
          value: "Large",
          label: "<30K",
          heading: "Large",
          image: IconType3,
          current: false,
        },
      ],
      locations: [
        {
          value: "Melbourne/Sydney/Brisbane/Perth/Adelaide/Hobart/Other",
          name: "Melbourne",
        },
        {
          value: "Sydney",
          name: "Sydney",
        },
        {
          value: "Brisbane",
          name: "Brisbane",
        },
        {
          value: "Perth",
          name: "Perth",
        },
        {
          value: "Adelaide",
          name: "Adelaide",
        },
        {
          value: "Hobart",
          name: "Hobart",
        },
        {
          value: "Other",
          name: "Other",
        },
      ],
    };
  }

  hasError = (payload) => {
    console.log(payload);
    let formIsValid = true;
    let errors = {};
    if (!payload.realestate_notifications) {
      errors["realestate_notifications"] = "This field is required.";
      formIsValid = false;
    }
    if (!payload.realestate_locations || payload.realestate_locations.length === 0) {
      errors["realestate_locations"] = "This field is required.";
      formIsValid = false;
    }
    if (!payload.realestate_invested_property) {
      errors["realestate_invested_property"] = "This field is required.";
      formIsValid = false;
    }
    if (!payload.realestate_investment_type) {
      errors["realestate_investment_type"] = "This field is required.";
      formIsValid = false;
    }

    if (!payload.realestate_sector) {
      errors["realestate_sector"] = "This field is required.";
      formIsValid = false;
    }

    // if (!payload.markets || payload.markets.length === 0) {
    //   errors["markets"] = "This field is required.";
    //   formIsValid = false;
    // }

    // if (!payload.company_type) {
    //   errors["company_type"] = "This field is required.";
    //   formIsValid = false;
    // }

    if (!payload.how_you_know_us) {
      errors["how_you_know_us"] = "This field is required.";
      formIsValid = false;
    } else {
      if (payload.how_you_know_us === "Broker") {
        if (!payload.broker_name) {
          errors["broker_name"] = "This field is required.";
          formIsValid = false;
        }
        if (!payload.reference_code) {
          errors["reference_code"] = "This field is required.";
          formIsValid = false;
        }
      }
    }
    console.log(errors)
    this.setState({ errors: errors });

    return formIsValid;
  };

  componentDidMount = () => {
    this.props.fetchAssets();
    this.props.fetchMarkets();

    let {
      realestate_notifications,
      investment_size,
      company_type,
      assets,
      markets,
      reference_code,
      broker_name,
      how_you_know_us,
      realestate_sector,
      realestate_invested_property,
      realestate_investment_type,
      realestate_other_location,
      realestate_locations,
    } = this.props.currentUser;

    let newState = {};
    newState.notifications = this.state.notifications.map((item) => {
      if (item.value === realestate_notifications) item.current = true;
      else item.current = false;
      return item;
    });

    newState.investment_size = this.state.investment_size.map((item) => {
      if (Array.isArray(investment_size)) {
        investment_size &&
          investment_size.map((investment_size) => {
            if (item.value === investment_size) item.current = true;
          });
      } else {
        if (item.value === investment_size) item.current = true;
      }

      return item;
    });

    newState.company_size = this.state.company_size.map((item) => {
      if (item.value === company_type) item.current = true;
      else item.current = false;

      return item;
    });

    this.setState({
      ...newState,
      reference_code,
      broker_name,
      how_you_know_us:
        options.filter((option) => option.value === how_you_know_us).length > 0
          ? how_you_know_us
          : "Other",
      showDetailsInput:
        options.filter((option) => option.value === how_you_know_us).length > 0
          ? false
          : true,
      assets: assets ? assets.map(({ id }) => id) : [],
      markets: markets ? markets.map(({ id }) => id) : [],
      realestate_sector: realestate_sector,
      realestate_invested_property: realestate_invested_property,
      realestate_other_location: realestate_other_location,
      realestate_investment_type: realestate_investment_type,
      realestate_locations:
        realestate_locations && realestate_locations.length
          ? realestate_locations
          : [],
      details:
        options.filter((option) => option.value === how_you_know_us).length > 0
          ? ""
          : how_you_know_us,
    });
  };

  handleNotification = (event, value) => {
    let newNotifications = this.state.notifications.map((item) => {
      if (item.value === value) item.current = true;
      else item.current = false;

      return item;
    });

    this.setState({
      notifications: newNotifications,
    });
  };

  handleInvestmentSize = (value) => {
    let newInvestmentSize = this.state.investment_size.map((item) => {
      if (item.value === value) {
        if (item.current === false) {
          item.current = true;
        } else {
          item.current = false;
        }
      }
      return item;
    });

    this.setState({
      investment_size: newInvestmentSize,
    });
  };

  handleCompanySize = (value) => {
    let newCompanySize = this.state.company_size.map((item) => {
      if (item.value === value) item.current = true;
      else item.current = false;

      return item;
    });

    this.setState({
      company_size: newCompanySize,
    });
  };

  handleAssets = (options) => {
    this.setState({ assets: options });
  };

  handleMarkets = (options) => {
    this.setState({ markets: options });
  };

  handleLocations = (options) => {
    this.setState({ realestate_locations: options });
  };

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  getSettings(setting) {
    let option = null;
    let optArray = [];
    switch (setting) {
      case "notifications":
        option = this.state.notifications.filter(
          (item) => item.current === true
        );
        return option && option[0] ? option[0].value : null;
      case "investment_size":
        option = this.state.investment_size.filter(
          (item) => item.current === true
        );
        return option && option.map((elem) => elem.value);
      case "company_size":
        option = this.state.company_size.filter(
          (item) => item.current === true
        );
        return option && option[0] ? option[0].value : null;
      case "assets":
        option = this.state.assets.filter((item) => item.selected === true);
        for (let item of option) {
          optArray.push(item.value);
        }
        return optArray;
      case "markets":
        option = this.state.markets.filter((item) => item.selected === true);
        for (let item of option) {
          optArray.push(item.value);
        }
        return optArray;
      default:
        return;
    }
  }

  handleSettings = (e) => {
    let payload = {};
    const { history } = this.props;
    payload = {
      realestate_notifications: this.getSettings("notifications"),
      investment_size: this.getSettings("investment_size"),
      company_type: this.getSettings("company_size"),
      assets: this.state.assets,
      markets: this.state.markets,
      realestate_invested_property: this.state.realestate_invested_property,
      realestate_sector: this.state.realestate_sector,
      realestate_other_location: this.state.realestate_other_location,
      realestate_locations: this.state.realestate_locations,
      realestate_investment_type: this.state.realestate_investment_type,
      how_you_know_us:
        this.state.how_you_know_us === "Other"
          ? this.state.details
          : this.state.how_you_know_us,
    };

    if (this.state.how_you_know_us === "Broker") {
      payload.broker_name = this.state.broker_name;
      payload.reference_code = this.state.reference_code;
    } else {
      payload.broker_name = null;
      payload.reference_code = null;
    }
    console.log(this.hasError(payload));
    if (this.hasError(payload)) {
      this.props.updatePreferences(payload, history);
    }
  };

  handleAlert = () => {
    this.props.clearAlert();
  };

  onHowYouKnowUsHandler = (e) => {
    let formIsValid = true;
    let errors = {};

    if (e.target.value === 0) {
      errors["how_you_know_us"] = "This field is required.";

      formIsValid = false;
    }

    if (e.target.value === "Other") {
      this.setState((prevProps) => {
        return { showDetailsInput: !prevProps.showDetailsInput };
      });
    } else {
      this.setState((prevProps) => {
        return { showDetailsInput: false };
      });
    }
    this.setState({
      how_you_know_us: e.target.value,
      errors,
    });
  };

  handleChange = (e) => {
    let name = [e.target.name];
    let errors = this.state.errors;

    if (e.target.value != "") {
      errors[name] = null;
    }
    this.setState({
      [e.target.name]: e.target.value,
      errors,
    });
  };

  render() {
    const { notifications, company_size, locations } = this.state;
    const { currentUser, markets } = this.props;
    return (
      <div className="page-create-account investor-account investor-account-01 ">
        <div className="form-normal form-create-account">
          <h1>Preferences</h1>

          <Form onSubmit={this.handleSettings}>
            <div className="all-fields">
              <div className="field-group-item field-radio-option">
                <InvestorNotifications
                  label="Notification Settings"
                  data={notifications}
                  handleChange={this.handleNotification}
                />
                <span style={{ fontSize: "12px", color: "red" }}>
                  {this.state.errors["notifications"]}
                </span>
              </div>

              {/*<div className="field-group-item">*/}
              {/*  <InvestmentLarge*/}
              {/*    label="In What size Company do you typically invest?"*/}
              {/*    data={company_size}*/}
              {/*    handleChange={this.handleCompanySize}*/}
              {/*  />*/}
              {/*  <span style={{ fontSize: "12px", color: "red" }}>*/}
              {/*    {this.state.errors["company_size"]}*/}
              {/*  </span>*/}
              {/*</div>*/}

              {/*<div className="field-group-item">*/}
              {/*  <AssetsDropdown*/}
              {/*    label="What sectors of the market do you like?"*/}
              {/*    data={markets}*/}
              {/*    labelData={"name"}*/}
              {/*    valueData={"id"}*/}
              {/*    selected={*/}
              {/*      this.props.currentUser && this.props.currentUser.markets*/}
              {/*        ? this.props.currentUser.markets.map(({ id }) => id)*/}
              {/*        : []*/}
              {/*    }*/}
              {/*    onChange={this.handleMarkets}*/}
              {/*  />*/}
              {/*  <span style={{ fontSize: "12px", color: "red" }}>*/}
              {/*    {this.state.errors["markets"]}*/}
              {/*  </span>*/}
              {/*</div>*/}

              <div className="field-group-item">
                <label className="hl-title">How did you hear about us ?</label>

                <select
                  className="select-picker how_you_know_us"
                  defaultValue={
                    currentUser.how_you_know_us &&
                    options.filter(
                      (option) => option.value === currentUser.how_you_know_us
                    ).length > 0
                      ? currentUser.how_you_know_us
                      : "Other"
                  }
                  data-selected-text-format="count > 3"
                  title="Select"
                  onChange={(event) => this.onHowYouKnowUsHandler(event)}
                >
                  {options.map((elem) => (
                    <option
                      className="ml-4"
                      style={{
                        color: "#000 !important",
                        backgroundColor: "#fff !important",
                      }}
                      key={elem.key}
                      value={elem.value}
                    >
                      {elem.text}
                    </option>
                  ))}
                </select>
                <span style={{ fontSize: "12px", color: "red" }}>
                  {this.state.errors["how_you_know_us"]}
                </span>

                {this.state.showDetailsInput && (
                  <div className="field-group-item">
                    <label for="details" className="hl-title">
                      Details
                    </label>
                    <input
                      type="text"
                      name="details"
                      className="form-control"
                      id="details"
                      value={this.state.details}
                      onChange={(event) => this.handleInput(event)}
                    />
                  </div>
                )}
              </div>

              <div className="field-group-item">
                <label className="hl-title">
                  Have you ever invested in property?{" "}
                </label>

                <select
                  className="select-picker realestate_invested_property"
                  defaultValue={currentUser.realestate_invested_property}
                  name="realestate_invested_property"
                  data-selected-text-format="count > 3"
                  title="Select"
                  onChange={(event) => this.handleChange(event)}
                >
                  {["Yes", "No"].map((elem, key) => (
                    <option
                      className="ml-4"
                      style={{
                        color: "#000 !important",
                        backgroundColor: "#fff !important",
                      }}
                      key={key}
                      value={elem}
                    >
                      {elem}
                    </option>
                  ))}
                </select>
                <span style={{ fontSize: "12px", color: "red" }}>
                  {this.state.errors["realestate_invested_property"]}
                </span>
              </div>

              <div className="field-group-item">
                <label className="hl-title">What type of investment? </label>

                <select
                  className="select-picker realestate_investment_type"
                  defaultValue={currentUser.realestate_investment_type}
                  name="realestate_investment_type"
                  data-selected-text-format="count > 3"
                  title="Select"
                  onChange={(event) => this.handleChange(event)}
                >
                  {["Debt", "Equity", "Both"].map((elem, key) => (
                    <option
                      className="ml-4"
                      style={{
                        color: "#000 !important",
                        backgroundColor: "#fff !important",
                      }}
                      key={key}
                      value={elem}
                    >
                      {elem}
                    </option>
                  ))}
                </select>
                <span style={{ fontSize: "12px", color: "red" }}>
                  {this.state.errors["realestate_investment_type"]}
                </span>
              </div>

              <div className="field-group-item">
                <label className="hl-title">What sector? </label>
                <select
                  className="select-picker realestate_sector"
                  defaultValue={currentUser.realestate_sector}
                  title="Select"
                  name="realestate_sector"
                  onChange={(event) => this.handleChange(event)}
                >
                  {["Residential", "Commercial"].map((elem, key) => (
                    <option
                      className="ml-4"
                      style={{
                        color: "#000 !important",
                        backgroundColor: "#fff !important",
                      }}
                      key={key}
                      value={elem}
                    >
                      {elem}
                    </option>
                  ))}
                </select>
                <span style={{ fontSize: "12px", color: "red" }}>
                  {this.state.errors["realestate_sector"]}
                </span>
              </div>

              <div className="field-group-item">
                <AssetsDropdown
                  label="What are your preferred locations?"
                  data={locations}
                  labelData={"name"}
                  valueData={"name"}
                  selected={this.state.realestate_locations}
                  onChange={this.handleLocations}
                />
                <span style={{ fontSize: "12px", color: "red" }}>
                  {this.state.errors["realestate_locations"]}
                </span>
              </div>
              <div className="field-group-item">
                {this.state.realestate_locations &&
                  this.state.realestate_locations.find(
                    (location) => location === "Other"
                  ) && (
                    <div className="field-group-item">
                      <label for="details" className="hl-title">
                        Other Location
                      </label>
                      <input
                        type="text"
                        name="realestate_other_location"
                        className="form-control"
                        id="realestate_other_location"
                        value={this.state.realestate_other_location}
                        onChange={(event) => this.handleInput(event)}
                        style={{ width: "336px" }}
                      />
                    </div>
                  )}
                <span style={{ fontSize: "12px", color: "red" }}>
                  {this.state.errors["realestate_locations"]}
                </span>
              </div>

              {this.state.how_you_know_us === "Broker" && (
                <Fragment>
                  {" "}
                  <div className="field-group-item">
                    <label className="title">Broker Name</label>
                    <input
                      className="form-control"
                      name="broker_name"
                      type="text"
                      style={{ width: "50%" }}
                      defaultValue={currentUser.broker_name}
                      onChange={(event) => this.handleInput(event)}
                    />
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {this.state.errors["broker_name"]}
                    </span>
                  </div>
                  <div className="field-group-item">
                    <label className="title">Reference Code</label>
                    <input
                      className="form-control"
                      type="text"
                      name="reference_code"
                      style={{ width: "50%" }}
                      defaultValue={currentUser.reference_code}
                      onChange={(event) => this.handleInput(event)}
                    />
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {this.state.errors["reference_code"]}
                    </span>
                  </div>
                </Fragment>
              )}
            </div>
            <div className="row is-button-group">
              <div className="button-group">
                <div className="col-xs-12 col-sm-6 col-md-6">
                  <ButtonLoader
                    type="submit"
                    className="btn btn-primary btn-medium"
                  >
                    Save and continue
                  </ButtonLoader>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, markets, assets }) => ({
  currentUser: auth.currentUser,
  preferences: auth.preferences,
  markets: markets.markets,
  assets: assets.assets,
});

const { fetchAssets } = assetActions;
const { fetchMarkets } = marketActions;

const mapDispatchToProps = (dispatch) => ({
  updatePreferences: (payload, history) =>
    updatePreferences(dispatch, payload, history),
  fetchAssets: (payload) => fetchAssets(dispatch, payload),
  fetchMarkets: (payload) => fetchMarkets(dispatch, payload),
  clearAlert: () => dispatch(clearAlert),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvestorPreferences)
);
