import React, { Component } from "react";
import { Form, reduxForm, Field, change } from "redux-form";
import { FormField, MaskedPriceFormat } from "../../../../layout/FormField";
import { required, numericality } from "redux-form-validators";
import { connect } from "react-redux";
import EntityActions from "../../../../../redux/actions/entityActions";
import AlertComponent from "../../../../common/Alert/Alert";
import { withRouter } from "react-router-dom";
import { MIN_BID_AMOUNT } from "../../../../../utils/constants";

const queryString = require("query-string");

class DealsViewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warning: false,
    };
  }

  componentDidMount = () => {
    let { fetchEntitiesData, currentUser } = this.props;

    if (currentUser) {
      fetchEntitiesData();
    }
  };

  onShareCountChangeHander = (e) => {
    this.props.change(
      "investment_amount",
      parseFloat(e.target.value * this.props.capRaisePrice).toFixed(2)
    );
  };

  onInvestmentChangeHandler = (e) => {
    if (typeof e !== "object") {
      return this.props.currentDeal.type !== "market"
        ? this.props.change(
            "share_count",
            (parseFloat(e) / parseFloat(this.props.capRaisePrice)).toFixed(0)
          )
        : null;
    }
  };

  onBlur = (e) => {
    const { form } = this.props;
    if (
      form &&
      form.DealsViewForm &&
      form.DealsViewForm.values &&
      form.DealsViewForm.values.share_count
    ) {
      this.props.change(
        "investment_amount",
        parseFloat(
          form.DealsViewForm.values.share_count * this.props.capRaisePrice
        ).toFixed(2)
      );
    }
  };

  render() {
    let {
      handleSubmit,
      buttonTitle,
      form,
      queryStringValue,
      currentDeal,
      location,
    } = this.props;

    const parsed = queryString.parse(location.search);
    let bid_min_amount = currentDeal.min_bid ? currentDeal.min_bid : MIN_BID_AMOUNT;

    console.log("Props", this.props);
    return (
      <div>
        {this.props.title && <h3>{this.props.title}</h3>}
        {currentDeal.status === "Suspended" &&
          !queryStringValue &&
          parseInt(parsed.bid) !== 1 && (
            <AlertComponent
              message="Deal Suspended."
              type="error"
              description="Please contact us on 1300 373 522 or email us at info@180realestate.com.au to change your deal."
            />
          )}
       
        <div className="deal-form-content">
          {this.props.currentDeal &&
          (
            <Form onSubmit={handleSubmit}>
              <div className="all-fields">
                <div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="field">
                        <div className="field-input">
                          <label className="title">Select Entity </label>
                          <div className="field-input">
                            <Field
                              className="form-control"
                              name="entity_id"
                              component={FormField}
                              type="select"
                              validate={[required()]}
                              options={
                                this.props.selectEntityOptions &&
                                this.props.selectEntityOptions
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="field">
                      <div className="field-input">
                        <label className="title">Investment Amount</label>
                        <div className="field-input">
                          <Field
                            className="form-control"
                            name="investment_amount"
                            component={MaskedPriceFormat}
                            onBlurHandler={this.onBlur}
                            type="number"
                            validate={
                              this.props.initialValues &&
                              this.props.initialValues.investment_amount
                                ? [
                                    required(),
                                    numericality({
                                      ">=": parseFloat(
                                        this.props.initialValues
                                          .investment_amount
                                      ),
                                      msg: {
                                        ">=": `Min bid amount must be greater than original bid amount $${this.props.initialValues.investment_amount}.`,
                                      },
                                    }),
                                  ]
                                : [
                                    required(),
                                    numericality({
                                      ">=": parseFloat(bid_min_amount),
                                      msg: {
                                        ">=": `Min bid amount is $${parseFloat(bid_min_amount)}.`,
                                      },
                                    }),
                                  ]
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {form.DealsViewForm &&
                  form.DealsViewForm.values &&
                  form.DealsViewForm.values.investment_amount &&
                  this.props.initialValues &&
                  this.props.initialValues.investment_amount &&
                  parseFloat(form.DealsViewForm.values.investment_amount) <
                    parseFloat(this.props.initialValues.investment_amount) && (
                    <AlertComponent
                      message="Information"
                      type="error"
                      description="Contact us at info@180realestate.com.au if you would like to reduce your bid. When possible, we will attempt to reduce your bid, but cannot guarantee it."
                    />
                  )}
              </div>
              <div className="row">
                <div className="button-group">
                  <div className="col-sm-12">
                    <button
                      disabled={
                        form.DealsViewForm &&
                        form.DealsViewForm.values &&
                        form.DealsViewForm.values.investment_amount &&
                        this.props.initialValues &&
                        this.props.initialValues.investment_amount &&
                        parseFloat(
                          form.DealsViewForm.values.investment_amount
                        ) <
                          parseFloat(
                            this.props.initialValues.investment_amount
                          ) &&
                        parseInt(queryStringValue) === 1
                          ? true
                          : false
                      }
                      style={{ marginTop: "10px" }}
                      type="submit"
                      className="btn btn-primary btn-medium"
                    >
                      {buttonTitle ? buttonTitle : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form,
  };
};

const { fetchEntities } = EntityActions;

const mapDispatchToProps = (dispatch) => ({
  fetchEntitiesData: (payload, history) => fetchEntities(dispatch),
});

export default reduxForm({ form: "DealsViewForm", enableReinitialize: true })(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(DealsViewForm))
);
