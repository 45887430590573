import actionTypes from "../actions/newsLetterActions";

const initialState = {
  list: [],
};

const newsletterReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actionTypes.FETCH_ALL_NEWSLETTERS:
      return {
        ...state,
        list: payload,
      };

    default:
      return state;
  }
};

export default newsletterReducer;
