import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { acceptInvestorTerms } from "../../../../redux/actions/profileActions";

require("bootstrap-select");

class InvestorPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidMount = () => {};

  acceptTerms = () => {
    const { history } = this.props;
    this.props.acceptInvestorTerms({ realestate_terms: 1 }, history);
  };

  render() {
    return (
      <main class="page-main">
        <div class="bl-about pb-0 mb-100">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="text-about">
                  <h4 class="text-danger mb-4">
                    Please read and accept the terms and conditions below to
                    access our deals.
                  </h4>
                  <p>
                    <strong>180 Real Estate</strong>
                  </p>
                  <p>
                    <strong>Investor Terms&nbsp;</strong>
                    <br /> These terms and conditions contain important
                    information, including disclaimers and limitations of
                    liability, about your use of the Website.&nbsp;
                  </p>
                  <ol>
                    <li>
                      <strong>
                        Your acceptance of the Terms
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>
                        These terms and the "Capital Raising and Standard
                        Confidentiality Agreement" available on the AFMA website
                        (https://afma.com.au/standards/standard-documentation)
                        (together, the&nbsp;<strong>Terms</strong>) govern your
                        use of the Website and your investment in any
                        Opportunities through the Website. Any capitalised words
                        used in this document but which are not defined here
                        have the meaning given to them in the Capital Raising
                        and Standard Confidentiality Agreement. To the extent of
                        any inconsistency between this document and the Capital
                        Raising and Standard Confidentiality Agreement, this
                        document prevails.
                      </li>
                      <li>
                        In accordance with these Terms, 180 Markets PTY LTD ACN
                        638 381 129 (<strong>180 Markets</strong>,&nbsp;
                        <strong>we,</strong>
                        &nbsp;<strong>us, our, 180 Real Estate</strong>) permits
                        you (<strong>you</strong>,&nbsp;
                        <strong>your, Investor</strong>) to:
                      </li>
                      <ul>
                        <li>
                          access and use the public elements of our website at
                          180realestate.com.au (<strong>Website</strong>);
                        </li>
                        <li>
                          make use of the Website by viewing and, potentially,
                          Bidding on the Opportunities posted by property
                          professionals through the Portal which is operated by
                          us (<strong>Service</strong>);
                        </li>
                        <li>
                          view or interact with any content, information,
                          communications, advice, text, or other material
                          provided by us through the Website or Service (
                          <strong>Content</strong>); and
                        </li>
                        <li>communicate with us.</li>
                      </ul>
                      <li>
                        You agree to be bound by these Terms by clicking the "I
                        have read and accept 180 Real Estate T&amp;C&rsquo;s"
                        button, or by otherwise using, browsing or accessing any
                        part of the Website or Service. If you do not accept
                        these Terms, you must not use the Website or Service .
                      </li>
                      <li>
                        We may from time to time review and update these Terms,
                        including to take account of changes to Applicable Laws,
                        regulations, products or technology. Your use of the
                        Website or Service will be governed by the most recent
                        Terms posted on the Website. By continuing to use the
                        Website or Service, you agree to be bound by the most
                        recent Terms. It is your responsibility to check the
                        Website regularly for updated versions of the Terms.
                        <br />{" "}
                      </li>
                    </ol>
                    <li>
                      <strong>
                        Changes to the Website or Service
                        <br /> <br />{" "}
                      </strong>
                      The Website and Service are subject to change at any time
                      without notice and may contain errors.
                      <br />{" "}
                    </li>
                    <li>
                      <strong>
                        Nature of the&nbsp;Service
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>The Service will:</li>
                      <ul>
                        <li>
                          provide you, and other Investors, with access to
                          information about Opportunities submitted by
                          Operators;
                        </li>
                        <li>
                          enable you, and other Investors, to submit Bids to
                          Operators in respect of Opportunities;
                        </li>
                        <li>
                          notify you if you receive an Allocation in respect of
                          a Bid; and
                        </li>
                        <li>
                          where you receive an Allocation, provide you with
                          information about how to participate in the
                          Opportunity and acquire the relevant financial
                          products.
                        </li>
                      </ul>
                      <li>
                        You acknowledge and agree that we do not act on behalf
                        of Operators in respect of Bids submitted through the
                        Service and that the Service does not include the
                        ability for Investors to complete trades with Operators.
                        <br />{" "}
                      </li>
                    </ol>
                    <li>
                      <strong>
                        Becoming an Investor
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>
                        You may apply to become an Investor by completing the
                        online Investor Application Form and providing all
                        required information. You agree to provide us with all
                        information which we may require, in our sole
                        discretion, to consider your application to become an
                        Investor and to remain as an Investor, including any
                        information which may be required in connection with
                        the&nbsp;
                        <em>
                          Anti-money Laundering and Counter-Terrorism Financing
                          Act 2006 (Cth)
                        </em>
                        &nbsp;or other Applicable Laws.
                      </li>
                      <li>
                        180 Real Estate may accept or reject your application in
                        its sole discretion.
                        <br />{" "}
                      </li>
                    </ol>
                    <li>
                      <strong>
                        Bids made through the Service
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>
                        We may assume the authenticity of any Bid or other
                        instruction given or purportedly given through the
                        Service by you or any person claiming to be your
                        representative. We are not obliged to enquire into any
                        authenticity of instructions received through the
                        Service and you authorise us to act upon any
                        instructions we reasonably believe to be authentic.
                      </li>
                      <li>
                        We reserve the right at all times to decline to allow
                        you to submit a Bid through the Service without
                        explanation or prior notice for reasons. This may
                        include where we consider, in our sole discretion, that
                        your Bid is ambiguous, incomplete or unclear or would
                        result in a breach of Applicable Law.
                      </li>
                      <li>
                        We will inform you of any Bid declined in accordance
                        with clause 5(b) as soon as reasonably practicable,
                        subject to Applicable Laws.
                      </li>
                      <li>
                        We will use our best endeavours to pass on all Bids to
                        the relevant Operator, but we do not guarantee we will
                        do so, or do so by a certain time. The reasons for us
                        not passing on a Bid, or not doing so by a certain time,
                        may include where your Bid is contrary to these Terms or
                        Applicable Law or where your Bid is ambiguous or
                        unclear.
                      </li>
                      <li>
                        We may, in our sole discretion, impose restrictions or
                        conditions on your ability, or the ability of Investors
                        generally, to submit Bids, including Bids below or above
                        thresholds we determine in our sole discretion.
                      </li>
                      <li>
                        Any Bid you place through the Website is a binding and
                        irrevocable offer to acquire the financial products
                        specified in the Bid, in accordance with these Terms and
                        any additional terms specified in the Opportunity. Each
                        such offer is made in favour of, and for the benefit of,
                        us, the Operator and the Offeror.
                      </li>
                      <li>
                        By making a Bid, you are making each of the
                        representations and warranties set out in these Terms,
                        including the General Acknowledgement and General
                        Foreign Jurisdiction Representation in the Capital
                        Raising and Standard Confidentiality Agreement. Each of
                        these representations and warranties is made to, and for
                        the benefit of, us, the Operator and the Offeror.
                      </li>
                      <li>
                        You represent and warrant to, and for the benefit of us,
                        the Opertor and the Offeror, that you are a "wholesale
                        client" within the meaning of the Corporations Act or,
                        if you are outside Australia, that you are a person to
                        whom financial services can be offered in the manner
                        contemplated in these Terms under the laws of the
                        jurisdiction in which you are located or resident.
                      </li>
                      <li>
                        Once a Bid has been passed on to the Operator, we accept
                        no responsibility for any failure or delay by the
                        Operator or Offeror to consider the Bid.
                      </li>
                      <li>
                        An Opportunity may cease to be available at any time.
                        This may occur before or after you submit a Bid. If we
                        receive notice from an Operator that an Opportunity
                        ceases to be available, we will pass this notice on to
                        you as soon as reasonably practicable.
                        <br />{" "}
                      </li>
                    </ol>
                    <li>
                      <strong>
                        Confirmation of Allocations
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>
                        If the Operator confirms to us that you have received an
                        Allocation in response to a Bid, we will provide you
                        with a Confirmation. You acknowledge that your
                        Allocation may be for a number of financial products
                        equal to, or less than, the number specified in your
                        Bid, including as a result of a Scale Back.
                      </li>
                      <li>On receipt of a Confirmation, you must:</li>
                      <ul>
                        <li>
                          acknowledge, in the manner indicated on the
                          Confirmation, your acceptance of your Allocation; and
                        </li>
                        <li>
                          pay the amount specified in the Confirmation as
                          directed, and by the time specified, in the
                          Confirmation.
                        </li>
                      </ul>
                      <li>
                        You agree to indemnify us for any costs, expenses,
                        liability or loss we incur as a result of a failure by
                        you to pay any amount payable under clause 6(b),
                        including where we choose (in our sole discretion) to
                        pay an equivalent amount to the Operator in respect of
                        your Bid. To the extent that we do so, you acknowledge
                        that you will have no right or interest in the
                        Opportunity or the financial products to which it
                        relates.
                        <br />{" "}
                      </li>
                    </ol>
                    <li>
                      <strong>
                        Responsibility for Opportunities
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>You acknowledge and agree that:</li>
                      <ul>
                        <li>
                          we do not recommend or endorse any Opportunities or
                          other investments in or available through the Service,
                          and we assume no responsibility or liability in
                          relation to the quality of such Opportunities or
                          investments;
                        </li>
                        <li>
                          all information about an Opportunity is provided by
                          the Operator identified as being responsible for that
                          Opportunity;
                        </li>
                        <li>
                          we assume no responsibility for any information
                          submitted by an Operator in relation to an Opportunity
                          and make no representation about its accuracy or
                          completeness or the suitability of an Opportunity for
                          you or for Investors generally;
                        </li>
                        <li>
                          it is your responsibility to assess and research each
                          Opportunity and determine its suitability for your
                          purposes;
                        </li>
                        <li>
                          prior to placing a Bid, you have had an opportunity
                          to, and have to the extent you consider necessary,
                          obtained professional advice in relation to legal,
                          financial, tax and investment matters in connection
                          with the Opportunity;
                        </li>
                        <li>
                          prior to placing a Bid, you have had access to
                          information which you consider to be sufficient to
                          enable you to conduct appropriate due diligence and
                          make a decision in relation to the Opportunity based
                          on your own circumstances.
                        </li>
                      </ul>
                      <li>
                        To the greatest extent permissible by law, you further
                        acknowledge and agree that we are not responsible
                        (whether in contract, tort or otherwise) for any loss or
                        damage caused or suffered by you to the extent that the
                        loss or damage results from:
                      </li>
                      <ul>
                        <li>
                          any defect in our computer systems, or any delay,
                          fault, failure in or loss of access to the Service;
                        </li>
                        <li>
                          telecommunications failure, delay, or interruption of
                          or defective network or internet connections or
                          services by a third party, including any defect in the
                          Service or the Operator's computer systems or any
                          delay, fault, failure by the Operator;
                        </li>
                        <li>your incorrect operation of the Service;</li>
                        <li>
                          inaccuracy, error or omission in any material, data or
                          information provided by you, an Operator or any other
                          third party through the Service;
                        </li>
                        <li>
                          actions of third parties in respect of the
                          Opportunity, including the Operator or the Offeror; or
                        </li>
                        <li>
                          any delay, interruption, omission, failure, error or
                          fault in your Bid being passed on to a Operator or
                          being considered by a Operator, other than as a direct
                          result of our fraud, wilful default or negligence.
                          <br />{" "}
                        </li>
                      </ul>
                    </ol>
                    <li>
                      <strong>
                        Using the Website and Service
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>
                        You agree to use the Website and Service in good faith
                        in accordance with these Terms and Applicable Laws.
                      </li>
                      <li>
                        You will comply with any policies, procedures, operating
                        rules and directions of 180 Real Estate from time to
                        time in relation to the operation of the Service, your
                        access to the Website and Service and the manner of
                        performance of your obligations under these Terms.
                      </li>
                      <li>
                        You are responsible for all activity through the
                        Service, including any fraudulent, illegal or
                        unauthorised dealings, which are attributable to your
                        conduct. You release and discharge 180 Real Estate from
                        any liability in respect of such activity.
                        <br />{" "}
                      </li>
                    </ol>
                    <li>
                      <strong>
                        Termination
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>
                        You or 180 Real Estate may terminate these Terms
                        immediately by giving the other party written notice if
                        that party has materially breached these Terms.
                      </li>
                      <li>
                        180 Real Estate may terminate these Terms without cause
                        by giving you 10 business days prior written notice.
                      </li>
                      <li>
                        You may terminate these Terms without cause by giving
                        180 Real Estate written notice.
                      </li>
                      <li>
                        Upon termination of these Terms, you will no longer have
                        access to the Website Service or Operators Portal, other
                        than those portions of the Website which are publicly
                        available. Any such use of the Website by you will
                        continue to be subject to these Terms.
                      </li>
                      <li>
                        Despite any other provision in these Terms of Use, this
                        clause 9 and clauses 12, 13, 14, 15(b), 18, 19, 20, 21,
                        23 and 24 survive the expiry or termination of these
                        Terms.
                        <br />{" "}
                      </li>
                    </ol>
                    <li>
                      <strong>
                        Third party information
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>
                        The Website and Service incorporate Third Party
                        Information.
                      </li>
                      <li>
                        180 Real Estate is not responsible for the Third Party
                        Information and makes no representation as to the
                        accuracy, reliability, timeliness or appropriateness of
                        the Third Party Information.
                      </li>
                      <li>
                        Any recommendations or statements of opinion contained
                        in the Third Party Information are those of the relevant
                        third party and are not adopted by or attributable to
                        180 Real Estate.
                        <br />{" "}
                      </li>
                    </ol>
                    <li>
                      <strong>
                        No recommendations
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>
                        You acknowledge that 180 Real Estate is not authorised
                        to, and does not through the Website or Service, provide
                        personal financial product advice (within the meaning of
                        the Corporations Act).
                      </li>
                      <li>
                        All information provided on the Website or Service is,
                        unless otherwise indicated, factual information and does
                        not involve any recommendation or statement of opinion
                        by 180 Real Estate. Such information does not take into
                        account your objectives, financial situation or needs
                        and you should consider obtaining professional financial
                        advice before making any investment decisions.
                      </li>
                      <li>You acknowledge that:</li>
                      <ul>
                        <li>
                          any financial product advice provided through the
                          Website or Service is solely prepared and provided by
                          the relevant Operator;
                        </li>
                        <li>
                          180 Real Estate has not in any way selected the
                          content of any financial product advice set out in any
                          communication or interaction regarding an Opportunity
                          between an Operator and an Investor through the
                          Website or Service, nor has it modified or otherwise
                          exercised any control over the content of such
                          financial product advice; and
                        </li>
                        <li>
                          180 Real Estate does not endorse or otherwise assume
                          any responsibility for any financial product advice
                          set out in any communication or interaction between an
                          Operator and an Investor through the Website, Service
                          or otherwise.
                        </li>
                        <li>
                          180 Real Estate does not accept any responsibility for
                          any financial services provided to you by an operator.
                          <br />{" "}
                        </li>
                      </ul>
                    </ol>
                    <li>
                      <strong>
                        Disclosure
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>
                        You acknowledge that you have been provided with access
                        to our Financial Services Guide and the Financial
                        Services Guides of each Operator who makes available an
                        Opportunity through the website.
                      </li>
                      <li>
                        180 Real Estate may provide benefits to third parties in
                        connection with the Website, App and Service, including:
                      </li>
                      <ul>
                        <li>
                          distributors, such as accountants and other
                          professional service providers, who refer Investors to
                          us; and
                        </li>
                        <li>
                          the Operators.
                          <br />{" "}
                        </li>
                      </ul>
                    </ol>
                    <li>
                      <strong>
                        Intellectual property
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>
                        All intellectual property rights (including copyright
                        and patents) in the Website and Service, and all
                        components of them, are owned or licensed by us unless
                        otherwise indicated. You must not copy, modify or
                        transmit any part of the Website or Service, except as
                        permitted in these Terms.
                      </li>
                      <li>
                        The Website and Service contain trademarks, logos,
                        service names and trade names of 180 Real Estate or
                        third parties that may be registered or otherwise
                        protected by law. These include the 180 Real Estate
                        logo. You are not permitted to use any trademarks,
                        logos, service names or trade names appearing on the
                        Website or Service.
                      </li>
                      <li>
                        We grant you a non-exclusive and non-transferable
                        licence to use the Website and the Service, for your own
                        personal use. You may not download (other than page
                        caching) or modify the Website, the contents of the
                        Service or any portion them.
                        <br />{" "}
                      </li>
                    </ol>
                    <li>
                      <strong>
                        Privacy
                        <br /> <br />{" "}
                      </strong>
                      Any Personal Information submitted by you to 180 Real
                      Estate is subject to and will be handled in accordance
                      with our privacy policy (<strong>Privacy Policy</strong>)
                      at
                      <a href="&nbsp;https:/180realestate.com.au/privacy-policy.">
                        &nbsp;https://180realestate.com.au/privacy-policy.
                      </a>
                      &nbsp;You agree that, by using the Website, Service or
                      Operator Portal or communicating with us, you have read
                      the Privacy Policy, understood its contents and consented
                      to its requirements.
                      <br />{" "}
                    </li>
                    <li>
                      <strong>
                        Your Content
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>
                        Subject to certain restrictions and limits outlined in
                        this clause and clause 16, the Website and Service may
                        allow you to upload, post, link to, store, communicate,
                        send or transmit content (<strong>Your Content</strong>)
                        on the Website or via the Service.
                      </li>
                      <li>
                        You grant to us a non-exclusive, royalty-free,
                        perpetual, irrevocable and fully sub-licensable right to
                        use, modify, reproduce, adapt, communicate, display,
                        perform and distribute Your Content in relation to and
                        only for the purpose of our operation of the Website and
                        Service.
                      </li>
                      <li>
                        You represent and warrant that Your Content does not
                        infringe any third party intellectual property rights
                        and that you own or otherwise control all of the rights
                        to the content or that you have obtained all necessary
                        authorisations and consents to post, link to, store or
                        communicate Your Content and to grant us the rights in
                        clause 15(b), that Your Content is accurate, that the
                        use of Your Content does not violate these Terms and
                        will not cause any loss or injury to any person. You
                        agree to be solely responsible for any of Your Content
                        that you post to the Website and through the Service.
                      </li>
                      <li>
                        180 Real Estate has the right, but not the obligation,
                        to monitor any Content (including Your Content) made
                        available on the Website or Service. We reserve the
                        right, in our absolute discretion, to block, modify or
                        remove any Content (including Your Content) without
                        notice, and will not be liable in any way for possible
                        consequences of such actions.
                      </li>
                      <li>
                        If you have a complaint regarding any Content, our sole
                        obligation will be to review any written complaint
                        notified to us and, if we see fit, in our sole
                        discretion, to modify or remove the particular Content.
                        <br />{" "}
                      </li>
                    </ol>
                    <li>
                      <strong>
                        Prohibited uses
                        <br /> <br />{" "}
                      </strong>
                      In using the Website or Service, you must not engage or
                      attempt to engage in any activities that:
                    </li>
                    <ol>
                      <li>
                        violate any applicable local, state, federal or
                        international law including, without limitation
                        the&nbsp;
                        <em>Spam Act 2003 (Cth), Copyright Act 1968 (Cth)</em>,
                        principles of law or equity established by decisions of
                        courts and statutes, regulations or by-laws of the
                        Commonwealth of Australia, or any State or Territory of
                        the Commonwealth of Australia or a government agency;
                      </li>
                      <li>
                        result in the placement, posting, uploading of, linking
                        to, sending, storing or otherwise communicating or
                        distributing in any way content that is (or is likely to
                        be considered) inappropriate, defamatory, abusive,
                        profane, infringing, obscene, indecent, obscene, or
                        unlawful material or information;
                      </li>
                      <li>
                        violate the rights of any third party (including,
                        without limitation abusing, stalking, threatening or
                        otherwise, infringement of copyright, trademark, or
                        other intellectual property right, misappropriation of
                        trade secrets, Confidential Information, electronic
                        fraud, invasion of privacy, pornography, obscenity or
                        libel);
                      </li>
                      <li>
                        interfere with or disrupt any other third parties
                        (including other users of the Website or Service),
                        equipment, functions, features, the Website or Service;
                      </li>
                      <li>
                        introduce or allow the introduction, transmission,
                        distribution or uploading of any, virus or other
                        potentially harmful programs, materials, information or
                        malicious code into the Website or Service or any
                        related network;
                      </li>
                      <li>
                        use any device or process to retrieve, index, "data
                        mine" or in any way reproduce or circumvent the
                        navigational structure or presentation of the Website or
                        Service or their contents;
                      </li>
                      <li>
                        involve distribution of unsolicited advertising or chain
                        letters, repeated harassment of other users or third
                        parties, impersonating another user, falsifying a
                        users&rsquo; network identity for improper or illegal
                        purposes, gaining unauthorised access to any parts of
                        the Website or Service , sending unsolicited bulk emails
                        or calls, continuing to send someone emails after being
                        asked to stop and using a network to gain unauthorised
                        entry to any other machine accessible via a network;
                      </li>
                      <li>
                        involve the unauthorised use of any machine or network,
                        denial of service attacks, falsification of header
                        information or user identification information,
                        monitoring or scanning the networks of others;
                      </li>
                      <li>
                        gain unauthorised access to the Website or Service ;
                      </li>
                      <li>
                        disrupt, impair, alter or otherwise interfere with the
                        functions, features, Content of the Website or Service ;
                        restrict or inhibit any other visitor from using the
                        Website or Service , including, without limitation, by
                        means of "hacking" or defacing a portion of this Website
                        or , Service;
                      </li>
                      <li>
                        express or imply that statements you make are endorsed
                        by 180 Real Estate, without 180 Real Estate's prior
                        written consent;
                      </li>
                      <li>
                        modify, adapt, decompile, reverse engineer, disassemble
                        or otherwise reduce the Website or Service to a
                        human-perceivable form;
                      </li>
                      <li>
                        remove any copyright, trademark or other proprietary
                        rights notices contained in the Website or Service;
                      </li>
                      <li>
                        harvest or collect information about this Website's
                        visitors or members without their express consent;
                      </li>
                      <li>
                        are commercial, including selling, modifying,
                        displaying, distributing or otherwise using any Content,
                        in whole or in part, for any public or commercial
                        purpose without 180 Real Estate's prior written consent,
                        marketing, advertising or promoting goods or services,
                        collecting and using any product lists or pricing for
                        the benefit of other merchants, or re-selling,
                        sublicensing or translating the Website or Service;
                      </li>
                      <li>
                        use any meta-tags or any other "hidden text" utilising
                        180 Real Estate&rsquo;s name or 180 Real Estate Content
                        without 180 Real Estate's express written permission;
                      </li>
                      <li>
                        frame the Website, or utilise framing techniques on any
                        part of the Website, without 180 Real Estate's express
                        written permission; or
                      </li>
                      <li>
                        provide access to the Website or Service to persons who
                        are not Investors.
                        <br />{" "}
                      </li>
                    </ol>
                    <li>
                      <strong>
                        Registration and Login
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>
                        Certain parts of the Website and Service are only
                        accessible to Investors who have &ldquo;logged
                        in&rdquo;.
                      </li>
                      <li>
                        While you are an Investor, you represent and warrant
                        that you are the person identified in the Application
                        Form and that all information you have supplied to us in
                        connection with your use of the Website and Service is
                        and remains true and correct.
                      </li>
                      <li>
                        When using the Website or Service, you are responsible
                        for maintaining the confidentiality of your login
                        details and for restricting access by third parties to
                        your account. You agree to be liable if your login
                        details are used by an unauthorised person and are fully
                        responsible for all activities that are conducted under
                        your membership. If you believe that any of your login
                        details have been compromised, lost or misplaced, you
                        must contact us immediately by email at&nbsp;
                        <a href="mailto:info@180realestate.com.au">
                          info@180realestate.com.au
                        </a>
                        . You must exercise particular caution when accessing
                        the Website or Service from a public or shared computer
                        or mobile device so that others are not able to view or
                        record your login details or other personal information.
                        <br />{" "}
                      </li>
                    </ol>
                    <li>
                      <strong>
                        Disclaimer of warranties and limitation of liability
                        <br /> <br /> <br />{" "}
                      </strong>
                    </li>
                    <ol>
                      <li>
                        You agree that you have not relied on any
                        representation, description, illustration or
                        specification that is not expressly stated in these
                        Terms.
                      </li>
                      <li>
                        Nothing in these Terms excludes, restricts or modifies
                        any consumer guarantee, right or remedy conferred on you
                        by Schedule 2 to the&nbsp;
                        <em>Competition and Consumer Act 2010</em>&nbsp;(
                        <strong>ACL</strong>), or any other Applicable Law, that
                        cannot be excluded, restricted or modified by agreement.
                      </li>
                      <li>
                        To the extent permitted by law (including the ACL), 180
                        Real Estate excludes all warranties, whether express or
                        implied (not including any consumer guarantees under the
                        ACL), including any warranties or representations
                        concerning availability of the Website or Service,
                        quality, completeness, accuracy, suitability,
                        acceptability or fitness for purpose in relation to the
                        Website or Service including Content, all links to or
                        from the Website or Service and the goods and services
                        advertised or accessible using the Website or Service.
                        Subject to the consumer guarantees provided for in
                        consumer protection legislation (including the ACL), we
                        do not warrant that you will have continuous access to
                        the Website or Service. We will not be liable in the
                        event that the Website or the full functionality of the
                        Service is unavailable to you or due to computer
                        downtime attributable to malfunctions, upgrades,
                        preventative or remedial maintenance activities or
                        interruption in telecommunications supply.&nbsp;
                      </li>
                      <li>
                        To the extent permitted by law (including the ACL), 180
                        Real Estate excludes all warranties, whether express or
                        implied (not including any consumer guarantees under the
                        ACL) in relation to financial services provided by
                        Operators through the Website or Service.
                      </li>
                      <li>
                        We do not guarantee the delivery of communications over
                        the internet as such communications rely on third party
                        service providers. The exchange of information
                        electronically is vulnerable to interception by third
                        parties and we do not guarantee the security or
                        confidentiality of information available through the
                        Website or Service nor the security of the Website or
                        Whilst we strive to protect information transmitted via
                        the Website and Service , any such information is
                        transmitted at your own risk.
                      </li>
                      <li>
                        To the extent permitted by law (including the ACL), 180
                        Real Estate's liability in respect of any non-excludable
                        warranties or conditions relating to the Website or
                        Service including Content, all links to or from the
                        Website or Service and the goods and services advertised
                        or accessible using the content is limited to
                        resupplying the relevant Website or , Service or the
                        reasonable cost of resupplying the relevant Website or
                        Service, whichever 180 Real Estate sees fit to provide.
                      </li>
                      <li>
                        For all other claims or liability, and to the extent
                        permitted by law, the maximum liability of 180 Real
                        Estate and its employees, agents or contractors for any
                        loss, damage, claim, cost or expense whatsoever arising
                        out of or in connection with these Terms, the Website or
                        Service , all links to or from the Website or Service
                        and the goods and services advertised or accessible
                        using the Website or Service will be the amount of any
                        Membership Fee received from you in the preceding 12
                        months in the aggregate of all claims.
                      </li>
                      <li>
                        Except where 180 Real Estate fails to meet a consumer
                        guarantee under the ACL and notwithstanding any other
                        provision of these Terms, 180 Real Estate will not be
                        liable to you for:
                      </li>
                      <ul>
                        <li>
                          any damage, loss or expense resulting from or caused
                          by:
                        </li>
                        <ol>
                          <li>
                            any act of 180 Real Estate which was carried out in
                            good faith (notwithstanding that such act may have
                            been in breach of these Terms or negligent);
                          </li>
                          <li>
                            your investment decisions and any Bidding on
                            Opportunities by you, regardless of whether they
                            were made in light of Content available on the
                            Website or Service;
                          </li>
                          <li>fluctuations in the value of Opportunities;</li>
                          <li>any act or omission of any third party;</li>
                          <li>
                            any inaccurate or incorrect Third Party Information;
                          </li>
                          <li>
                            any inaccurate or incorrect information in Your
                            Content or otherwise provided by you;
                          </li>
                          <li>
                            any event or circumstance beyond 180 Real Estate's
                            reasonable control including, without limitation, a
                            Force Majeure Event; and
                          </li>
                          <li>
                            any breach of these Terms, negligence, default,
                            fraud or dishonesty by you;
                          </li>
                        </ol>
                        <li>
                          any direct or indirect lost profit, loss of
                          opportunity, incidental, consequential or special
                          damages, howsoever the loss is caused and regardless
                          of whether it was foreseeable or not.
                          <br />{" "}
                        </li>
                      </ul>
                    </ol>
                    <li>
                      <strong>
                        Indemnity
                        <br /> <br />{" "}
                      </strong>
                      You agree to indemnify 180 Real Estate in respect of all
                      loss, damage, costs, expenses (including legal fees on a
                      full indemnity basis), fines, penalties, claims, demands
                      and proceedings incurred howsoever arising, whether at
                      common law (including negligence) or under statute, in
                      connection with any of the following:
                    </li>
                    <ol>
                      <li>any breach of these Terms by you;</li>
                      <li>
                        your use of the Website or Service and Your Content (as
                        applicable); and
                      </li>
                      <li>
                        your communications with 180 Real Estate or Operators.
                        <br />{" "}
                      </li>
                    </ol>
                    <li>
                      <strong>
                        Jurisdiction and law
                        <br /> <br />{" "}
                      </strong>
                      These Terms are governed by and must be construed in
                      accordance with the laws of the State of Victoria,
                      Australia. You submit to the exclusive jurisdiction of the
                      courts of that State and the Commonwealth of Australia in
                      respect of all matters arising out of or relating to these
                      Terms, their performance and subject matter.
                      <br />{" "}
                    </li>
                    <li>
                      <strong>
                        Severability
                        <br /> <br />{" "}
                      </strong>
                      Each provision of these Terms is severable from the others
                      and no severance of a provision will affect any other
                      provision.
                      <br />{" "}
                    </li>
                    <li>
                      <strong>
                        Third party beneficiary
                        <br /> <br />{" "}
                      </strong>
                      You agree that, in respect of a Bid, Allocation and
                      Confirmation, the Operator and Offeror are third party
                      beneficiaries of these Terms, with all rights to enforce
                      all relevant provisions as if they were a party to these
                      Terms.
                      <br />{" "}
                    </li>
                    <li>
                      <strong>
                        Contacting&nbsp;180 Real Estate
                        <br /> <br />{" "}
                      </strong>
                      If you have questions about the Website or Service, these
                      Terms or the Privacy Policy, please contact 180 Real
                      Estate by emailing info@180realestate.com.au. We will
                      contact you using the contact details you provide in your
                      Application Form or any details you notify to us from time
                      to time.
                      <br />{" "}
                    </li>
                    <li>
                      <strong>
                        Definitions
                        <br /> <br />{" "}
                      </strong>
                      In these Terms unless the context requires otherwise:
                      <br /> <br /> <strong>Allocation&nbsp;</strong>has the
                      meaning given to that term in the Capital Raising Standard
                      Confidentiality Agreement and relates to the number of
                      financial products allocated to you in response to your
                      Bid, as set out in your Confirmation.
                      <br /> <br /> <strong>Applicable Law</strong>&nbsp;means
                      the&nbsp;
                      <em>Corporations Act 2001 (Cth)</em>, and any other
                      statute, statutory instrument or general law that is
                      applicable to a party in connection with these Terms,
                      including ASIC Policy;
                      <br /> <br /> <strong>ASIC</strong>&nbsp;means the
                      Australian Securities and Investments Commission
                      established under the Australian Securities and
                      Investments Commission Act 2001 (Cth) and its successors;
                      <br /> <br /> <strong>ASIC Policy</strong>&nbsp;means
                      regulatory guides, legislative instruments and class
                      orders issued by ASIC;
                      <br /> <br /> <strong>Bid&nbsp;</strong>has the meaning
                      given to that term in the Capital Raising Standard
                      Confidentiality Agreement and includes a binding and
                      irrevocable offer by an Investor to acquire financial
                      products of a particular value in connection with an
                      Opportunity, on the terms specified in the Opportunity;
                      <br /> <br /> <strong>Operator</strong>&nbsp;means a
                      person who has been accepted by us as an "Operator" for
                      the purpose of the Service;
                      <br /> <br /> <strong>Business Day</strong>&nbsp;means a
                      day other than Saturday or Sunday on which banks are open
                      for general banking business in Melbourne;
                      <br /> <br /> <strong>Confidential Information</strong>
                      &nbsp;means all information belonging or relating to 180
                      Real Estate and/or the Service, whether oral, graphic,
                      electronic, written or in any other form, that:
                    </li>
                    <ol>
                      <li>
                        is or should reasonably be regarded as, confidential to
                        180 Real Estate; or
                      </li>
                      <li>
                        is not generally available to the public at the time of
                        disclosure other than by reason of a breach of these
                        Terms;
                        <br />{" "}
                      </li>
                    </ol>
                  </ol>
                  <p>
                    <strong>Confirmation&nbsp;</strong>means a confirmation from
                    us to you in relation to the number of financial products
                    allocated to you by the Operator in respect of your Bid.
                    <br /> <br /> <strong>Content</strong>&nbsp;means any
                    content made available on the Website or Service;
                    <br /> <br /> <strong>Force Majeure Event&nbsp;</strong>
                    means any act, event or cause including:
                  </p>
                  <ol>
                    <ol>
                      <li>
                        (a) an act of God, peril of the sea, accident of
                        navigation, war, sabotage, riot, act of terrorism,
                        insurrection, civil commotion, national emergency
                        (whether in fact or law), martial law, fire, lightning,
                        flood, cyclone, earthquake, landslide, storm or other
                        adverse weather conditions, explosion, power shortage,
                        strike or other labour difficulty (whether or not
                        involving employees of the party concerned), epidemic,
                        quarantine, radiation or radioactive contamination;
                      </li>
                      <li>
                        (b) an action or inaction of a government agency,
                        including expropriation, restraint, prohibition,
                        intervention, requisition, requirement, direction or
                        embargo by legislation, regulation, decree or other
                        legally enforceable order; or
                      </li>
                      <li>
                        (c) breakdown of plant, machinery or equipment or
                        shortages of labour, transportation, fuel, power or
                        plant, machinery, equipment or material, to the extent
                        that the act, event or cause directly or indirectly
                        results in a party being prevented from or delayed in
                        performing one or more of its obligations under these
                        Terms and that act, event or cause is beyond the
                        reasonable control of that party;
                        <br />{" "}
                      </li>
                    </ol>
                  </ol>
                  <p>
                    <strong>Investor&nbsp;</strong>means a user who has
                    subscribed for access to the Service in accordance with
                    these Terms and whose application was accepted under clause
                    4(a);
                  </p>
                  <p>
                    <strong>Investor Application Form</strong>&nbsp;means the
                    application form to become an Investor made available on the
                    Website or Service by 180 Real Estate, from time to time;
                  </p>
                  <p>
                    <strong>Master ECM Terms&nbsp;</strong>means the "Master ECM
                    Terms" available on the AFMA website at&nbsp;
                    <a href="http://www.afma.com.au/standards/documentation.html">
                      www.afma.com.au/standards/documentation.html
                    </a>
                    &nbsp;and which are incorporated into and form part of these
                    Terms.
                  </p>
                  <p>
                    <strong>Month</strong>&nbsp;means a period extending from a
                    date in one calendar month to the corresponding date in the
                    following month;
                  </p>
                  <p>
                    <strong>Opportunity&nbsp;</strong>means an opportunity to
                    purchase financial products (such as shares through an
                    initial public offering or placement) which has been
                    submitted for inclusion in the Service by an Operator;
                  </p>
                  <p>
                    <strong>Personal Information&nbsp;</strong>means your name,
                    address, date of birth, credit card details, 180 Real Estate
                    login details and any other information you have entered
                    into the Service about yourself which the Service identifies
                    as "personal information";&nbsp;
                  </p>
                  <p>
                    <strong>Scale Back&nbsp;</strong>means, in respect of a Bid
                    for an Opportunity, a reduction in the amount of financial
                    products available to an Investor;
                  </p>
                  <p>
                    <strong>Service&nbsp;</strong>has the meaning given in
                    clause 1(b)(ii);
                  </p>
                  <p>
                    <strong>Third Party Information&nbsp;</strong>means
                    information supplied by an Operator, another Investor or a
                    third party and incorporated into the Website or Service,
                    including:
                  </p>
                  <ol>
                    <ol>
                      <li>
                        information about Opportunities, including information
                        obtained from ASX Limited and information submitted by
                        Operators;
                      </li>
                      <li>
                        research and analysis in relation to Opportunities; and
                      </li>
                      <li>Content provided by other Investors; and</li>
                    </ol>
                  </ol>
                  <p>
                    <strong>Your Content&nbsp;</strong>means the content
                    uploaded, posted, linked to, stored, communicated, sent or
                    transmitted on the Website or via the Service in accordance
                    with clause 15(a).
                  </p>
                  <p>
                    <strong>Interpretations</strong>
                    <br /> <br /> In these Terms unless the context requires
                    otherwise:
                  </p>
                  <ol>
                    <ol>
                      <li>the singular includes the plural and vice versa;</li>
                      <li>a gender includes the other genders;</li>
                      <li>
                        the headings are used for convenience only and do not
                        affect the interpretation of these Terms;
                      </li>
                      <li>
                        other grammatical forms of defined words or expressions
                        have corresponding meanings;
                      </li>
                      <li>
                        a reference to a document includes the document as
                        modified from time to time and any document replacing
                        it;
                      </li>
                      <li>
                        a reference to a party is to a party to these Terms and
                        a reference to a party to a document includes the
                        party's executors, administrators, successors and
                        permitted assigns and substitutes;
                      </li>
                      <li>
                        if something is to be or may be done on a day that is
                        not a Business Day then it must be done on the next
                        Business Day;
                      </li>
                      <li>
                        the word "person" includes a natural person,
                        partnership, body corporate, association, governmental
                        or local authority, agency and any body or entity
                        whether incorporated or not;
                      </li>
                      <li>
                        the word "month" means calendar month and the word
                        "year" means 12&nbsp;months;
                      </li>
                      <li>
                        the words "in writing" include any communication sent by
                        letter, facsimile transmission or email or any other
                        form of communication capable of being read by the
                        recipient;
                      </li>
                      <li>
                        a reference to a thing includes a part of that thing;
                      </li>
                      <li>
                        a reference to all or any part of a statute, rule,
                        regulation or ordinance (<strong>statute</strong>)
                        includes that statute as amended, consolidated,
                        re-enacted or replaced from time to time;
                      </li>
                      <li>
                        wherever "include", "for example" or any form of those
                        words or similar expressions is used, it must be
                        construed as if it were followed by "(without being
                        limited to)";
                      </li>
                      <li>
                        money amounts in these Terms, the Website and Service
                        are stated in Australian currency unless otherwise
                        specified;
                      </li>
                      <li>
                        a reference to time is to Melbourne, Australia time;
                      </li>
                      <li>
                        a reference to any agency or body, if that agency or
                        body ceases to exist or is reconstituted, renamed or
                        replaced or has its powers or functions removed (
                        <strong>defunct body</strong>), means the agency or body
                        which performs most closely the functions of the defunct
                        body;
                      </li>
                      <li>
                        any agreements, representation, warranty or indemnity in
                        favour of two or more parties (whether those parties are
                        included in the same defined term or not) is for the
                        benefit of them jointly and separately; and
                      </li>
                      <li>
                        any agreements, representation, warranty or indemnity by
                        two or more parties (whether those parties are included
                        in the same defined term or not) binds them jointly and
                        separately.
                      </li>
                    </ol>
                  </ol>
                  <button
                    type="button"
                    className="btn btn-primary btn-medium"
                    onClick={() => this.acceptTerms()}
                  >
                    I Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  acceptInvestorTerms: (payload, history) =>
    acceptInvestorTerms(dispatch, payload, history),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvestorPreferences)
);
