import React, { Component } from "react";
import HomeVideo from "../../../assets/videos/home.mp4";

export default class HowItWork extends Component {

  render() {
    return (
      <main className="page-main home-page pt-5">
        <div class="main_bg">
          <div className="container">
            <div className="nav-tab-bg how-it-works" id="how-it-works">
              <h3 className="hero_hedading text-center how_it_work">
                How It Works For Investors
              </h3>
              <div
                className="container-fluid"
                style={{
                  padding: "50px 0 0 0",
                }}
              >
                <div className="d-flex justify-content-center">
                  <video
                    src={HomeVideo}
                    width="800"
                    height="500"
                    controls="controls"
                    autoplay={false}
                  />
                </div>
              </div>

              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="investors"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div
                    className="row"
                    style={{
                      marginTop: "15px",
                    }}
                  >
                    <div className="col-lg-5 offset-1 padd bgtext">
                      <h3 className="hiw_heading">
                        <a
                          href="/auth/register/investor"
                          style={{ color: "inherit" }}
                        >
                          1. Create an Account
                        </a>
                      </h3>
                      <p className="hiw_p">
                        Upload one ID document and a proof of Sophisticated
                        Investor form from your accountant.
                      </p>
                    </div>
                    <div className="col-lg-5 offset-1 padd bgtext_2">
                      <h3 className="hiw_heading">2. Verify</h3>
                      <p className="hiw_p">
                        We will verify your account within MINUTES and you can
                        start investing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="developers" style={{marginTop:100}}>

              <h3 className="hero_hedading" style={{paddingBottom:20}}>Property investors, developers and financiers</h3>

              <h4 style={{paddingBottom:20}}>Are you in need of funding? Or interested in selling down existing, illiquid interests in projects?</h4>


              <p>Unlisted trust units and partnership shares? We can help. We offer tailored, discrete fundraising and broking solutions to meet your capital needs or
                liquidity constraints.</p>

              <p>Please get in touch at info@180realestate.com.au</p>

              <h5>How it works</h5>

              <ol>
                <li>Reach out to the 180 team at info@180realestate.com.au</li>
                <li>We discuss your capital needs</li>
                <li>We raise you monies via our network, tailored and discretely</li>
              </ol>


            </div>
          </div>
        </div>
      </main>
    );
  }
}
