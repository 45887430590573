import React, { createRef, Component, Fragment, useState } from "react";
import Dropzone from "react-dropzone";
import { Icon } from "semantic-ui-react";
import { IconUpload } from "./Graphics";
import { BaseUrl } from "../../services/settings";
import Confirm from "./Confirm";

export default class NewDropzone extends Component {
  state = {
    upload: [],
    previousDownloads: {},
    singleDownloads: []
  };

  onDelete = id => {
    this.setState({
      upload: this.state.upload.filter((file, index) => {
        return index !== id;
      })
    });
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.multiDocuments) {
      let newArray = [];
      Object.keys(this.props.multiDocuments).map(key => {
        newArray.push(this.props.multiDocuments[key]);
      });
      this.setState({ previousDownloads: this.props.multiDocuments });
    }

    if (nextProps.singleDocuments) {
      let newArray = [];
      Object.keys(nextProps.singleDocuments).map(key => {
        newArray.push(nextProps.singleDocuments[key]);
      });
      this.setState({
        singleDownloads: [...nextProps.singleDocuments]
      });
    }
  };

  render() {
    this.props.onUpload(this.state.upload);
    const dropzoneRef = createRef();
    const openDialog = () => {
      // Note that the ref is set async,
      // so it might be null at some point
      if (dropzoneRef.current) {
        dropzoneRef.current.open();
      }
    };

    const img = {
      display: "block",
      width: "100%",
      height: "100px"
    };

    const deleteIcon = {
      //   background: '#fff',
      // position: 'relative',
      // width: '30px',
      // height: '30px',
      // left: '-21px',
      // borderRadius: '23px',
      // padding: '10px',
      // top: '-8px',
      cursor: "pointer",
      position: "absolute",
      background: "#fff",
      borderRadius: "12px",
      width: "26px",
      height: "25px",
      textAlign: "center",
      padding: "7px",
      marginLeft: "75px",
      marginTop: "-12px",
      fontSize: "14px"
    };

    const fileName = {
      width: "80px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontSize: "12px",
      marginTop: "3px"
    };
    const thumb = {
      // display: "inline-flex",
      borderRadius: 2,
      border: "1px solid #eaeaea",
      marginBottom: 8,
      marginRight: 8,
      width: 100,
      height: "100%",
      padding: 4,
      boxSizing: "border-box"
    };

    const thumbsContainer = {
      display: "flex",
      // flexDirection: "row",
      // flexWrap: "wrap",
      marginTop: 16
    };

    const thumbInner = {
      display: "flex",
      minWidth: 0,
      overflow: "hidden"
    };

    const fileIcon = {
      fontSize: "100px",
      height: "100px"
    };

    return (
      // Disable click and keydown behavior on the <Dropzone>
      <Dropzone
        ref={dropzoneRef}
        noClick
        noKeyboard
        onDropAccepted={acceptedFiles => {
          if (this.props.multiple) {
            this.setState({
              upload: this.state.upload.concat(acceptedFiles)
            });
          } else {
            this.setState({ upload: acceptedFiles[0] });
          }
        }}
      >
        {({ getRootProps, getInputProps, acceptedFiles }) => {
          acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          );
          return (
            <div className="field">
              <label className="title">{this.props.title}</label>
              <div className="field-input is-field-upload">
                <div {...getRootProps({ className: "dropzone" })}>
                  <div className="text-upload">
                    <Fragment>
                      <div className="icon">
                        <img
                          src={IconUpload}
                          alt="Upload Here"
                          className="img-fluid"
                        />
                      </div>
                      <p>
                        Click and drag a logo to this area or choose from a
                        library
                      </p>
                    </Fragment>
                  </div>
                  <div className="file-upload">
                    <label
                      htmlFor="file-upload-01"
                      className="btn btn-outline-primary"
                      onClick={openDialog}
                    >
                      <input
                        {...getInputProps()}
                        accept={this.props.accept || "*"}
                      />
                      <p>{this.props.buttonLabel}</p>
                    </label>
                  </div>
                </div>
                <div>
                  <aside style={thumbsContainer}>
                    {this.props.multiple ? (
                      this.state.upload.map((file, keys) => (
                        <div style={thumb} key={keys}>
                          <div style={thumbInner}>
                            {file.type.includes("image") ? (
                              <img src={file.preview} style={img} />
                            ) : (
                              <Icon size="huge" name="file" style={fileIcon} />
                            )}
                            <Icon
                              size="huge"
                              name="delete"
                              size="small"
                              onClick={() => this.onDelete(keys)}
                              style={({ cursor: "pointer " }, deleteIcon)}
                            />
                            {/* {file.path} - {file.size} bytes */}
                          </div>
                          <div className="file-name" style={fileName}>
                            {file.name}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>
                        {this.state.upload &&
                        Object.entries(this.state.upload).length > 0 ? (
                          <aside style={thumbsContainer}>
                            <div style={thumb}>
                              <div style={thumbInner}>
                                {this.state.upload.type.includes("image") ? (
                                  <img
                                    src={this.state.upload.preview}
                                    style={img}
                                  />
                                ) : (
                                  <Icon
                                    size="huge"
                                    name="file"
                                    style={fileIcon}
                                  />
                                )}
                                <Icon
                                  size="huge"
                                  name="delete"
                                  size="small"
                                  onClick={() =>
                                    this.setState({
                                      upload: ""
                                    })
                                  }
                                  style={({ cursor: "pointer " }, deleteIcon)}
                                />
                                {/* {file.path} - {file.size} bytes */}
                              </div>
                              <div className="file-name" style={fileName}>
                                {this.state.upload.name}
                              </div>
                            </div>
                          </aside>
                        ) : null}
                      </div>
                    )}
                  </aside>
                </div>

                {/* 
        Previous Multiple Downloads */}
                <aside style={thumbsContainer}>
                  {this.state.previousDownloads !== undefined &&
                    Object.keys(this.state.previousDownloads).map(key => {
                      return (
                        <Fragment>
                          <div style={thumb}>
                            <div style={thumbInner}>
                              {this.state.previousDownloads[
                                key
                              ].mime_type.includes("image") ? (
                                <img
                                  src={`${BaseUrl}/storage/${this.state.previousDownloads[key].id}/${this.state.previousDownloads[key].file_name}`}
                                  style={img}
                                />
                              ) : (
                                <Icon
                                  size="huge"
                                  name="file"
                                  style={fileIcon}
                                />
                              )}

                              <Icon
                                size="huge"
                                name="delete"
                                size="small"
                                // onClick={() => alert(key)}
                                onClick={() =>
                                  this.props.open(
                                    this.state.previousDownloads[key].id
                                  )
                                }
                                style={({ cursor: "pointer" }, deleteIcon)}
                              />
                              <Confirm
                                message="Are you sure you want to delete this file?"
                                show={this.props.show}
                                handleDelete={() =>
                                  this.props.onDocumentDeleteHandler()
                                }
                                handleClose={this.props.close}
                              />
                            </div>
                            <div className="file-name" style={fileName}>
                              {this.state.previousDownloads[key].name}
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                </aside>

                {/* Single Uploaded Documents */}
                <aside style={thumbsContainer}>
                  {this.state.singleDownloads !== undefined &&
                    this.state.singleDownloads.map((value, key) => {
                      return (
                        <div style={thumb}>
                          <div style={thumbInner}>
                            {value.mime_type.includes("image") ? (
                              <img
                                src={`${BaseUrl}/storage/${value.id}/${value.file_name}`}
                                style={img}
                              />
                            ) : (
                              <Icon size="huge" name="file" style={fileIcon} />
                            )}

                            <Icon
                              size="huge"
                              name="delete"
                              size="small"
                              onClick={() => this.props.open(value.id)}
                              style={({ cursor: "pointer " }, deleteIcon)}
                            />
                            <Confirm
                              message="Are you sure you want to delete this file?"
                              show={this.props.show}
                              handleDelete={() =>
                                this.props.onDocumentDeleteHandler()
                              }
                              handleClose={this.props.close}
                            />
                          </div>
                          <div className="file-name" style={fileName}>
                            {this.state.singleDownloads[key].name}
                          </div>
                        </div>
                      );
                    })}
                </aside>
              </div>
            </div>
          );
        }}
      </Dropzone>
    );
  }
}
