import actionTypes from "../actions/interviewActions";

const initialState = {
  list: [],
  selected: {},
};

const interviewReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.FETCH_ALL_INTERVIEWS:
      return {
        ...state,
        list: [...payload],
      };

    case actionTypes.FETCH_INTERVIEW:
      return {
        ...state,
        selected: { ...payload },
      };

    default:
      return state;
  }
};

export default interviewReducer;
