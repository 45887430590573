import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";

export default class TermsModal extends Component {

  render() {
      const {show, onClose, heading} = this.props
    return (
      <Fragment>
        <Modal show={show} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.children}
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}
