import React, { Component } from "react";

import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";
import { ConsoleSqlOutlined } from "@ant-design/icons";
var moment = require("moment");

class DateDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { year: null, month: null, day: null };
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.meta.initial &&
      this.props.meta.initial !== nextProps.meta.initial
    ) {
      this.setState({
        day: moment(nextProps.meta.initial).format("L").split("/")[1],
        month: moment(nextProps.meta.initial).format("L").split("/")[0] - 1,
        year: moment(nextProps.meta.initial).format("L").split("/")[2],
      });
    }
  };

  render() {
    let { readOnly, selectedDate } = this.props;
    let { year, month, day } = this.state;
    if (this.props.meta.initial) {
      day =
        day ||
        parseInt(moment(this.props.meta.initial).format("L").split("/")[1]);
      month =
        month || moment(this.props.meta.initial).format("L").split("/")[0] - 1;
      year = year || moment(this.props.meta.initial).format("L").split("/")[2];
    }

    if (year && month && day) {
      let requiredDate =
        ("0" + day).slice(-2) +
        "/" +
        ("0" + (parseInt(month) + 1)).slice(-2) +
        "/" +
        year;
      selectedDate(this.props.input.name, requiredDate);
    }
    return (
      <div className="row">
        <YearPicker
          defaultValue={"Year"}
          // default is 1900
          start={1900}
          // default is current year
          end={new Date().getFullYear() - 18}
          // default is ASCENDING
          reverse
          // default is false
          required={true}
          // default is false
          disabled={readOnly}
          // mandatory
          value={year}
          // mandatory
          onChange={(year) => {
            this.setState({ year });
          }}
          id={"year"}
          name={"year"}
          classes={"col-sm-3 ml-3 form-control year-select"}
          optionClasses={"option classes"}
        />
        <MonthPicker
          defaultValue={"Month"}
          // to get months as numbers

          // default is full name
          short
          // default is Titlecase
          caps
          // mandatory if end={} is given in YearPicker
          endYearGiven
          // mandatory
          year={year}
          // default is false
          required={true}
          // default is false
          disabled={readOnly}
          // mandatory
          value={month}
          // mandatory
          onChange={(month) => {
            this.setState({ month });
          }}
          id={"month"}
          name={"month"}
          classes={"col-sm-3 form-control month-select"}
          optionClasses={"option classes"}
        />
        <DayPicker
          defaultValue={"Day"}
          // mandatory
          year={year}
          // mandatory
          month={month}
          // mandatory if end={} is given in YearPicker
          endYearGiven
          // default is false
          required={true}
          // default is false
          disabled={readOnly}
          // mandatory
          value={day}
          // mandatory
          onChange={(day) => {
            this.setState({ day });
          }}
          id={"day"}
          name={"day"}
          classes={"col-sm-3 form-control day-select "}
          optionClasses={"option classes"}
        />
      </div>
    );
  }
}

export default DateDropdown;
