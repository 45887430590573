import React, { useEffect } from "react";
import { connect } from "react-redux";
import NewsletterActions from "../../../redux/actions/newsLetterActions";
import NewsLetterTable from "./NewsLetterTable/NewsLetterTable";
import { sortNewsLettersAccToDate } from "../../../utils/constants";

const Newsletter = ({ fetchAllNewsletters, newsletters }) => {
  useEffect(() => {
    fetchAllNewsletters();
  }, []);

  return (
    <main className="page-main page-deals page-all-deals">
      <div className="container">
        <div className="page-deals page-all-deals">
          <div className="title">
            <div className="row">
              <div className="col-sm-9">
                <h1>Newsletters</h1>
              </div>
            </div>
            <NewsLetterTable
              newsletters={sortNewsLettersAccToDate(newsletters)}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  return { newsletters: state.newsletters.list };
};

const { getAllNewsletters } = NewsletterActions;

const mapDispatchToProps = (dispatch) => ({
  fetchAllNewsletters: () => getAllNewsletters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
