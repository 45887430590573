import NewsLetterService from "../../services/newsLetterService";
import { toast } from "../../components/common/Toast";
import { reset } from "redux-form";
import { startButtonLoading, stopButtonLoading } from "./loadingActions";

const actions = {
  FETCH_ALL_NEWSLETTERS: "FETCH_ALL_NEWSLETTERS",

  getAllNews: (dispatch, payload) => {
    dispatch({
      type: actions.FETCH_ALL_NEWSLETTERS,
      payload,
    });
  },

  getAllNewsletters: (dispatch, payload) => {
    NewsLetterService.getAll(payload)
      .then((res) => {
        actions.getAllNews(dispatch, res.data.data);
      })
      .catch((error) => {
        if (error) toast.error("Something went wrong.");
      });
  },

  subscribeNewsletter: (dispatch, payload) => {
    dispatch(startButtonLoading);

    NewsLetterService.subscribe(payload)
      .then((res) => {
        dispatch(stopButtonLoading);
        dispatch(reset("NewsletterForm"));
        toast.success(res.data.message);
      })
      .catch((error) => {
        dispatch(stopButtonLoading);

        if (error) toast.error("Something went wrong.");
      });
  },
};

export default actions;
