import React, { Component } from "react";
import { Tabs } from "antd";
import DirectorSharesTable from "./DirectorSharesTable/DirectorSharesTable";
import { connect } from "react-redux";
import directorActions from "../../../redux/actions/directorActions";
import BidDirectorShares from "../../common/BidDirectorShares";

const { TabPane } = Tabs;

class DirectorShares extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showIpo: false,
      submittedIndication: {},
      showEditIpoForm: false,
      showEditShareIndicationForm: false,
    };
  }

  componentDidMount = () => {
    const { getAllDirectorSharesData, getAllIndications } = this.props;

    getAllDirectorSharesData();
    getAllIndications();
  };

  onBidDirectorNow = async (id) => {
    const { getSingleIndication, selectedShare } = this.props;

    await getSingleIndication({ id });

    return this.setState((prevState) => {
      return {
        showIpo: !prevState.showIpo,
        showEditIpoForm: selectedShare.myindication ? true : false,
      };
    });
  };

  handleDirectorCancelBid = () => {
    this.setState({ showIpo: false, showEditShareIndicationForm: false });
  };

  onSubmitIndication = async (values) => {
    const {
      createIndication,
      updateSubmittedIndication,
      getAllIndications,
      selectedShare,
    } = this.props;

    if (!selectedShare.myindication) {
      await createIndication({
        ...values,
        director_share_id: selectedShare.id,
      });
    } else {
      updateSubmittedIndication({
        ...values,
        id: selectedShare.myindication.uuid,
        director_share_id: selectedShare.id,
      });
      getAllIndications();
    }

    this.setState({ showIpo: false, showEditShareIndicationForm: false });
  };

  editIpoBidHandler = () => {
    this.setState({ showEditShareIndicationForm: true });
  };

  render() {
    const { shares, selectedShare } = this.props;
    const {
      submittedIndication,
      showIpo,
      showEditShareIndicationForm,
    } = this.state;
    return (
      <main className="page-main page-deals page-all-deals">
        <div className="container">
          <div className="page-deals page-all-deals">
            <div className="title">
              <div className="row">
                <div className="col-sm-9">
                  <h1>Director Shares</h1>
                </div>
              </div>
            </div>


            <p>180 Markets tracks company directors’ purchases and sales of shares on their own stock. On this page we provide daily monitoring of director trades for every ASX listed company.</p>

            <p>About this page:</p>
            <ul className="ds">
              <li>Every director trade involving consideration is disclosed (direct and indirect)</li>
              <li>Director trades are updated daily</li>
              <li>Data is manually documented</li>
              <li>We track the purchase, sale and issue of ordinary shares and exercise of options</li>
              <li>Data is sourced from the ASX - ASX Company Announcements (Appendix 3Ys and Change of Directors Interest Notice)</li>
              <li>Where the same Appendix 3Y has been used to disclose transactions on two or more different days only the most recent date has been disclosed by 180 Markets</li>
            </ul>

            <p style={{fontSize: "90%"}}>180 Markets does not guarantee the integrity of the above data nor does it constitute financial advice of any form.</p>







            <BidDirectorShares
              visible={showIpo}
              handleCancel={this.handleDirectorCancelBid}
              selectedDirectorShare={selectedShare}
              onSubmit={this.onSubmitIndication}
              submittedIndication={submittedIndication && submittedIndication}
              initialValues={
                selectedShare &&
                selectedShare.myindication && {
                  investment_amount:
                    selectedShare.myindication.investment_amount,
                }
              }
              showEditIpoBidForm={this.state.showEditIpoForm}
              editIpoBidHandler={this.editIpoBidHandler}
              showEditShareIndicationForm={showEditShareIndicationForm}
            />

            <Tabs defaultActiveKey="1" onChange={this.callback}>
              <TabPane tab={<span className="nav-link-top ">Buy</span>} key="1">
                <DirectorSharesTable
                  shares={shares.filter((share) => share.category === "buy")}
                  onBidDirectorSharesNow={this.onBidDirectorNow}
                />
              </TabPane>
              <TabPane
                tab={<span className="nav-link-top ">Sell</span>}
                key="2"
              >
                <DirectorSharesTable
                  shares={shares.filter((share) => share.category === "sell")}
                  onBidDirectorSharesNow={this.onBidDirectorNow}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </main>
    );
  }
}

const {
  getAllDirectorShares,
  submitIndication,
  getAllDirectorSharesIndications,
  updateIndication,
  fetchSingleIndication,
} = directorActions;

const mapDispatchToProps = (dispatch) => ({
  getAllDirectorSharesData: () => getAllDirectorShares(dispatch),
  createIndication: (payload) => submitIndication(dispatch, payload),
  getAllIndications: () => getAllDirectorSharesIndications(dispatch),
  updateSubmittedIndication: (payload) => updateIndication(dispatch, payload),
  getSingleIndication: (payload) => fetchSingleIndication(dispatch, payload),
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    shares: state.shares.shares,
    indications: state.shares.indications,
    selectedShare: state.shares.selectedShare,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DirectorShares);
