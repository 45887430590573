import React from "react";
import { toast } from "../../components/common/Toast";
import { reset } from "redux-form";
import queryString from "query-string";

import {
  APP_LOADING_STARTS,
  APP_LOADING_ENDS,
  REGISTRATION_PROCESS_STARTED,
  REGISTRATION_VALIDATION_FAILED,
  REGISTRATION_PROCESS_ENDED,
  REGISTRATION_TYPE,
  REGISTRATION_SUCCESS,
  // REGISTRATION_FAILED,
  USER_LOGIN_PROCESS_STARTED,
  // USER_LOGIN_PROCESS_ENDED,
  // USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  CLEAR_REGISTRATON_ALERTS,
  CLEAR_LOGIN_WARNING,
  CLEAR_LOGIN_NOTIFICATION,
  USER_LOGOUT,
  UPDATE_AUTH,
  CHANGE_EMAIL_PENDING_STATE,
  USER_LOGIN_PENDING,
  // START_PREFERENCE_UPDATE,
  // END_PREFERENCE_UPDATE,
  // PREFERENCE_UPDATE_SUCCESS,
  // PREFERENCE_UPDATE_FAILED,
  USER_TOKEN_DATA,
  REGISTRATION_ERROR_MESSAGE,
  PROFILE_STATUS,
  REGISTER_TOKEN_SAVE,
} from "./constants";

import auth from "../../services/authService";
import { hideUserMenu } from "./appActions";
import { history } from "../history";
import { startButtonLoading, stopButtonLoading } from "./loadingActions";
import { Redirect } from "react-router-dom";

const userTokenData = (payload) => {
  return {
    type: USER_TOKEN_DATA,
    payload,
  };
};

const registrationStarted = {
  type: REGISTRATION_PROCESS_STARTED,
};

const clearRegistrationAlerts = {
  type: CLEAR_REGISTRATON_ALERTS,
};

// const registrationSuccess = {
//   type: REGISTRATION_SUCCESS
// };

const registrationEnded = {
  type: REGISTRATION_PROCESS_ENDED,
};

const registrationValidationFailed = {
  type: REGISTRATION_VALIDATION_FAILED,
};

export const registrationErrorMessage = (payload) => {
  return { type: REGISTRATION_ERROR_MESSAGE, payload };
};

export const changePendingMessage = () => {
  return { type: CHANGE_EMAIL_PENDING_STATE };
};

const registerFor = (dispatch, payload) => {
  dispatch({
    type: REGISTRATION_TYPE,
    payload,
  });
};

const checkEmail = (dispatch, payload) => {
  auth
    .emailCheck(payload)
    .then(() => {
      dispatch(registrationErrorMessage(""));
    })
    .catch((error) => {
      dispatch(registrationErrorMessage(error.message));
    });
};

const forgotPassword = (dispatch, payload, history) => {
  auth
    .forgotPassword(payload)
    .then(async (data) => {
      await toast.success(data.data.success);
      // history.push("/auth/login");
      // dispatch(registrationErrorMessage(data.response.data.message));
    })
    .catch((error) => {
      toast.error(error.data.error);
    });
};

const resetPassword = (payload, history) => {
  auth
    .resetPassword(payload)
    .then(async () => {
      await toast.success("Password Reset Successfully");
      history.push("/auth/login");
    })
    .catch((error) => {
      let errorMessage = "";
      if (error.email) {
        errorMessage = error.email || error.password;
      }
      if (error.errors && error.errors.password) {
        errorMessage = error.errors.password[0];
      }
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong. " + errorMessage + "");
      }
    });
};

//Get User
const getCurrentUser = (dispatch) => {
  dispatch({ type: APP_LOADING_STARTS });
  auth
    .me()
    .then((payload) => {
      let { currentUser } = payload;
      if (currentUser && currentUser.id !== undefined) {
        dispatch({ type: UPDATE_AUTH, payload });
        dispatch({ type: APP_LOADING_ENDS });
      } else {
        localStorage.removeItem("auth_token");
        dispatch({ type: USER_LOGOUT });
        dispatch({ type: APP_LOADING_ENDS });
      }
    })
    .catch(() => {
      dispatch({ type: USER_LOGOUT });
      dispatch({ type: APP_LOADING_ENDS });
    });
};

const contactUs = ({ payload, dispatch }) => {
  dispatch(startButtonLoading);
  auth
    .contactUs(payload)
    .then(async () => {
      toast.success("Thanks you. We will get in touch with you soon.");
      dispatch(reset("ContactForm"));
      dispatch(stopButtonLoading);
    })
    .catch((error) => {
      dispatch(stopButtonLoading);
      toast.error(error.message);
    });
};

const uploadAvatar = (payload, dispatch) => {
  dispatch(startButtonLoading);
  auth
    .uploadAvatar(payload)
    .then(async () => {
      await dispatch(getCurrentUser(dispatch));
      toast.success("Avatar Uploaded Succesffuly");
      dispatch(stopButtonLoading);
    })
    .catch(() => {
      dispatch(stopButtonLoading);
      // toast.error("Something went wrong");
    });
};

const registerUser = (dispatch, payload, history) => {
  // dispatch(registrationStarted);
  dispatch(startButtonLoading);

  auth
    .register(payload)
    .then((data) => {
      let { token, currentUser, message = "", error } = data;
      if (token) {
        localStorage.setItem("auth_token", token);
        localStorage.setItem("redirect", true);
        dispatch({ type: UPDATE_AUTH, payload: { currentUser } });
        dispatch({
          type: REGISTRATION_SUCCESS,
        });
        toast.success("Registration successful.");
        if (currentUser.role.some((role) => role.title === "Investor")) {
          history.push("/user/preferences");
        } else {
          history.push("/user/deals");
        }
      }

      if (error) {
        dispatch(stopButtonLoading);
        dispatch({ type: PROFILE_STATUS, payload: { error: true, message } });
        history.push("/auth/profile-status");
      }
      dispatch(stopButtonLoading);

      // dispatch(registrationEnded);
    })
    .catch((error) => {
      if (error.response.status === 422) {
        dispatch(stopButtonLoading);
        return toast.error("The email has already been taken.");
      }
      toast.error("Something went wrong.");
      dispatch(registrationEnded);
      dispatch(stopButtonLoading);
    });
};

//Login Actions

const authenticationStarted = {
  type: USER_LOGIN_PROCESS_STARTED,
};

// const authenticationEnded = {
//   type: USER_LOGIN_PROCESS_ENDED
// };

const clearNotification = {
  type: CLEAR_LOGIN_NOTIFICATION,
};

const clearWarning = {
  type: CLEAR_LOGIN_WARNING,
};

//Login User

const loginUser = (dispatch, payload, history) => {
  let values = null;
  if (history.location.search !== "")
    values = queryString.parse(history.location.search);

  dispatch(startButtonLoading);
  dispatch(authenticationStarted);
  auth
    .login(payload)
    .then(async (data) => {
      dispatch(stopButtonLoading);
      let { token, payload, success, error = null, message } = data;

      if (token) {
        toast.success("Logged in successfully.");
        dispatch(userTokenData(data));
        if (token) {
          localStorage.setItem("auth_token", token);
          dispatch({
            type: UPDATE_AUTH,
            payload,
          });
        }
        if (values && values.redirect) {
          history.push(`${values.redirect}`);
        } else {
          history.push("/user/deals");
        }
      }

      if (error) {
        dispatch(stopButtonLoading);
        history.push("/auth/profile-status");
        await dispatch({
          type: PROFILE_STATUS,
          payload: { error: true, message },
        });
      }
    })
    .catch((error) => {
      dispatch(stopButtonLoading);

      let errorMessage = "";
      if (error.error) {
        errorMessage = error.error;
      }
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong.");
      }
    });
};

//Logout
// const logoutUser = {
//     type : USER_LOGOUT
// }

const logoutUser = (dispatch) => {
  dispatch({ type: APP_LOADING_STARTS });
  dispatch({ type: USER_LOGOUT });
  dispatch(hideUserMenu);
  dispatch({ type: APP_LOADING_ENDS });
};

export {
  registerUser,
  registerFor,
  loginUser,
  clearRegistrationAlerts,
  clearNotification,
  clearWarning,
  logoutUser,
  getCurrentUser,
  checkEmail,
  forgotPassword,
  resetPassword,
  uploadAvatar,
  contactUs,
};
