import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";

class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_bead: [],
    };
  }

  componentDidMount() {
    this.getBitById();
  }

  getBitById() {
    let bids = this.props.bids;
    let deal_id = this.props.deal_id;
    let arr = [];
    let iterator = 1;
    for (let item of bids) {
      if (item.deal_id === deal_id) {
        item.investor_name = "Investor " + iterator;
        arr.push(item);
        iterator++;
      }
    }
    this.setState({ current_bead: arr });
  }

  render() {
    const handleChange = () => {};
    const columns = [
      {
        name: "Investor",
        selector: "investor",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.investor_name}</div>,
      },
      {
        name: "Shares",
        selector: "share_count",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.share_count}</div>,
      },
      {
        name: "Investment Amount",
        selector: "investment_amount",
        sortable: false,
        left: true,
        style: { textAlign: "350px" },
        cell: (row) => <div>${row.investment_amount}</div>,
      },
    ];
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={this.props.handleClose}
          animation={false}
          style={{ top: "200px" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>All Bids</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="md-table-normal">
              <DataTable
                noHeader
                overflowY
                highlightOnHover
                responsive={true}
                columns={columns}
                data={this.state.current_bead}
                style={{ width: "100%" }}
                onSelectedRowsChange={handleChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer style={{ zIndex: "21", height: "78px" }}>
            <Button
              className="btn btn-outline-primary btn-sm"
              onClick={this.props.handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default Confirm;
