import actionTypes from "../actions/ipoActions";

const initialState = {
  ipos: [],
  selectedIpo: {},
  loading: false
};

const ipo = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.GET_ALL_IPOS:
      return {
        ...state,
        ipos: payload.data.data
      };

    case actionTypes.GET_SINGLE_IPO:
      return {
        ...state,
        selectedIpo: payload.data.data
      };

    default:
      return state;
  }
};

export default ipo;
