import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../../../../services/settings";
import { Icon } from "semantic-ui-react";
import { numberFormatter } from "../../../../../utils/constants";
import { customStyles } from "../../../../common/CommonMethods";
import NumberFormat from "react-number-format";

export default class CurrentBid extends Component {
  onView = (row) => {
    this.props.history.push(`/user/deal-view/${row.uuid}`);
  };

  handleChange = (row) => {
    this.props.history.push(`/user/deal-view/${row.uuid}`);
  };

  render() {
    const columns = [
      {
        name: "",
        selector: "picture",
        sortable: false,
        className: "company no-wrap",
        // style: { minWidth: "120px" },
        cell: (row) => (
          <>
            <div
              className="company-logo"
              onClick={() => this.handleChange(row)}
            >
              {row.picture && (
                <img
                  style={{
                    maxWidth: "70px",
                    maxHeight: "70px",
                    float: "left",
                  }}
                  src={`${BaseUrl}/storage/${row.picture.id}/${row.picture.file_name}`}
                  alt=""
                />
              )}
            </div>
          </>
        ),
      },
      {
        name: "Instrument",
        selector: "instrument",
        sortable: false,
        className: "company wrap",
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              <span>{row.instrument}</span>
            </div>
          </>
        ),
      },
      {
        name: "Sector",
        selector: "sector",
        sortable: false,
        className: "company wrap",
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              <span>{row.sector}</span>
            </div>
          </>
        ),
      },
      {
        name: "Location",
        selector: "location",
        sortable: false,
        className: "company wrap",
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              <span>{row.geography}</span>
            </div>
          </>
        ),
      },
      {
        name: "Raise Amount",
        selector: "offer",
        sortable: false,
        className: "company wrap",
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              <span>
                <NumberFormat
                  value={row.offer}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            </div>
          </>
        ),
      },
      // {
      //   name: "Estimated Exit Date",
      //   selector: "estimate_exit_date",
      //   sortable: false,
      //   className: "company wrap",
      //   cell: (row) => (
      //     <>
      //       <div className="wrap" onClick={() => this.handleChange(row)}>
      //         <span>{row.estimate_exit_date}</span>
      //       </div>
      //     </>
      //   ),
      // },
      {
        name: "Internal Rate of Return (IRR)",
        selector: "internal_return_rate",
        sortable: false,
        className: "company wrap",
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              <span>
                {row.internal_return_rate ? `${row.internal_return_rate}` : ""}
              </span>
            </div>
          </>
        ),
      },
      // {
      //   name: "Est. Rate of Return",
      //   selector: "estimate_return",
      //   sortable: false,
      //   className: "company wrap",
      //   cell: (row) => (
      //     <>
      //       <div className="wrap" onClick={() => this.handleChange(row)}>
      //         <span>
      //           {row.estimate_return ? `${row.estimate_return}` : ""}
      //         </span>
      //       </div>
      //     </>
      //   ),
      // },
      // {
      //   name: "Min Bid",
      //   selector: "min_bid",
      //   left: true,
      //   cell: (row) => (
      //     <>
      //       <div className="wrap" onClick={() => this.handleChange(row)}>
      //         <span>
      //           <NumberFormat
      //             value={row.min_bid}
      //             displayType={"text"}
      //             thousandSeparator={true}
      //             prefix={"$"}
      //           />
      //         </span>
      //       </div>
      //     </>
      //   ),
      // },
      // {
      //   name: "Current Yield",
      //   selector: "current_yield",
      //   left: true,
      //   cell: (row) => (
      //     <>
      //       <div className="wrap" onClick={() => this.handleChange(row)}>
      //         <span>{row.current_yield}</span>
      //       </div>
      //     </>
      //   ),
      // },
      // {
      //   name: "Loan to Value",
      //   selector: "loan_to_value",
      //   left: true,
      //   cell: (row) => (
      //     <>
      //       <div className="wrap" onClick={() => this.handleChange(row)}>
      //         <span>{row.loan_to_value}</span>
      //       </div>
      //     </>
      //   ),
      // },
      {
        name: "View",
        selector: "bid_now",
        sortable: false,
        left: true,
        cell: (row) => (
          <div className="tbl-title" onClick={() => this.onView(row)}>
            <span className="bid-now">
              <Icon
                style={{ cursor: "pointer" }}
                color="blue"
                size="huge"
                name="arrow alternate circle right outline"
              />
            </span>
          </div>
        ),
      },
    ];

    return (
      <div className="md-table-normal table-responsive">
        <DataTable
          noHeader
          highlightOnHover
          columns={columns}
          data={this.props.deals}
          onRowClicked={this.handleChange}
          pointerOnHover={true}
          customStyles={customStyles}
        />
      </div>
    );
  }
}
