import React, { Component } from "react";
import { connect } from "react-redux";
import interviewActions from "../../../../redux/actions/interviewActions";
import { BaseUrl } from "../../../../services/settings";
import { FileUpload } from "../../../common/Graphics";
var moment = require("moment");

class InterviewView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount = () => {
    const { getSelected, match } = this.props;

    getSelected({ id: match.params.uuid });
  };

  render() {
    const { interview } = this.props;

    return (
      <main className="page-deal-add-doc">
        <div className="md-top-deal">
          <div className="container">
            <div className="deal-inner">
              <div className="row">
                <div className="col-md-2">
                  <div className="logo">
                    <div className="inner">
                      <img
                        src={
                          interview &&
                          interview.logo &&
                          `${BaseUrl}/storage/${interview.logo.id}/${interview.logo.file_name}`
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="information">
                    <h1>{interview && interview.company_name}</h1>
                    <p className="sub-title">
                      ASX:
                      {interview.asx_code && interview.asx_code}
                    </p>
                    <div className="detail">
                      <div className="row">
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Last Price</p>
                            <p className="number">
                              ${interview.last_price && interview.last_price}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Market Capitalization </p>
                            <p className="number">${interview.market_cap}</p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Current Cash Balance </p>
                            <p className="number">${interview.current_cash}</p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">52 Week High</p>
                            <p className="price">
                              ${interview && interview.week_52_high}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">52 Week Low</p>
                            <p className="price">
                              ${interview && interview.week_52_low}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md-tabs tab-deal-add-doc">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <p>
                  <b>Interview Date: </b>{" "}
                  {interview.interview_date &&
                    moment(interview.interview_date, "DD/MM/YYYY").format(
                      "MMM. DD, YYYY"
                    )}
                </p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: interview.details,
                  }}
                />
                <iframe
                  style={{ width: 560, height: 315 }}
                  src={interview.youtube_url && interview.youtube_url}
                  frameborder="0"
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowfullscreen
                />
                <p style={{ marginTop: 30 }}>
                  <b>Key Highlights: </b>{" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: interview.key_highlights,
                    }}
                  />
                  <br />
                </p>
                <p>
                  <em>
                    All information is general in nature and does not take into
                    account your circumstances and, before acting, you should
                    consider its appropriateness having regard to your
                    circumstances. We do not make any representation about any
                    companies or financial products mentioned or that the
                    information is or will remain accurate, reliable or
                    complete. 180 Markets PTY LTD  ACN 638 381 129 is an
                    authorised representative (AR No. 001280199) of Pursuit
                    Capital Pty Ltd ACN 136 717 283 (AFSL No. 339211).
                  </em>
                </p>
              </div>
              <div className="col-md-5 mt-4">
                {interview &&
                  interview.documents &&
                  interview.documents.map((document) => {
                    return (
                      <div className="col-md-12 mb-4">
                        {document.document && (
                          <div className="smd-file-detail clearfix">
                            <div className="file-image">
                              <img src={FileUpload} alt="" width={42} />
                            </div>
                            <div className="file-meta">
                              <span className="name">{document.name}</span>
                              <div className="view clearfix">
                                {document.document && (
                                  <a
                                    target="_blank"
                                    href={`${BaseUrl}storage/${document.document.id}/${document.document.file_name}`}
                                  >
                                    View Document
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return { interview: state.interviews.selected };
};

const { getSelectedInterview } = interviewActions;

const mapDispatchToProps = (dispatch) => ({
  getSelected: (id) => getSelectedInterview({ dispatch, id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(InterviewView);
