import React, { Component } from "react";
import ProfileForm from "../../../forms/ProfileForm";
import { connect } from "react-redux";
import { updateProfile } from "../../../../redux/actions/profileActions";
import { deleteMediaSuccess } from "../../../../redux/actions/accountActions";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploaded: {
        newFile: [],
      },
      logo_url: null,
      open: false,
    };
  }

  open = (id) => {
    this.setState({ open: true, documentID: id });
  };

  close = () => this.setState({ open: false });

  componentDidMount() {
    let { currentUser } = this.props;
    let profile = {
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      email: currentUser.email,
      company_name: currentUser.company_name,
      phone: currentUser.phone,
      description: currentUser.description,
    };

    this.setState({
      profile,
      logo_url: currentUser.logo_url,
    });
  }

  handleSubmit = (values) => {
    let { newFile } = this.state.uploaded;
    if (newFile !== null) {
      values.logo = newFile;
    } else {
      values.logo = null;
    }
    this.props.updateProfile(values);
  };

  // onLogoUpload = event => {
  //   event.preventDefault();
  //   return this.setState({
  //     uploaded: { newFile: event.target.files[0] }
  //   });
  // };

  onLogoUpload = (files) => {
    this.setState({
      uploaded: { newFile: files },
    });
  };

  onDocumentDeleteHandler = async (id) => {
    let { deleteMedia, getCurrentUserData } = this.props;
    await deleteMedia({ id: this.state.documentID });
    this.setState({ open: false });
  };

  render() {
    return (
      <ProfileForm
        // onLogoUpload={this.onLogoUpload}
        onLogoUpload={this.onLogoUpload}
        onSubmit={this.handleSubmit}
        preview_url={this.state.logo_url}
        open={this.open}
        show={this.state.open}
        close={this.close}
        onDocumentDeleteHandler={this.onDocumentDeleteHandler}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  initialValues: {
    form: state.form,
    first_name: state.auth.currentUser.first_name,
    last_name: state.auth.currentUser.last_name,
    email: state.auth.currentUser.email,
    phone: state.auth.currentUser.phone,
    company_name: state.auth.currentUser.company_name,
    description: state.auth.currentUser.description,
    receive_bids_email: state.auth.currentUser.receive_bids_email,
  },
});

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (payload) => updateProfile(dispatch, payload),
  deleteMedia: (payload) => deleteMediaSuccess(dispatch, payload),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
