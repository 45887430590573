import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ViewBids from "../../../../common/ViewBids";
import Confirm from "../../../../common/Confirm";
import dealActions from "../../../../../redux/actions/dealActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { customStyles } from "../../../../common/CommonMethods";
import { BaseUrl } from "../../../../../services/settings";
import NumberFormat from "react-number-format";

class ClosedDeals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      open_view: false,
    };
  }

  componentDidMount = () => {
    const { fetchAllDEALS } = this.props;

    fetchAllDEALS();
  };

  open = (id) => {
    this.setState({ open: true, dealId: id });
  };

  close = () => this.setState({ open: false, open_view: false });

  deleteDeal = (id) => {
    let { deleteSingleDeal } = this.props;
    deleteSingleDeal(id);
    this.setState({ open: false });
  };

  onView = (id) => {
    this.props.history.push(`/user/deal-view/${id}`);
  };

  openBid = (id) => {
    this.setState({ open_view: true, view_dealId: id });
  };

  getBidsCount = (dealId) => {
    let res = 0;
    if (this.props.bids) {
      for (let item of this.props.bids) {
        if (item.deal_id === dealId) res++;
      }
    }
    return res;
  };

  render() {
    const handleChange = (row) => {};
    const columns = [
      {
        name: "",
        selector: "picture",
        sortable: false,
        className: "company no-wrap",
        // style: { minWidth: "120px" },
        cell: (row) => (
          <>
            <div
              className="company-logo"
              onClick={() => this.handleChange(row)}
            >
              {row.picture && (
                <img
                  style={{
                    maxWidth: "70px",
                    maxHeight: "70px",
                    float: "left",
                  }}
                  src={`${BaseUrl}/storage/${row.picture.id}/${row.picture.file_name}`}
                  alt=""
                />
              )}
            </div>
          </>
        ),
      },
      {
        name: "Instrument",
        selector: "instrument",
        sortable: false,
        className: "company wrap",
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              <span>{row.instrument}</span>
            </div>
          </>
        ),
      },
      {
        name: "Sector",
        selector: "sector",
        sortable: false,
        className: "company wrap",
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              <span>{row.sector}</span>
            </div>
          </>
        ),
      },
      {
        name: "Location",
        selector: "location",
        sortable: false,
        className: "company wrap",
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              <span>{row.geography}</span>
            </div>
          </>
        ),
      },
      {
        name: "Raise Amount",
        selector: "offer",
        sortable: false,
        className: "company wrap",
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              <span>
                <NumberFormat
                  value={row.offer}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            </div>
          </>
        ),
      },
      // {
      //   name: "Estimated Exit Date",
      //   selector: "estimate_exit_date",
      //   sortable: false,
      //   className: "company wrap",
      //   cell: (row) => (
      //     <>
      //       <div className="wrap" onClick={() => this.handleChange(row)}>
      //         <span>{row.estimate_exit_date}</span>
      //       </div>
      //     </>
      //   ),
      // },
      {
        name: "Internal Rate of Return (IRR)",
        selector: "internal_return_rate",
        sortable: false,
        className: "company wrap",
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              <span>
                {row.internal_return_rate ? `${row.internal_return_rate}` : ""}
              </span>
            </div>
          </>
        ),
      },
      // {
      //   name: "Est. Rate of Return",
      //   selector: "estimate_return",
      //   sortable: false,
      //   className: "company wrap",
      //   cell: (row) => (
      //     <>
      //       <div className="wrap" onClick={() => this.handleChange(row)}>
      //         <span>
      //           {row.estimate_return ? `${row.estimate_return}` : ""}
      //         </span>
      //       </div>
      //     </>
      //   ),
      // },
      // {
      //   name: "Min Bid",
      //   selector: "min_bid",
      //   left: true,
      //   cell: (row) => (
      //     <>
      //       <div className="wrap" onClick={() => this.handleChange(row)}>
      //         <span>
      //           <NumberFormat
      //             value={row.min_bid}
      //             displayType={"text"}
      //             thousandSeparator={true}
      //             prefix={"$"}
      //           />
      //         </span>
      //       </div>
      //     </>
      //   ),
      // },
      // {
      //   name: "Current Yield",
      //   selector: "current_yield",
      //   left: true,
      //   cell: (row) => (
      //     <>
      //       <div className="wrap" onClick={() => this.handleChange(row)}>
      //         <span>{row.current_yield}</span>
      //       </div>
      //     </>
      //   ),
      // },
      // {
      //   name: "Loan to Value",
      //   selector: "loan_to_value",
      //   left: true,
      //   cell: (row) => (
      //     <>
      //       <div className="wrap" onClick={() => this.handleChange(row)}>
      //         <span>{row.loan_to_value}</span>
      //       </div>
      //     </>
      //   ),
      // },
    ];

    return (
      <div className="md-table-normal">
        <DataTable
          noHeader
          highlightOnHover
          columns={columns}
          data={
            this.props.deals &&
            this.props.deals.filter((deal) => deal.status === "Closed")
          }
          sortServer={true}
          onSelectedRowsChange={handleChange}
          customStyles={customStyles}
        />
        {this.state.open_view ? (
          <ViewBids
            show={this.state.open_view}
            handleClose={this.close}
            bids={this.props.bids}
            deal_id={this.state.view_dealId}
          />
        ) : null}
        {this.state.open ? (
          <Confirm
            message="Are you sure you want to close this deal?"
            closeButtonText="Cancel"
            deleteButtonText="Yes, Close"
            show={this.state.open}
            handleDelete={() => this.deleteDeal(this.state.dealId)}
            handleClose={this.close}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bids: state.bids.bids,
    deals: state.deals.deals,
  };
};

const { fetchDEALS, deleteDeal } = dealActions;

const mapDispatchToProps = (dispatch) => ({
  deleteSingleDeal: (id) => deleteDeal({ dispatch, id }),
  fetchAllDEALS: (payload, history) =>
    fetchDEALS({ dispatch, payload, history }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClosedDeals));
