import InterviewService from "../../services/interviewsService";
import { toast } from "../../components/common/Toast";
import { startButtonLoading, stopButtonLoading } from "./loadingActions";

const actions = {
  FETCH_ALL_INTERVIEWS: "FETCH_ALL_INTERVIEWS",
  FETCH_INTERVIEW: "FETCH_INTERVIEW",

  getList: (dispatch, payload) => {
    dispatch({
      type: actions.FETCH_ALL_INTERVIEWS,
      payload,
    });
  },

  getSelected: (dispatch, payload) => {
    dispatch({
      type: actions.FETCH_INTERVIEW,
      payload,
    });
  },

  getAllInterviews: (dispatch) => {
    dispatch(startButtonLoading);
    InterviewService.getAll()
      .then((res) => {
        dispatch(stopButtonLoading);
        actions.getList(dispatch, res.data.data);
      })
      .catch((error) => {
        dispatch(stopButtonLoading);

        if (error) toast.error("Something went wrong.");
      });
  },

  getSelectedInterview: ({ dispatch, id }) => {
    dispatch(startButtonLoading);
    InterviewService.getSelected({ payload: id })
      .then((res) => {
        dispatch(stopButtonLoading);
        actions.getSelected(dispatch, res.data.data);
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) toast.error("Something went wrong.");
      });
  },
};

export default actions;
