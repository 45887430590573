import React, { Children, Component } from "react";
import Nprogress from "nprogress";
import ReactPlaceholder from "react-placeholder";
import "nprogress/nprogress.css";
import "react-placeholder/lib/reactPlaceholder.css";

// export default function asyncComponent(importComponent) {
class AsyncComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // component: null,
      status: false,
    };
  }
  UNSAFE_componentWillMount() {
    Nprogress.start();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  async componentDidMount() {
    this.mounted = true;
    // const Component = this.props.children;

    if (this.mounted) {
      this.setState({
        ...this.state,
        status: true,
      });
      Nprogress.done();

      // this.setState({
      //   component: <Component {...this.props} />,
      // });
    }
  }

  render() {
    // const Component = this.state.component || <div />;

    return (
      <ReactPlaceholder type="text" rows={7} ready={this.state.status}>
        {this.props.children}
      </ReactPlaceholder>
    );
  }
}
export default AsyncComponent;
// }
