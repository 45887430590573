import React, { Fragment, Component } from "react";
import { FormField } from "../layout/FormField";
import { required, email, length } from "redux-form-validators";
import { Field, reduxForm, Form } from "redux-form";
import Entities from "./Entities/EntityFormContainer";
import RemoteSubmitButton from "./RemoteButton";
import NewDropzone from "../common/NewDropzone";
import DraggerComponent from "../common/DraggerComponent/DraggerComponent";
import { scrollToFirstError } from "../../utils/scroller";

class InvestorForm extends Component {
  render() {
    let { handleSubmit } = this.props;
    return (
      <main className="page-signup page-create-account ">
        <div className="container">
          <div class="form-normal form-create-account profile-upgrade">
            <h1>Profile</h1>
            <Form onSubmit={handleSubmit}>
              <div className="all-fields">
                <div className="row mb-3">
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">First Name</label>
                      <div class="field-input">
                        <Field
                          className="form-control entity__input"
                          name="first_name"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Last Name</label>
                      <div class="field-input">
                        <Field
                          className="form-control entity__input"
                          name="last_name"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Phone</label>
                      <div class="field-input">
                        <Field
                          className="form-control entity__input"
                          name="phone"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Email</label>
                      <div class="field-input">
                        <Field
                          className="form-control entity__input"
                          type="text"
                          name="email"
                          component={FormField}
                          validate={[required(), email()]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: 50 }}>
                  <Entities {...this.props} />
                </div>
                <div className="row mb-3" style={{ marginTop: 50 }}>
                  {/* <div className="col-sm-6">
                    <h3>ID verification documents</h3>
                    <p>
                      Acceptable forms of identification include:
                      <br />
                      <br />
                      <Field
                        className="form-control entity__input"
                        name="form_of_identifications[passport]"
                        component={FormField}
                        type="checkbox"
                        label={<p>Australian Passport</p>}
                        htmlFor="any"
                      />
                      <br />
                      <Field
                        className="form-control entity__input"
                        name="form_of_identifications[driver_license]"
                        component={FormField}
                        type="checkbox"
                        label={<p>Driver’s License</p>}
                        htmlFor="any"
                      />
                      <br />
                      <Field
                        className="form-control entity__input"
                        name="form_of_identifications[aus_id_card]"
                        component={FormField}
                        type="checkbox"
                        label={<p>Australian Identity Cards</p>}
                        htmlFor="any"
                      />
                    </p>
                  </div> */}
                  {/* <div className="col-sm-6">
                      <Fragment>
                        <label>Upload ID verification documents</label>
                        <DraggerComponent />
                        <NewDropzone
                          multiple={true}
                          buttonLabel="Choose document"
                          title={"Upload ID verification documents"}
                          onUpload={this.props.onIDUpload}
                          multiDocuments={this.props.verificationDocument}
                          onDocumentDeleteHandler={
                            this.props.onDocumentDeleteHandler
                          }
                          open={this.props.open}
                          close={this.props.close}
                          show={this.props.show}
                        />
                      </Fragment>
                    </div> */}
                </div>
                <RemoteSubmitButton />
              </div>
            </Form>
          </div>
        </div>
      </main>
    );
  }
}

export default reduxForm({
  form: "InvestorProfileForm",
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(InvestorForm);
