import React from "react";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../../../services/settings";
import { Icon } from "semantic-ui-react";

const NewsLetterTable = ({ newsletters }) => {
  const columns = [
    {
      name: "",
      selector: "deal_logo",
      sortable: false,
      cell: (row) => (
        <>
          <div className="company-logo">
            {row.logo && (
              <img
                style={{
                  maxWidth: "70px",
                  maxHeight: "70px",
                  float: "left",
                }}
                src={`${BaseUrl}/storage/${row.logo.id}/${row.logo.file_name}`}
                alt=""
              />
            )}
          </div>
        </>
      ),
    },
    {
      name: "Date",
      selector: "company-name",
      sortable: false,
      left: true,
      cell: (row) => (
        <div className="company-name">{row.date && `${row.date}`}</div>
      ),
    },
    {
      name: "Description",
      selector: "company-name",
      sortable: false,
      left: true,
      cell: (row) => (
        <div className="company-name">
          {row.description && `${row.description}`}
        </div>
      ),
    },
    {
      name: "Newsletter",
      selector: "company-name",
      sortable: false,
      left: true,
      cell: (row) => (
        <div>
          <a
            href={`${BaseUrl}storage/${row.newsletter.id}/${row.newsletter.file_name}`}
          >
            <Icon name="file" size="huge" color="blue" /> View Newsletter
          </a>
        </div>
      ),
    },
  ];

  return (
    <div className="md-table-normal table-responsive">
      <DataTable
        noHeader
        highlightOnHover
        columns={columns}
        data={newsletters}
      />
    </div>
  );
};

export default NewsLetterTable;
