import AssetService from "../../services/assetService";

const actions = {
  FETCH_ALL_ASSETS: "FETCH_ALL_ASSETS",
  FETCH_ALL_ASSETS_ERROR: "FETCH_ALL_ASSETS_ERROR",
  FETCH_ALL_ASSETS_SUCCESS: "FETCH_ALL_ASSETS_SUCCESS",

  fetchAssets: (dispatch) =>
    AssetService
      .getAll()
      .then(({ data }) => {
        dispatch({ type: actions.FETCH_ALL_ASSETS_SUCCESS, payload: data });
      })
      .catch(() => {})
};
export default actions;
