import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class ProtectedRoutes extends Component {
  render() {
    let { component: Component, currentUser, isLoading, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          return currentUser && currentUser.id !== undefined ? (
            currentUser.realestate_terms === 1 ?
            (<Component {...props} />) : (
              <Redirect
              to={`/user/accept-terms`}
            />
            )
          ) : (
            <Redirect
              to={`/auth/login?redirect=${
                props.location.pathname + props.location.search
              }`}
            />
          );
        }}
      />
    );
  }
}

const mapStateToProps = ({ auth, app }) => ({
  currentUser: auth.currentUser,
  roles: auth.roles,
  isLoading: app.isLoading,
});

export default connect(mapStateToProps, null)(ProtectedRoutes);
