import actionTypes from "../actions/marketActions";

const initialState = {
  markets: [],
  loading: false
};

const markets = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.FETCH_ALL_MARKETS_SUCCESS:
      return {
        ...state,
        markets: payload
      };
    default:
      return state;
  }
};

export default markets;
