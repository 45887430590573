import React, { Component } from "react";
import { Upload, message, Popconfirm } from "antd";
import "antd/dist/antd.css";
import {
  InboxOutlined,
  DeleteOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { BaseUrl } from "../../../services/settings";

const { Dragger } = Upload;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const props = {
  showUploadList: {
    showDownloadIcon: false,
  },
};

class DraggerComponent extends Component {
  state = {
    selectedFile: null,
    selectedFileList: [],
  };

  confirm = (id) => {
    const { onDocumentDeleteHandler } = this.props;

    onDocumentDeleteHandler(id);
  };

  cancel = (e) => {
    console.log(e);
    // message.error("Click on No");
  };

  onChange = (info) => {
    const { handleIdUpload, name } = this.props;
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFileList = [info.file];
        break;
      case "done":
        nextState.selectedFile = info.file;
        nextState.selectedFileList = [info.file];
        message.success(`${info.file.name} file selected successfully.`);
        let object = {};
        object[name] = info.file.originFileObj;
        handleIdUpload(object);
        break;

      default:
        // error or removed
        nextState.selectedFile = null;
        nextState.selectedFileList = [];
    }
    this.setState(() => nextState);
  };

  render() {
    const { fileList } = this.props;
    return (
      <div className="App">
        <Dragger
          fileList={this.state.selectedFileList}
          customRequest={dummyRequest}
          onChange={this.onChange}
          {...props}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Support for a single file upload.</p>
        </Dragger>
        <div>
          {fileList && (
            <div
              title={fileList.file_name}
              style={{
                fontSize: "14px",
                paddingRight: "5px",
                paddingLeft: "5px",
              }}
            >
              <span>
                <PaperClipOutlined />
              </span>
              <span style={{ marginLeft: "6px" }}>
                <a
                  href={`${BaseUrl}storage/${fileList.id}/${fileList.file_name}`}
                  target="blank"
                >
                  {fileList.file_name}
                </a>
              </span>
              <span>
                <Popconfirm
                  title="Are you sure delete this document?"
                  onConfirm={() => this.confirm(fileList.id)}
                  onCancel={this.cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined
                    style={{
                      float: "right",
                      cursor: "pointer",
                      marginTop: "2px",
                    }}
                    onClick={(e) => console.log(e, "custom removeIcon event")}
                  />
                </Popconfirm>
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DraggerComponent;
