import React, { Component } from "react";
import { connect } from "react-redux";
import CreateDealForm from "../../../forms/Deals/CreateDealForm";
import DealActions from "../../../../redux/actions/dealActions";
import moment from "moment";

class CreateDeal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      deal_logo: null,
      term_sheets: null,
      additional_document: null,
      information_overview: null,
      files: [],
      details: "",
      helpful_links: "",
    };
  }

  handleSubmit = (values) => {
    const { history, updateSelectedDeal, match } = this.props;

    if (match.params.id) {
      let {
        deal_logo,
        term_sheets,
        information_overview,
        additional_document,
        details,
        helpful_links,
      } = this.state;

      return updateSelectedDeal(
        {
          ...values,
          deal_logo,
          term_sheets,
          details,
          additional_document,
          information_overview,
          id: match.params.id,
          helpful_links,
        },
        history
      );
    }
    let {
      deal_logo,
      term_sheets,
      information_overview,
      additional_document,
      details,
      helpful_links,
    } = this.state;
    this.props.createDeal(
      {
        ...values,
        deal_logo,
        term_sheets,
        details,
        information_overview,
        helpful_links,
        additional_document,
      },
      history
    );
  };

  handleUpload = (file) => {
    return this.setState({
      deal_logo: file,
    });
  };

  handleMultipleFilesTermSheet = (files) => {
    return this.setState({
      term_sheets: files,
    });
  };

  handleMultipleFilesInfo = (files) => {
    return this.setState({
      information_overview: files,
    });
  };

  handleAdditionalDocument = (files) => {
    return this.setState({
      additional_document: files,
    });
  };

  getHelpfulLinks = (value) => {
    this.setState({ helpful_links: value });
  };

  editorValue = (value) => {
    this.setState({ details: value });
  };

  render() {
    const { match } = this.props;

    return (
      <CreateDealForm
        onSubmit={this.handleSubmit}
        handleUpload={this.handleUpload}
        {...this.props}
        handleMultipleFilesTermSheet={this.handleMultipleFilesTermSheet}
        handleMultipleFilesInfo={this.handleMultipleFilesInfo}
        handleAdditionalDocument={this.handleAdditionalDocument}
        paramsID={this.props.paramsID}
        editorValue={this.editorValue}
        getHelpfulLinks={this.getHelpfulLinks}
        title={match.params.id ? "Update" : "Add New"}
      />
    );
  }
}

const { createDeal, updateDeal } = DealActions;

const mapDispatchToProps = (dispatch) => ({
  createDeal: (payload, history) => createDeal({ dispatch, payload, history }),
  updateSelectedDeal: (payload, history) =>
    updateDeal(payload, history, dispatch),
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDeal);
