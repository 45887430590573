import actionTypes from "../actions/directorActions";

const initialState = {
  shares: [],
  indications: [],
  selectedShare: {},
  loading: false,
};

const shares = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.GET_ALL_DIRECTOR_SHARES:
      return {
        ...state,
        shares: payload.data.data,
      };

    case actionTypes.GET_ALL_INDICATIONS:
      return {
        ...state,
        indications: payload.data,
      };

    case actionTypes.GET_SINGLE_INDICATION:
      return {
        ...state,
        selectedShare: payload.data,
      };

    default:
      return state;
  }
};

export default shares;
