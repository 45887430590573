import React, { Fragment, Component } from "react";
// import { IconUpload } from "../../common/Graphics";
import IconUpload from "../../../assets/images/ico-upload.png";

class InputFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewImageUrl: null
    };
  }

  handleUpload = event => {
    const { onUpload } = this.props;
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        previewImageUrl: reader.result
      });
    };
    reader.readAsDataURL(file);

    onUpload(event);
  };

  triggerUpload = () => {
    const { name = "upload-file" } = this.props;
    document.getElementsByName(name)[0].click();
  };

  render() {
    let {
      onUpload,
      previewImageUrl = null,
      buttonLabel = "Change logo",
      title = "Upload Company Logo",
      name = "upload-file",
      accept = "*",
      ...rest
    } = this.props;

    previewImageUrl = this.state.previewImageUrl || this.props.previewImageUrl;

    return (
      <div className="field">
        <label className="title">{title}</label>
        <div className="field-input is-field-upload">
          <div className="text-upload">
            {previewImageUrl ? (
              <Fragment>
                <div className="icon">
                  <img
                    src={previewImageUrl || previewImageUrl}
                    alt="Upload Here"
                    className="previewUrl"
                  />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="icon">
                  <img
                    src={IconUpload}
                    alt="Upload Here"
                    className="img-fluid"
                  />
                </div>
                <p>
                  Click and drag a logo to this area or choose from a library
                </p>
              </Fragment>
            )}
          </div>
          <div className="file-upload">
            <label
              htmlFor="file-upload-01"
              className="btn btn-outline-primary"
              onClick={this.triggerUpload}
            >
              {buttonLabel}
            </label>
            <input
              type="file"
              className="input-file"
              onChange={event => this.handleUpload(event)}
              name={name}
              accept={accept}
              {...rest}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default InputFile;
