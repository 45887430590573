import React, { Component } from "react";
import { FormField, DatePickerField } from "../../layout/FormField";
import { Field, reduxForm, Form } from "redux-form";
import { required } from "redux-form-validators";
import NewDropzone from "../../common/NewDropzone";
import ButtonLoader from "../../common/ButtonLoader";

import { connect } from "react-redux";
import DraftComponent from "../../common/DraftComponent";
import { scrollToFirstError } from "../../../utils/scroller";

const brokerOptions = ["Yes", "No"];

class CreateDealForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validate: false,
    };
  }

  validate = () => {
    this.setState({ validate: true });
  };

  render() {
    let {
      handleSubmit,
      handleUpload,
      title,
      form,
      editorValue,
      getHelpfulLinks,
    } = this.props;
    return (
      <main className="page-ipos page-add-new-deal">
        <div className="container">
          <div className="form-normal">
            <h1>{title} Deal</h1>
            <Form onSubmit={handleSubmit}>
              <div className="all-fields">
                <div className="row">
                  <div className="col-sm-6">
                    {/* <div className="field">
                      <label className="title">Name of deal</label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="name"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div> */}
                    <div className="field">
                      <label className="title">Company Name</label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="company_name"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="title">ASX Code</label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="asx_code"
                          component={FormField}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <NewDropzone
                      multiple={false}
                      buttonLabel="Upload deal logo"
                      title={"Upload deal logo"}
                      accept="image/*"
                      onUpload={(file) => handleUpload(file)}
                      singleDocuments={
                        this.props.initialValues &&
                        this.props.initialValues.deal_logo && [
                          this.props.initialValues.deal_logo,
                        ]
                      }
                      onDocumentDeleteHandler={
                        this.props.onDocumentDeleteHandler
                      }
                      open={this.props.open}
                      close={this.props.close}
                      show={this.props.show}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-md-4">
                    <div className="field">
                      <label
                        className="title"
                        // className="title is-tooltip"
                        // data-tooltip="Add your tooltip here"
                        // data-position="right center"
                      >
                        Last Price
                      </label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="last_price"
                          component={FormField}
                          type="text"
                          step="0.01"
                          min="0"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="field">
                      <label
                        // className="title is-tooltip"
                        // data-tooltip="Add your tooltip here"
                        // data-position="right center"
                        className="title"
                      >
                        Capital Raise Price
                      </label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="capital_raise"
                          component={FormField}
                          type="text"
                          step="0.01"
                          min="0"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="field">
                      <label
                        className="title"
                        // className="title is-tooltip"
                        // data-tooltip="Add your tooltip here"
                        // data-position="right center"
                      >
                        Discount
                      </label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="discount"
                          component={FormField}
                          type="text"
                          step="0.01"
                          min="0"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="field">
                      <label className="title">Raise Amount </label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="raise_amount"
                          component={FormField}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="field">
                      <label className="title">Free Option </label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="free_option"
                          component={FormField}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="field">
                      <label className="title">Market Capitalisation</label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="market_capital"
                          component={FormField}
                          type="text"
                          step="0.01"
                          min="0"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row hl-margin">
                  <div className="col-sm-6">
                    <div className="field">
                      <label
                        className="title"
                        // className="title is-tooltip"
                        // data-tooltip="Add your tooltip here"
                        // data-position="right center"
                      >
                        Bids Due
                      </label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="bids_due"
                          component={DatePickerField}
                          type="text"
                          placeholder="DD/MM/YYYY"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="field">
                      <label
                        className="title"
                        // className="title is-tooltip"
                        // data-tooltip="Add your tooltip here"
                        // data-position="right center"
                      >
                        Settlement Date
                      </label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="settlement_date"
                          component={DatePickerField}
                          type="text"
                          placeholder="DD/MM/YYYY"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="field">
                      <label className="title">Helpful Links</label>
                      <div className="field-input">
                        <DraftComponent
                          initialValue={
                            this.props.initialValues &&
                            this.props.initialValues.helpful_links
                          }
                          getValue={getHelpfulLinks}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="field">
                      <label className="title">Summary of Deal</label>
                      <div className="field-input">
                        <DraftComponent
                          initialValue={
                            this.props.initialValues &&
                            this.props.initialValues.details
                          }
                          getValue={editorValue}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <NewDropzone
                      multiple={false}
                      buttonLabel="Choose document"
                      title={"Upload Term sheet"}
                      onUpload={this.props.handleMultipleFilesTermSheet}
                      singleDocuments={
                        this.props.initialValues &&
                        this.props.initialValues.term_sheets && [
                          this.props.initialValues.term_sheets,
                        ]
                      }
                      onDocumentDeleteHandler={
                        this.props.onDocumentDeleteHandler
                      }
                      open={this.props.open}
                      close={this.props.close}
                      show={this.props.show}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <NewDropzone
                      multiple={false}
                      buttonLabel="Choose document"
                      title={"Company information / Research"}
                      onUpload={this.props.handleMultipleFilesInfo}
                      singleDocuments={
                        this.props.initialValues &&
                        this.props.initialValues.information_overview && [
                          this.props.initialValues.information_overview,
                        ]
                      }
                      onDocumentDeleteHandler={
                        this.props.onDocumentDeleteHandler
                      }
                      open={this.props.open}
                      close={this.props.close}
                      show={this.props.show}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-6">
                    <div className="field">
                      <label className="title">
                        Name of Additional Document{" "}
                      </label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="additional_document_name"
                          component={FormField}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <NewDropzone
                      multiple={false}
                      buttonLabel="Choose document"
                      title={"Additional Document"}
                      onUpload={this.props.handleAdditionalDocument}
                      singleDocuments={
                        this.props.initialValues &&
                        this.props.initialValues.additional_document && [
                          this.props.initialValues.additional_document,
                        ]
                      }
                      onDocumentDeleteHandler={
                        this.props.onDocumentDeleteHandler
                      }
                      open={this.props.open}
                      close={this.props.close}
                      show={this.props.show}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-8">
                    <div className="field">
                      <label className="title">Are you the lead broker?</label>
                      <div className="field-input form-group row">
                        {brokerOptions.map((element, key) => {
                          return (
                            <Field
                              key={key}
                              name="is_lead_broker"
                              component={FormField}
                              type="radio"
                              value={element}
                              label={element}
                              className={`radio-inline`}
                            />
                          );
                        })}
                      </div>
                      <p style={{ fontSize: "12px", color: "red " }}>
                        {this.state.validate &&
                          form &&
                          form.CreateDealForm &&
                          form.CreateDealForm.syncErrors &&
                          form.CreateDealForm.syncErrors.is_lead_broker &&
                          `This field is ${form.CreateDealForm.syncErrors.is_lead_broker}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="button-group w-small">
                  <div className="col-xs-12 col-sm-6 col-md-6">
                    <ButtonLoader
                      className="btn btn-primary btn-medium"
                      onClick={this.validate}
                    >
                      {title === "Update" ? "Update" : "Save"}
                    </ButtonLoader>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6">
                    <button
                      type="button"
                      onClick={() => this.props.history.push("/user/deals")}
                      className="btn btn-outline-primary btn-medium"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form,
  };
};

export default reduxForm({
  form: "CreateDealForm",
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(connect(mapStateToProps, null)(CreateDealForm));
