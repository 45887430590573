import React, { Component } from "react";
import { connect } from "react-redux";
import Allocations from "./Allocations";
import deals from "../../../redux/actions/dealActions";
import EntityActions from "../../../redux/actions/entityActions";

class AllocationsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entity: false,
      split: false,
      shares: {},
    };
  }

  componentDidMount = async () => {
    const { match, getAllocate, fetchAllEntities } = this.props;
    fetchAllEntities();
    await getAllocate(match.params.token);
  };

  selectEntity = () => {
    this.setState((prevState) => {
      return {
        entity: !prevState.entity,
      };
    });
  };

  splitAllocationSelect = () => {
    this.setState((prevState) => {
      return {
        split: !prevState.split,
      };
    });
  };

  handleSharesArray = (values) => {
    let shares_array = [];

    for (let key in values) {
      let splittedValue = key.split("-");
      if (splittedValue[0] === "share_count") {
        shares_array.push({
          entity_id: splittedValue[1],
          share_count: values[key],
        });
      }
    }

    return shares_array;
  };

  handleSharesAmount = () => {
    let { allocations, form } = this.props;
    let values = form.Allocations && form.Allocations.values;
    let greater = false;
    let enteredShares = 0;
    for (let key in values) {
      if (key.includes("share_count")) {
        enteredShares += parseFloat(values[key]);
      }
    }

    if (
      (parseInt(enteredShares) > parseInt(allocations.allocation_amount) ||
        parseInt(enteredShares) < parseInt(allocations.allocation_amount)) &&
      enteredShares > 0
    ) {
      greater = true;
    } else {
      greater = false;
    }

    return greater;
  };

  handleAllocation = async (value) => {
    const {
      acceptAllocation,
      history,
      allocations,
      match,
      getAllocate,
    } = this.props;

    if (!this.handleSharesAmount()) {
      await acceptAllocation(
        {
          status: value.status ? "Accepted" : "",
          id: allocations.id,
          entity_id: value.entity_id,
          spilit_allocation: value.split_allocation,
          spilit_array: value.split_allocation
            ? this.handleSharesArray(value)
            : null,
        },
        history
      );
      getAllocate(match.params.token);
    }
  };

  onDeclineHandler = () => {
    const { declineAllocation, history, allocations } = this.props;

    declineAllocation({ status: "Declined", id: allocations.id }, history);
  };

  render() {
    const { allocations, entities } = this.props;

    return (
      <>
        <Allocations
          allocations={allocations}
          onSubmit={this.handleAllocation}
          onDeclineHandler={this.onDeclineHandler}
          entities={entities}
          selectEntity={this.selectEntity}
          entity={this.state.entity}
          split={this.state.split}
          splitAllocationSelect={this.splitAllocationSelect}
          initialValues={{
            entity_id:
              allocations &&
              allocations.bid &&
              allocations.bid.entity &&
              allocations.bid.entity.id,
          }}
          onShareCountChangeHander={this.onShareCountChangeHander}
          handleSharesAmount={this.handleSharesAmount}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  allocations: state.deals.allocations,
  entities: state.entities.entities,
  form: state.form,
});

const {
  getAllocations,
  acceptAllocationStatus,
  declineAllocationStatus,
} = deals;
const { fetchEntities } = EntityActions;

const mapDispatchToProps = (dispatch) => ({
  fetchAllEntities: (payload, history) =>
    fetchEntities({ dispatch, payload, history }),
  getAllocate: (token) => getAllocations(token, dispatch),
  acceptAllocation: (payload, history) =>
    acceptAllocationStatus(payload, history, dispatch),
  declineAllocation: (payload, history) =>
    declineAllocationStatus(payload, history, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllocationsComponent);
