import React, { Component } from "react";
import { FormField } from "../layout/FormField";
import { required, email, length } from "redux-form-validators";
import { Field, reduxForm, Form } from "redux-form";
import { connect } from "react-redux";
import { checkEmail } from "../../redux/actions/authActions";
import ButtonLoader from "../common/ButtonLoader";
import TermsModal from "../common/TermsModal";
import { Link } from "react-router-dom";
import {
  phoneNumber,
  minLength,
  matchPasswords,
} from "../../utils/customValidators";

class InvestorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTerms: false,
    };
  }

  onBlurHandler = (e) => {
    let { checkEmailExistence } = this.props;
    checkEmailExistence(e.target.value);
  };

  openModal = () => {
    this.setState({
      showTerms: true,
    });
  };

  render() {
    let { handleSubmit, register } = this.props;
    return (
      <main className="page-main page-signup page-create-account">
        <div className="container">
          <div class="form-normal form-create-account" style={{marginTop: 30}}>
            <h2>Create an Investor account</h2>
            <Form onSubmit={handleSubmit}>
              <div className="all-fields">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">First Name</label>
                      <div className="field-input">
                        <Field
                          className="form-control entity__input"
                          name="first_name"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Last Name</label>
                      <div class="field-input">
                        <Field
                          className="form-control entity__input"
                          name="last_name"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Phone</label>
                      <div class="field-input">
                        <Field
                          className="form-control entity__input"
                          name="phone"
                          component={FormField}
                          type="number"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Email</label>
                      <div class="field-input">
                        <Field
                          className="form-control entity__input"
                          type="text"
                          name="email"
                          component={FormField}
                          onChange={(e) => this.onBlurHandler(e)}
                          validate={[required(), email()]}
                        />
                        <div className="form__field-error">
                          {register.emailError ? register.emailError : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Password</label>
                      <div class="field-input">
                        <Field
                          className="form-control entity__input"
                          name="password"
                          component={FormField}
                          type="password"
                          validate={[required(), minLength(6)]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Confirm Password</label>
                      <div class="field-input">
                        <Field
                          className="form-control entity__input"
                          name="password_confirmation"
                          component={FormField}
                          type="password"
                          validate={[
                            required(),
                            matchPasswords(),
                            length({ min: 6 }),
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="field">
                    <Field
                      className="form-control entity__input"
                      name="terms_conditions"
                      component={FormField}
                      type="checkbox"
                      label={
                        <p>
                          I accept the{" "}
                          <Link
                            to={"/investor/terms&conditions"}
                            target="_blank"
                          >{`Terms & Conditions`}</Link>{" "}
                          and{" "}
                          <Link
                            to={"/privacy-policy"}
                            target="_blank"
                          >{`Privacy Policy`}</Link>
                        </p>
                      }
                      requiredError="Accept our terms and conditions"
                      validate={[required()]}
                      htmlFor="any"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="button-group">
                  <div className="col-sm-6">
                    <ButtonLoader
                      type="submit"
                      className="btn btn-primary btn-medium"
                    >
                      Create account
                    </ButtonLoader>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          {/* Terms and conditions modal */}
          <TermsModal
            heading={`Terms & Conditions`}
            show={this.state.showTerms}
            onClose={() =>
              this.setState({
                showTerms: false,
              })
            }
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </TermsModal>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    register: state.register,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkEmailExistence: (email) => checkEmail(dispatch, email),
});

export default reduxForm({ form: "InvestorForm" })(
  connect(mapStateToProps, mapDispatchToProps)(InvestorForm)
);
