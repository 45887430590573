import actionTypes from "../actions/bids.Actions";

const initialState = {
    bids: [],
    loading: false,
};

const bids = (state = initialState, action) => {
    const {payload} = action;
    switch (action.type) {
        case actionTypes.FETCH_ALL_BIDS:
            return {
                ...state,
                bids: payload
            };

        default:
            return state;
    }
};

export default bids;