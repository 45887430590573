import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

class Confirm extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.message}</Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-outline-primary btn-sm"
            onClick={this.props.handleClose}
          >
            {this.props.closeButtonText ? this.props.closeButtonText : "Cancel"}
          </Button>
          <Button
            className="btn btn-outline-warning btn-sm"
            onClick={this.props.handleDelete}
          >
            {this.props.deleteButtonText
              ? this.props.deleteButtonText
              : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Confirm;
