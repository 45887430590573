import React, { Component } from "react";
import { LogoCompany, FileUpload } from "../../../common/Graphics";
import DealsViewForm from "./DealsViewForm/DealsViewForm";
import { connect } from "react-redux";
import EntityActions from "../../../../redux/actions/entityActions";
import dealActions from "../../../../redux/actions/dealActions";
import { withRouter } from "react-router-dom";
import BidActions from "../../../../redux/actions/bids.Actions";
import { BaseUrl } from "../../../../services/settings";
import moment from "moment";

import Confirm from "../../../common/Confirm";
import ModalComponent from "../../../common/Modal";
const queryString = require("query-string");

class DealView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectEntityOptions: [],
      open: false,
    };
  }

  componentDidMount = () => {
    let {
      getSingleDealData,
      fetchAllEntities,
      match,
      currentUser,
      getDealStatus,
      bidStatus,
    } = this.props;
    if (currentUser && localStorage.getItem("auth_token")) {
      fetchAllEntities();
      getDealStatus(match.params.uuid);
    }
    getSingleDealData(match.params.uuid);
  };

  renderIndividualName = (entity) => {
    let name = "";
    for (let key in entity.entity_individuals) {
      if (name.length > 0) {
        return (name +=
          " " +
          "&" +
          " " +
          entity.entity_individuals[key].given_name +
          " " +
          entity.entity_individuals[key].sur_name);
      }

      name +=
        entity.entity_individuals[key].given_name +
        " " +
        entity.entity_individuals[key].sur_name;
    }

    return name;
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.existingEntity &&
      this.props.existingEntity !== nextProps.existingEntity &&
      nextProps.currentUser.id
    ) {
      this.setState({
        selectEntityOptions: nextProps.existingEntity.map((entity) => {
          if (entity.entity_company !== null)
            return {
              value: entity.id,
              text: entity.entity_company.name,
              value: entity.id,
            };
          else if (entity.entity_trust !== null)
            return {
              value: entity.id,
              text: entity.entity_trust.name,
              value: entity.id,
            };
          else if (entity.entity_individual !== null)
            return {
              value: entity.id,
              text: this.renderIndividualName(entity),
              value: entity.id,
            };
        }),
      });
    }
  };

  cancelBidHanler = (id) => {
    this.setState({ open: false });
    let { cancelBid, history } = this.props;
    cancelBid({ bidId: id }, history);
  };

  onSubmitBid = async (values) => {
    let {
      updateBidSubmit,
      currentDeal,
      bidStatus,
      currentUser,
      updateIndicationSubmit,
      history,
    } = this.props;
    if (bidStatus.myBid) {
      await updateBidSubmit(
        {
          ...values,
          property_id: currentDeal.id,
          bidId: bidStatus.myBid.uuid,
        },
        history
      );
    }
  };

  render() {
    let { currentDeal, currentUser, bidStatus, location } = this.props;
    const parsed = queryString.parse(location.search);

    return (
      <main className="page-deal-add-doc">
        <div className="md-top-deal">
          <div className="container">
            <div className="deal-inner">
              <div className="row">
                {/* <div className="col-md-2">
                  <div className="logo">
                    <div className="inner">
                      <img
                        src={
                          currentDeal &&
                          currentDeal.deal_logo &&
                          `${BaseUrl}/storage/${currentDeal.deal_logo.id}/${currentDeal.deal_logo.file_name}`
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-md-10">
                  <div className="information">
                    <h1>
                      {currentDeal &&
                        currentDeal.manager &&
                        currentDeal.manager.company_name}
                    </h1>
                    {/* <p className="sub-title">
                      ASX:
                      {currentDeal &&
                        currentDeal.asx_code &&
                        currentDeal.asx_code}
                    </p> */}
                    <div className="detail">
                      <div className="row">
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text"> Offer Size</p>
                            <p className="number">
                              $
                              {currentDeal &&
                                currentDeal.offer &&
                                currentDeal.offer}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Est. Exit Date</p>
                            <p className="number">
                              {currentDeal &&
                                currentDeal.estimate_exit_date &&
                                `${currentDeal.estimate_exit_date}`}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Est. Rate of Return </p>
                            <p className="number">
                              {currentDeal && currentDeal.estimate_return
                                ? currentDeal.estimate_return + " %"
                                : ""}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Current yield</p>
                            <p className="number">
                              {currentDeal &&
                                currentDeal.current_yield &&
                                `$${currentDeal.current_yield}`}
                            </p>
                          </div>
                        </div>

                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Loan to Value</p>
                            <p className="price">
                              $
                              {currentDeal &&
                                currentDeal.loan_to_value &&
                                currentDeal.loan_to_value}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md-tabs tab-deal-add-doc">
          <div className="container">
            <div className="md-tabs tab-deal-add-doc">
              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    <p>
                      <b>Fund Name: </b>{" "}
                      {currentDeal &&
                        currentDeal.fund_name &&
                        currentDeal.fund_name}
                    </p>
                    <p>
                      <b>Fund Manager: </b>{" "}
                      {currentDeal &&
                        currentDeal.manager &&
                        currentDeal.manager.full_name}
                    </p>
                    <p>
                      <b>Industry: </b>{" "}
                      {currentDeal &&
                        currentDeal.industry &&
                        currentDeal.industry}
                    </p>
                    <p>
                      <b>Sector: </b>{" "}
                      {currentDeal && currentDeal.sector && currentDeal.sector}
                    </p>
                    <p>
                      <b>Geography: </b>{" "}
                      {currentDeal &&
                        currentDeal.geography &&
                        currentDeal.geography}
                    </p>
                    <p>
                      <b>Property Type: </b>{" "}
                      {currentDeal &&
                        currentDeal.property_type &&
                        currentDeal.property_type}
                    </p>

                    <div className="row mt-5">
                      {currentDeal && currentDeal.additional_document && (
                        <div className="col-md-12 mb-4">
                          <div className="smd-file-detail clearfix">
                            <div className="file-image">
                              <img src={FileUpload} alt="" width={42} />
                            </div>
                            <div className="file-meta">
                              <span className="name">
                                {currentDeal.additional_document_name}
                              </span>
                              <div className="view clearfix">
                                <a
                                  target="_blank"
                                  href={`${BaseUrl}storage/${currentDeal.additional_document.id}/${currentDeal.additional_document.file_name}`}
                                >
                                  View Document
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5">
                    {currentUser.role &&
                    currentUser.role.some(
                      (role) => role.title === "Investor"
                    ) &&
                    currentUser.realestate_approved === "Approved" &&
                    bidStatus ? (
                      <div className="md-deal-form">
                        <div className="form-normal">
                          <DealsViewForm
                            initialValues={
                              bidStatus.myBid
                                ? {
                                    entity_id: bidStatus.myBid.entity_id,
                                    share_count: bidStatus.myBid.share_count,
                                    investment_amount:
                                      bidStatus.myBid.investment_amount,
                                  }
                                : {
                                    investment_amount:
                                      bidStatus.myIndication &&
                                      bidStatus.myIndication.investment_amount,
                                  }
                            }
                            currentUser={currentUser}
                            onSubmit={this.onSubmitBid}
                            selectEntityOptions={this.state.selectEntityOptions}
                            capRaisePrice={
                              currentDeal && currentDeal.capital_raise
                            }
                            currentDeal={currentDeal}
                          />
                        </div>
                      </div>
                    ) : null}
                    {currentUser.role &&
                      currentUser.role.some(
                        (role) => role.title === "Investor"
                      ) &&
                      currentUser.realestate_approved === "Pending" && (
                        <div className="md-deal-form">
                          <div className="form-normal dealBidMessage">
                            <div className="deal-form-content">
                              <p className="bidMessage">
                                Complete your profile to bid for deal.
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    {currentUser.role &&
                      currentUser.role.some(
                        (role) => role.title !== "Investor"
                      ) && (
                        <div className="md-deal-form">
                          <div className="form-normal dealBidMessage">
                            <div className="deal-form-content">
                              <p className="bidMessage">
                                Register as an investor to bid for deal.
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    {!currentUser ||
                      (!localStorage.getItem("auth_token") && (
                        <div className="md-deal-form">
                          <div className="form-normal dealBidMessage">
                            <div className="deal-form-content">
                              <p className="bidMessage">
                                Login to bid for deal.
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    existingEntity: state.entities.entities,
    currentDeal: state.deals.singleDeal,
    currentUser: state.auth.currentUser,
    bidStatus: state.deals.bidStatus,
  };
};

const { fetchEntities } = EntityActions;
let { singlePublicDeal, getSingleDeal } = dealActions;
let { updateBid, deleteBid, updateIndication } = BidActions;

const mapDispatchToProps = (dispatch) => ({
  fetchAllEntities: (payload, history) =>
    fetchEntities({ dispatch, payload, history }),
  getSingleDealData: (payload) => singlePublicDeal(payload, dispatch),
  getDealStatus: (payload) => getSingleDeal(payload, dispatch),
  updateBidSubmit: (payload, history) => updateBid(payload, dispatch, history),
  cancelBid: (payload, history) => deleteBid(payload, dispatch, history),
  updateIndicationSubmit: (payload, history) =>
    updateIndication(payload, dispatch, history),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DealView));
