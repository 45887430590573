import React, { Component } from "react";
import { Button, Header, Icon, Image, Modal } from "semantic-ui-react";
// import FileViewer from "react-file-viewer";

class ModalComponent extends Component {
  file = "http://www.africau.edu/images/default/sample.pdf";
  type = "pdf";
  render() {
    return (
      <Modal
        open={this.props.modalOpen}
        onClose={() => this.props.handleClose()}
      >
        <Modal.Header>{this.props.fileName}</Modal.Header>
        <Modal.Content>
          {/* <FileViewer fileType={this.type} filePath={this.file} /> */}
        </Modal.Content>
      </Modal>
    );
  }
}

export default ModalComponent;
