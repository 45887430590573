import React, { Component } from "react";
import Cardblock from "../../common/Cardblock";
import { withRouter } from "react-router-dom";

class RegisterType extends Component {
  onUserTypeSelection = (type) => {
    this.props.onSelect(type);
  };

  render() {
    return (
      <main className="page-main page-signup page-choose-account">
        <div className="container">
          <div className="top-content account_title">
            <h1>What kind of account do you want to create?</h1>
            <div className="excerpt">
              <p>
                {/* Our Mission is to UNLOCK the ASX and make it easier and fairer */}
                {/* for Investors to access Capital raises. */}
              </p>
            </div>
          </div>
          <div className="main-content">
            <div className="row">
              <div className="col-md-4">
                <Cardblock
                  heading="Investor"
                  // excerpt="A little paragraph of text about who Investor is and what benefits he can take from this app"
                  buttonText="Continue as Investor"
                  action={() =>
                    this.props.history.push("/auth/register/investor")
                  }
                />
              </div>
              <div className="col-md-4">
                <Cardblock
                  heading="Broker"
                  // excerpt="A little paragraph of text about who Broker is and what benefits he can take from this app"
                  buttonText="Continue as Broker"
                  action={() =>
                    this.props.history.push("/auth/register/broker")
                  }
                />
              </div>
              <div className="col-md-4">
                <Cardblock
                  heading="Listed Company"
                  // excerpt="A little paragraph of text about who and what Companies can take benefits from this      app"
                  buttonText="Continue as Listed Company"
                  action={() =>
                    this.props.history.push("/auth/register/company")
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(RegisterType);
