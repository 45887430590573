import React, { Component } from "react";
import { Alert } from "antd";

class AlertComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { description, type, message } = this.props;
    return (
      <Alert message={message} description={description} type={type} showIcon />
    );
  }
}

export default AlertComponent;
