import actionTypes from "../actions/dealActions";

const initialState = {
  deals: [],
  loading: false,
  singleDeal: {},
  bidStatus: {},
  currentDeals: [],
  publicDeals: [],
  allocations: {},
  allAllocations: [],
  publicClosedDeals: [],
  public180Deals: null
};

const deal = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.FETCH_ALL_DEALS_SUCCESS:
      return {
        ...state,
        deals: payload,
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: payload,
      };

    case actionTypes.FETCH_ALL_PUBLIC_DEALS_SUCCESS:
      return {
        ...state,
        public180Deals: payload,
      };
    case actionTypes.FETCH_ALL_CLOSED_DEALS_SUCCESS:
      return {
        ...state,
        publicClosedDeals: payload,
      };

    case actionTypes.FETCH_ALL_PUBLIC_DEALS_MARKET_SUCCESS:
      return {
        ...state,
        publicMarketDeals: payload,
      };

    case actionTypes.FETCH_ALL_CURRENT_DEALS_SUCCESS:
      return {
        ...state,
        currentDeals: payload,
      };

    case actionTypes.FETCH_SINGLE_DEAL_SUCCESS:
      return {
        ...state,
        singleDeal: payload.data,
      };

    case actionTypes.FETCH_SINGLE_DEAL_STATUS:
      return {
        ...state,
        bidStatus: payload.data,
      };

    case actionTypes.CREATE_DEAL_SUCCESS:
      return {
        ...state,
        deals: state.deals.push(payload),
      };

    case actionTypes.GET_ALLOCATIONS:
      return {
        ...state,
        allocations: payload.data,
      };

    case actionTypes.GET_ALL_ALLOCATIONS:
      return {
        ...state,
        allAllocations: payload.data.data,
      };

    default:
      return state;
  }
};

export default deal;
