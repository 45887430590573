import React, { Component } from "react";
import $ from "jquery";
import "../../../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../../../../node_modules/bootstrap-select/dist/js/bootstrap-select";

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: []
    };
  }

  componentDidMount() {
    const { data } = this.props;
    this.setState({
      ...this.state,
      options: data
    });

    setTimeout(() => {
      $(".select-picker").selectpicker();
    }, 1000);
  }

  componentWillMount() {
    setTimeout(() => {
      $(".select-picker").selectpicker();
    }, 1000);
  }

  handleChange = event => {
    let optedOptions = [];
    for (let item of event.target.options) {
      if (item.selected) {
        optedOptions.push(item.value);
      }
    }
    this.props.handleChange(optedOptions);
  };

  render() {
    const { valueData, labelData, selected, data } = this.props;
    let options = data.map((record, index) =>
      selected.includes(record[valueData]) ? (
        <option selected key={index + "ser"} value={record[valueData]}>
          {record[labelData]}
        </option>
      ) : (
        <option key={index + "ser"} value={record[valueData]}>
          {record[labelData]}
        </option>
      )
    );
    return (
      <select
        className="select-picker"
        multiple
        data-selected-text-format="count > 3"
        title="Select more than one"
        onChange={event => this.handleChange(event)}
      >
        {options}
      </select>
    );
  }
}

export default Dropdown;
