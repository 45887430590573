import React, { Component } from "react";
import Navigation from "../navigation/Navigation";
import { BrandLogo, LiveDealsButton, MenuToggle, Avator } from "../common";
import { showUserMenu } from "../../redux/actions/appActions";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "../../redux/actions/authActions";
import $ from "jquery";
import {
  Checkbox,
  Grid,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
} from "semantic-ui-react";

const UserHeader = ({ currentUser, showUserMenu, toggleMobileMenu }) => {
  const inInvestor = currentUser.role
    ? currentUser.role.some((role) => role.title === "Investor")
    : "";
  return (
    <>
      <div
        className={`md-header-desktop clearfix d-none d-lg-block ${
          currentUser && "page-header-loggedin"
        }`}
        style={{
          paddingLeft: "155px",
          paddingBottom: "10px",
        }}
      >
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <BrandLogo />
          <div
            className="main-nav-desktop"
            style={{
              width: "845px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingRight: "0",
              marginRight: "0",
            }}
          >
            <Navigation handleClick={toggleMobileMenu} />

            <div className="account">
              <LiveDealsButton />
              <Avator
                showUserMenu={showUserMenu}
                name={currentUser.full_name}
                url={currentUser.photo_url || currentUser.logo_url}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMobileMenu: false,
    };
  }

  componentDidMount() {
    // $(".md-header-mobile .toggle-menu").click(function () {
    //   $(this).parents(".md-header-mobile").toggleClass("active");
    //   $("body").toggleClass("navbar-open");
    // });

    // $(document).mouseup(function (e) {
    //   var container = $(".md-header-mobile");
    //   switch (e.which) {
    //     case 1:
    //       if (!container.is(e.target) && container.has(e.target).length === 0) {
    //         if ($("body.navbar-open").length > 0) {
    //           $("body").toggleClass("navbar-open");
    //           $(".md-header-mobile").toggleClass("active");
    //         }
    //       }
    //   }
    // });
    /* jQuery for desktop mobile
    -----------------------------------------------------*/
    $(".md-header-desktop .toggle-search").click(function () {
      $(this).parents(".search").toggleClass("active");
      $(this).parents(".search").find(".form-control").focus();
    });
  }

  toggleMobileMenu = () => {
    this.setState((prevState) => {
      return {
        showMobileMenu: !prevState.showMobileMenu,
      };
    });
  };

  render() {
    let { currentUser, showUserMenu } = this.props;
    let isInvestor = currentUser.role
      ? currentUser.role.some((role) => role.title === "Investor")
      : "";
    return (
      <>
        <header className="page-header clearfix">
          {currentUser.id ? (
            <UserHeader
              currentUser={currentUser}
              showUserMenu={showUserMenu}
              showMobileMenu-={this.state.showMobileMenu}
              toggleMobileMenu={this.toggleMobileMenu}
            />
          ) : (
            <div className="md-header-desktop clearfix d-none d-lg-block">
              <div
                className="container"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  paddingBottom: "4px",
                }}
              >
                <BrandLogo />

                <div className="main-nav-desktop">
                  <Navigation handleClick={this.toggleMobileMenu} />
                  {/*<ToggleSearch />*/}
                  <div className="account">
                    <LiveDealsButton />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="md-header-mobile d-block d-lg-none">
            <div className="top-navtigation">
              <div className="container">
                <div className="ctn-inner">
                  <BrandLogo />
                  <MenuToggle
                    showMobileMenu={this.state.showMobileMenu}
                    handleClick={this.toggleMobileMenu}
                    changeSidebar={this.props.changeSidebar}
                    visible={this.props.visible}
                  />
                </div>
              </div>
            </div>
            {/* <div className="main-nav-mobile">
              <div className="container">
                <ul className="clearfix">
                  <li>
                    <a href="#">For Brokers</a>
                  </li>
                  <li>
                    <a href="#">For Investors</a>
                  </li>
                  <li>
                    <a href="#">How it Works</a>
                  </li>
                  <li>
                    <a href="#">Contact</a>
                  </li>
                  <li>
                    <a href="#">Login</a>
                  </li>
                </ul>
                <div className="smd-search">
                  <form>
                    <div className="field-input">
                      <input type="text" className="form-control" />
                      <button className="btn-search"></button>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </header>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showUserMenu: () => dispatch(showUserMenu),
  logoutUser: () => dispatch(logoutUser),
});

export default connect(null, mapDispatchToProps)(Header);
