import React from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";

const RemoteSubmitButton = ({ dispatch }) => (
  <div className="row mb-4">
    <div className="button-group">
      <div className="col-sm-6">
        <button
          type="button"
          className="btn btn-primary btn-medium"
          onClick={() => dispatch(submit("InvestorProfileForm"))}
        >
          Save Settings
        </button>
      </div>
    </div>
  </div>
);

export default connect()(RemoteSubmitButton);
