import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class PublicRoute extends Component {
  render() {
    let { component: Component, currentUser, ...rest } = this.props;
    console.log('sdfsdf')
    return (
      <Route
        {...rest}
        render={(props) =>
          !currentUser.id ? (
            <Component {...props} />
          ) : (
            <Redirect to={"/user/deals"} />
          )
        }
      />
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
});

export default connect(mapStateToProps, null)(PublicRoute);
