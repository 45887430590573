import React, { Component, Fragment } from "react";
import ContactImage from "../../../../../assets/images/contact-page.png";
import { Icon } from "semantic-ui-react";
import { Field, Form, reduxForm } from "redux-form";
import { FormField } from "../../../../layout/FormField";
import { required, email } from "redux-form-validators";
import ButtonLoader from "../../../../common/ButtonLoader";
import ContactFooterImg from "../../../../../assets/images/footer_icon.png";
import { Link } from "react-router-dom";
class ContactForm extends Component {
  render() {
    let { handleSubmit } = this.props;
    return (
      <Fragment>
        <main className="page-main home-page contact_bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="register-account">
                  <div className="md-tabs tab-light">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="broker"
                        role="tabpanel"
                      >
                        <div className="form-normal form-register">
                          <Form
                            onSubmit={handleSubmit}
                            className="contact__form"
                          >
                            <h3 class="get_in_tuch">Get In Touch</h3>

                            <div className="register-form-content">
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="field contact__input">
                                    <div className="field-input ">
                                      <Field
                                        className="form-control"
                                        placeholder="First Name"
                                        type="text"
                                        name="first_name"
                                        component={FormField}
                                        validate={[required()]}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="field contact__input">
                                    <div className="field-input ">
                                      <Field
                                        className="form-control"
                                        type="text"
                                        placeholder="Last Name"
                                        name="last_name"
                                        component={FormField}
                                        validate={[required()]}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="field contact__input ">
                                <div className="field-input ">
                                  <Field
                                    className="form-control"
                                    placeholder="Email"
                                    type="text"
                                    name="email"
                                    component={FormField}
                                    validate={([required()], email())}
                                  />
                                </div>
                              </div>
                              <div className="field contact__input contact__margin">
                                <div className="field-input ">
                                  <Field
                                    className="form-control"
                                    placeholder="Subject"
                                    type="text"
                                    name="subject"
                                    component={FormField}
                                    validate={[required()]}
                                  />
                                </div>
                              </div>
                              <div className="field contact__textArea contact__margin">
                                <div className="field-input ">
                                  <Field
                                    className="form-control"
                                    aria-placeholder="Message"
                                    placeholder="Message"
                                    defaultValue="Message"
                                    type="textarea"
                                    name="message"
                                    multiple={true}
                                    rows="5"
                                    component={FormField}
                                    validate={[required()]}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  ></Field>
                                </div>
                              </div>
                            </div>
                            <div className="row contact__submitButton">
                              <div className="button-group hl-button-group">
                                <div className="col-sm-12">
                                  <ButtonLoader className="btn btn-primary btn-medium">
                                    SEND
                                  </ButtonLoader>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 p-4 text-right">
                <div className="contact_div_txt">
                  1300 373 522 &nbsp; &nbsp;
                  <Icon name="phone" />
                </div>
                <div className="contact_div_txt">
                  <a href="mailto:info@180realestate.com.au">
                    info@180realestate.com.au
                  </a>{" "}
                  &nbsp; &nbsp;{" "}
                  <Icon name="mail" className="contact__phoneIcon" />{" "}
                </div>
                <div className="contact_div">426A Glenhuntly Road</div>
                <div className="contact_div">Elsternwick VIC,</div>
                <div className="contact_div">3185</div>
                <div className="contact_div">Australia</div>
              </div>
            </div>
          </div>
        </main>
        <div className="main_bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mt_7em">
                <h3 className="top_footer_heading_contact">
                  Join Our Community
                </h3>
                <p className="top_footer_p_contact">
                  Join the discussion of the latest news in the world finance
                  industry. Meet like-minded enthusiasts here.
                </p>
                <Link to="/auth/register/investor">
                  <button
                    type="button"
                    className="btn btn-primary about_ftr_btn rounded-0"
                  >
                    JOIN NOW
                  </button>
                </Link>
              </div>
              <div className="col-lg-4">
                <img
                  src={ContactFooterImg}
                  className="img-fluid"
                  style={{
                    opacity: "0.5",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default reduxForm({ form: "ContactForm" })(ContactForm);
