import React from "react";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../../../services/settings";
import { Icon } from "semantic-ui-react";

const InterviewsTable = ({ interviews, onView, history }) => {
  const handleChange = (row) => {
    history.push(`/user/interview-view/${row.uuid}`);
  };

  const columns = [
    {
      name: "",
      selector: "deal_logo",
      sortable: false,
      cell: (row) => (
        <>
          <div className="company-logo" onClick={() => handleChange(row)}>
            {row.logo && (
              <img
                style={{
                  maxWidth: "70px",
                  maxHeight: "70px",
                  float: "left",
                }}
                src={`${BaseUrl}/storage/${row.logo.id}/${row.logo.file_name}`}
                alt=""
              />
            )}
          </div>
        </>
      ),
    },
    {
      name: "Company Name",
      selector: "company-name",
      sortable: false,
      left: true,
      cell: (row) => (
        <div className="company-name" onClick={() => handleChange(row)}>
          {row.company_name && row.company_name}
        </div>
      ),
    },
    {
      name: "Date",
      selector: "company-name",
      sortable: false,
      left: true,
      cell: (row) => (
        <div className="company-name" onClick={() => handleChange(row)}>
          {row.date && `${row.date}`}
        </div>
      ),
    },
    {
      name: "Description",
      selector: "company-name",
      sortable: false,
      left: true,
      cell: (row) => (
        <div className="company-name" onClick={() => handleChange(row)}>
          {row.description && (
            <div
              dangerouslySetInnerHTML={{
                __html: row.description,
              }}
            />
          )}
        </div>
      ),
    },
    {
      name: "View",
      selector: "bid_now",
      sortable: false,
      left: true,
      cell: (row) => (
        <div className="tbl-title" onClick={() => onView(row.uuid)}>
          <span className="bid-now">
            <Icon
              style={{ cursor: "pointer" }}
              color="blue"
              size="huge"
              name="arrow alternate circle right outline"
            />
          </span>
        </div>
      ),
    },
  ];

  return (
    <div className="md-table-normal table-responsive">
      <DataTable
        noHeader
        highlightOnHover
        pointerOnHover
        columns={columns}
        data={interviews}
        onRowClicked={handleChange}
      />
    </div>
  );
};

export default InterviewsTable;
