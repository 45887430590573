import React, { Component } from "react";
import { reduxForm, Field, Form } from "redux-form";
import { required, email } from "redux-form-validators";

import ButtonLoader from "../../../common/ButtonLoader";
import { FormField } from "../../../layout/FormField";

class SuggestionsForm extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <>
        <div class="form-normal form-register">
          <Form onSubmit={handleSubmit}>
            <div class="register-form-content">
              <div className="all-fields">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Company</label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="company"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">ASX Code</label>
                      <div class="field-input">
                        <Field
                          className="form-control"
                          name="asx_code"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="field">
                      <label className="title">
                        Please indicate your reason for suggesting a raise in
                        this company
                      </label>
                      <div class="field-input">
                        <Field
                          className="form-control"
                          name="raise_suggestion"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Name</label>
                      <div class="field-input">
                        <Field
                          className="form-control"
                          name="name"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Email</label>
                      <div class="field-input">
                        <Field
                          className="form-control"
                          type="text"
                          name="email"
                          component={FormField}
                          validate={[required(), email()]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Contact Number</label>
                      <div class="field-input">
                        <Field
                          className="form-control"
                          name="contact_number"
                          component={FormField}
                          type="number"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="button-group hl-button-group">
                  <div className="col-sm-12">
                    <ButtonLoader
                      type="submit"
                      className="btn btn-primary btn-medium"
                    >
                      Submit
                    </ButtonLoader>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </>
    );
  }
}

export default reduxForm({ form: "SuggestionsForm" })(SuggestionsForm);
