import SuperFetch from "./superfetch";
import axios from "axios";
import { BaseUrl, configMultipart, config } from "./settings";
import moment from "moment";

class EntityService {
  getAll = async (formInputs) => {
    return await SuperFetch.get("entity", formInputs).then((response) => {
      return response;
    });
  };

  getSingle = async (id) => {
    return await SuperFetch.get("entity/" + id, {}).then((response) => {
      return response;
    });
  };

  createEntity = async (payload) => {
    let bodyFormData = new FormData();

    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        bodyFormData.append(key, payload[key]);
      }
    }
    return axios
      .post(`${BaseUrl}realestate/v1/entity`, bodyFormData, configMultipart())
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  };

  createCompanyEntity = async (payload) => {
    let bodyFormData = new FormData();

    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        if (key === "directors") {
          for (let k in payload["directors"]) {
            for (let p in payload["directors"][k]) {
              bodyFormData.append(
                `directors[${k}][${p}]`,
                payload["directors"][k][p]
              );
            }
          }
        } else if (key.includes("directors") && key.includes("verification")) {
          payload[key].forEach((p, index) => {
            bodyFormData.append(`${key}[${index}]`, p);
          });
        } else if (key === "investorForms") {
          Object.values(payload[key]).forEach((file, index) => {
            bodyFormData.append(
              "sophisticated_investor_forms[" + index + "]",
              file
            );
          });
        } else {
          bodyFormData.append(key, payload[key]);
        }
      }
    }
    return axios
      .post(
        `${BaseUrl}realestate/v1/entity/company`,
        bodyFormData,
        configMultipart()
      )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  };

  createTrustEntity = async (payload) => {
    console.log(payload);
    let bodyFormData = new FormData();
    delete payload["beneficial_company"];
    delete payload["beneficial_personal"];
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        if (key === "beneficial") {
          for (let k in payload["beneficial"]) {
            for (let p in payload["beneficial"][k]) {
              for (let r in payload["beneficial"][k][p]) {
                if (r === "directors") {
                  for (let q in payload["beneficial"][k][p]["directors"]) {
                    for (let t in payload["beneficial"][k][p][r][q]) {
                      if (
                        payload["beneficial"][k][p][r][q][t] &&
                        t !== "verification"
                      ) {
                        bodyFormData.append(
                          `beneficial[${k}][${p}][${r}][${q}][${t}]`,
                          payload["beneficial"][k][p][r][q][t]
                        );
                      }
                    }
                  }
                } else {
                  bodyFormData.append(
                    `beneficial[${k}][${p}][${r}]`,
                    payload["beneficial"][k][p][r]
                  );
                }
              }
            }
          }
        }

        if (key === "beneficial") {
          bodyFormData.delete("beneficial");
        } else if (key === "trustDeedFiles") {
          Object.values(payload[key]).forEach((file, index) => {
            bodyFormData.append("trust_deeds[" + index + "]", file);
          });
        } else if (key === "investorForms") {
          Object.values(payload[key]).forEach((file, index) => {
            bodyFormData.append(
              "sophisticated_investor_forms[" + index + "]",
              file
            );
          });
        } else if (key === "settlor_dob") {
          if (payload[key]) {
            bodyFormData.append(key, moment(payload[key]).format("DD/MM/YYYY"));
          }
        } else if (key.includes("directors") && key.includes("verification")) {
          payload[key].forEach((p, index) => {
            bodyFormData.append(`${key}[${index}]`, p);
          });
        } else if (
          key.includes("beneficial[individuals]") &&
          key.includes("verification")
        ) {
          payload[key].forEach((p, index) => {
            bodyFormData.append(`${key}[${index}]`, p);
          });
        } else {
          bodyFormData.append(key, payload[key]);
        }
      }
    }
    return axios
      .post(
        `${BaseUrl}realestate/v1/entity/trust`,
        bodyFormData,
        configMultipart()
      )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  };

  createIndividualEntity = async (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        if (key === "individuals") {
          Object.values(payload[key]).forEach((individual, index) => {
            if (individual.id) {
              bodyFormData.append(
                "individuals[" + index + "][id]",
                individual.id
              );
            }
            if (individual.address) {
              bodyFormData.append(
                "individuals[" + index + "][address]",
                individual.address
              );
            }

            if (individual.suburb) {
              bodyFormData.append(
                "individuals[" + index + "][suburb]",
                individual.suburb
              );
            }

            if (individual.postcode) {
              bodyFormData.append(
                "individuals[" + index + "][postcode]",
                individual.postcode
              );
            }

            if (individual.state) {
              bodyFormData.append(
                "individuals[" + index + "][state]",
                individual.state
              );
            }

            if (individual.country) {
              bodyFormData.append(
                "individuals[" + index + "][country]",
                individual.country
              );
            }
            if (individual.title) {
              bodyFormData.append(
                "individuals[" + index + "][title]",
                individual.title
              );
            }
            if (individual.given_name) {
              bodyFormData.append(
                "individuals[" + index + "][given_name]",
                individual.given_name
              );
            }
            if (individual.sur_name) {
              bodyFormData.append(
                "individuals[" + index + "][sur_name]",
                individual.sur_name
              );
            }
            if (individual.country_of_citizenship) {
              bodyFormData.append(
                "individuals[" + index + "][country_of_citizenship]",
                individual.country_of_citizenship
              );
            }
            if (individual.tfn) {
              bodyFormData.append(
                "individuals[" + index + "][tfn]",
                individual.tfn
              );
            }
            if (individual.country_of_birth) {
              bodyFormData.append(
                "individuals[" + index + "][country_of_birth]",
                individual.country_of_birth
              );
            }
            // if (individual.dob) {
            //   bodyFormData.append(
            //     "individuals[" + index + "][dob]",
            //     moment(individual.dob).format("DD/MM/YYYY")
            //   );
            // }
            if (individual.status_documents) {
              individual.status_documents.map((document, documentIndex) => {
                bodyFormData.append(
                  "individuals[" +
                    index +
                    "][wholesale_status_document][" +
                    documentIndex +
                    "]",
                  document
                );
              });
            }
          });
        } else if (
          key.includes("individuals") &&
          key.includes("verification")
        ) {
          payload[key].forEach((p, index) => {
            bodyFormData.append(`${key}[${index}]`, p);
          });
        } else {
          bodyFormData.append(key, payload[key]);
        }
      }
    }

    return axios
      .post(
        `${BaseUrl}realestate/v1/entity/individual`,
        bodyFormData,
        configMultipart()
      )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  };

  updateCompanyEntity = async (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        if (key === "directors") {
          for (let k in payload[key]) {
            for (let m in payload[key][k]) {
              if (payload[key][k][m] && m != 'verification') {
                bodyFormData.append(
                  `directors[${k}][${m}]`,
                  payload[key][k][m]
                );
              }
            }
          }
        } else if (key === "investorForms") {
          Object.values(payload[key]).forEach((file, index) => {
            bodyFormData.append(
              "sophisticated_investor_forms[" + index + "]",
              file
            );
          });
        } else if (key.includes("directors") && key.includes("verification")) {
          
          payload[key].forEach((p, index) => {
            bodyFormData.append(`${key}[${index}]`, p);
          });
        } else {
          bodyFormData.append(key, payload[key]);
        }
      }
    }

    let config = {
      headers: {
        Accept: "application/json",
        Authorization:
          "Bearer " + localStorage.getItem("auth_token") || undefined,
      },
    };
    return axios
      .post(
        `${BaseUrl}realestate/v1/entity/company/${payload.id}`,
        bodyFormData,
        config
      )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  };

  updateTrustEntity = async (payload) => {
    let bodyFormData = new FormData();
    delete payload["beneficial_company"];
    delete payload["beneficial_personal"];
    delete payload["beneficial_owners"];
    // delete payload["directors"];

    for (var key in payload) {
      if (
        (payload[key] && payload[key] !== undefined) ||
        payload[key] !== null
      ) {
        if (key === "beneficial") {
          for (let k in payload["beneficial"]) {
            for (let p in payload["beneficial"][k]) {
              for (let r in payload["beneficial"][k][p]) {
                if (r === "directors") {
                  for (let q in payload["beneficial"][k][p]["directors"]) {
                    for (let t in payload["beneficial"][k][p][r][q]) {
                      if (
                        payload["beneficial"][k][p][r][q][t] &&
                        t !== "verification"
                      ) {
                        bodyFormData.append(
                          `beneficial[${k}][${p}][${r}][${q}][${t}]`,
                          payload["beneficial"][k][p][r][q][t]
                        );
                      }
                    }
                  }
                } else {
                  bodyFormData.append(
                    `beneficial[${k}][${p}][${r}]`,
                    payload["beneficial"][k][p][r]
                  );
                }
              }
            }
          }
        }

        if (key === "directors") {
          bodyFormData.delete("directors");
        } else if (key === "beneficial") {
          bodyFormData.delete("beneficial");
        } else if (key === "trustDeedFiles") {
          Object.values(payload[key]).forEach((file, index) => {
            bodyFormData.append("trust_deeds[" + index + "]", file);
          });
        } else if (key === "investorForms") {
          Object.values(payload[key]).forEach((file, index) => {
            bodyFormData.append(
              "sophisticated_investor_forms[" + index + "]",
              file
            );
          });
        } else if (key === "settlor_dob") {
          if (payload[key]) {
            bodyFormData.append(key, moment(payload[key]).format("DD/MM/YYYY"));
          }
        } else if (key.includes("directors") && key.includes("verification")) {
          payload[key].forEach((p, index) => {
            bodyFormData.append(`${key}[${index}]`, p);
          });
        } else if (
          key.includes("beneficial[individuals]") &&
          key.includes("verification")
        ) {
          payload[key].forEach((p, index) => {
            bodyFormData.append(`${key}[${index}]`, p);
          });
        } else {
          bodyFormData.append(key, payload[key]);
        }
      }
    }

    let config = {
      headers: {
        Accept: "application/json",
        Authorization:
          "Bearer " + localStorage.getItem("auth_token") || undefined,
      },
    };

    return axios
      .post(
        `${BaseUrl}realestate/v1/entity/trust/${payload.id}`,
        bodyFormData,
        config
      )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  };

  updateIndividualEntity = async (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        if (key === "individuals") {
          Object.values(payload[key]).forEach((individual, index) => {
            if (individual.id) {
              bodyFormData.append(
                "individuals[" + index + "][id]",
                individual.id
              );
            }
            if (individual.address) {
              bodyFormData.append(
                "individuals[" + index + "][address]",
                individual.address
              );
            }

            if (individual.suburb) {
              bodyFormData.append(
                "individuals[" + index + "][suburb]",
                individual.suburb
              );
            }

            if (individual.postcode) {
              bodyFormData.append(
                "individuals[" + index + "][postcode]",
                individual.postcode
              );
            }

            if (individual.state) {
              bodyFormData.append(
                "individuals[" + index + "][state]",
                individual.state
              );
            }

            if (individual.country) {
              bodyFormData.append(
                "individuals[" + index + "][country]",
                individual.country
              );
            }
            if (individual.title) {
              bodyFormData.append(
                "individuals[" + index + "][title]",
                individual.title
              );
            }
            if (individual.given_name) {
              bodyFormData.append(
                "individuals[" + index + "][given_name]",
                individual.given_name
              );
            }
            if (individual.sur_name) {
              bodyFormData.append(
                "individuals[" + index + "][sur_name]",
                individual.sur_name
              );
            }
            if (individual.country_of_citizenship) {
              bodyFormData.append(
                "individuals[" + index + "][country_of_citizenship]",
                individual.country_of_citizenship
              );
            }
            if (individual.tfn) {
              bodyFormData.append(
                "individuals[" + index + "][tfn]",
                individual.tfn
              );
            }
            if (individual.country_of_birth) {
              bodyFormData.append(
                "individuals[" + index + "][country_of_birth]",
                individual.country_of_birth
              );
            }
            // if (individual.dob) {
            //   bodyFormData.append(
            //     "individuals[" + index + "][dob]",
            //     moment(individual.dob).format("DD/MM/YYYY")
            //   );
            // }
            if (individual.status_documents) {
              individual.status_documents.map((document, documentIndex) => {
                bodyFormData.append(
                  "individuals[" +
                    index +
                    "][wholesale_status_document][" +
                    documentIndex +
                    "]",
                  document
                );
              });
            }
          });
        } else if (
          key.includes("individuals") &&
          key.includes("verification")
        ) {
          payload[key].forEach((p, index) => {
            bodyFormData.append(`${key}[${index}]`, p);
          });
        } else {
          bodyFormData.append(key, payload[key]);
        }
      }
    }

    let config = {
      headers: {
        Accept: "application/json",
        Authorization:
          "Bearer " + localStorage.getItem("auth_token") || undefined,
      },
    };

    return axios
      .post(
        `${BaseUrl}realestate/v1/entity/individual/${payload.id}`,
        bodyFormData,
        config
      )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  };

  deleteEntity = async (formInputs) => {
    let config = {
      headers: {
        Accept: "application/json",
        Authorization:
          "Bearer " + localStorage.getItem("auth_token") || undefined,
      },
    };

    return axios
      .delete(
        `${BaseUrl}realestate/v1/entity/${formInputs.id}`,
        config,
        formInputs
      )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
    // return await SuperFetch.delete("entity/" + formInputs.id, formInputs).then(
    //   response => {
    //     return response;
    //   }
    // );
  };

  deleteIndividualEntity = async (index) => {
    let config = {
      headers: {
        Accept: "application/json",
        Authorization:
          "Bearer " + localStorage.getItem("auth_token") || undefined,
      },
    };

    return axios
      .delete(`${BaseUrl}realestate/v1/entity/individual/${index}`, config)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
    // return await SuperFetch.delete("entity/" + formInputs.id, formInputs).then(
    //   response => {
    //     return response;
    //   }
    // );
  };
}
export default new EntityService();
