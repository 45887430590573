import { HIDE_USER_MENU, SHOW_USER_MENU } from "./constants";

const showUserMenu = {
  type: SHOW_USER_MENU
};

const hideUserMenu = {
  type: HIDE_USER_MENU
};

export { hideUserMenu, showUserMenu };
