import React, { Component, Fragment } from "react";
import About from "../../../assets/images/about2.jpg";
import FacebookIcon from "../../../assets/images/ico-facebook.png";
import TwitterIcon from "../../../assets/images/ico-twitter.png";
import FacebookIcon1 from "../../../assets/images/ico-facebook-01.png";
import LinkedInIcon from "../../../assets/images/linkedin-logo.png";
import TwitterIcon1 from "../../../assets/images/ico-twitter-01.png";
import GregLowe from "../../../assets/images/BPS_0482.jpg";
import SimonSzwarc from "../../../assets/images/BPS_0452.jpg";
import JordanKadish from "../../../assets/images/J.jpg";
import ShaunFactor from "../../../assets/images/BPS_0473.jpg";
import Jake from "../../../assets/images/jake.png";
import Tommy from "../../../assets/images/tommy.jpg";

import NewsletterContainer from "../../forms/Newsletter/NewsletterContainer";

import DirectImg from "../../../assets/images/building.jpg";
import BrokerImg from "../../../assets/images/BrokerDeals.png";
import EaseImg from "../../../assets/images/EaseofOneAccount.png";
import FairerImg from "../../../assets/images/FairerAllocations.png";
import AlertsImg from "../../../assets/images/AlertstoCapitalRaises.png";
import IndepthImg from "../../../assets/images/IndepthAnalysis.png";
import ChooseImg from "../../../assets/images/ChooseYourOwnDealers.png";
import CompanyImg from "../../../assets/images/CompanyDirecctDeals.png";
import IconImg from "../../../assets/images/icon.png";
import ContactFooterImg from "../../../assets/images/footer_icon.png";
import NewsletterImg from "../../../assets/images/Group7903.png";

import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
export default class WhoWeAre extends Component {
  render() {
    return (
      <>
        <main>
          <div className="about_us_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 p-5">
                  <h3>
                    180 Real Estate is changing the funding landscape of Australian property for the better; pairing property professionals and fund managers
                    with sophisticated investors on one easy to use platform. The 180 team brings nearly 50 years of professional investment experience and
                    knowledge to our clients, facilitating timely and tailored Capital Raisings and seamless investor opportunities.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 px-5">
                <div className="row">
                  <div className="col-lg-12 pt-2 pb-2">
                    <a href="#">
                      <img style={{width:'100%'}} src={DirectImg} />
                      {/*<p className="sml_txt">Wider distribution Network</p>*/}
                    </a>
                  </div>
                {/*  <div className="col-lg-4 col-6 mt-3">*/}
                {/*    <a href="#">*/}
                {/*      <img src={BrokerImg} />*/}
                {/*      <p className="sml_txt">Investor Access to hundreds (or more) of property investments formerly unknown</p>*/}
                {/*    </a>*/}
                {/*  </div>*/}
                {/*  <div className="col-lg-4 col-6 mt-3">*/}
                {/*    <a href="#">*/}
                {/*      <img src={EaseImg} />*/}
                {/*      <p className="sml_txt">Multiple Opportunities all on one platform</p>*/}
                {/*    </a>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div className="row mt-lg-5">*/}
                {/*  <div className="col-lg-4 col-6 mt-3">*/}
                {/*    <a href="#">*/}
                {/*      <img src={FairerImg} />*/}
                {/*      <p className="sml_txt">Potential opportunity to participate “hidden” opportunities</p>*/}
                {/*    </a>*/}
                {/*  </div>*/}
                {/*  <div className="col-lg-4 col-6 mt-3">*/}
                {/*    <a href="#">*/}
                {/*      <img src={IndepthImg} />*/}
                {/*      <p className="sml_txt">Ability to sort and compare multiple investments on one clean platform</p>*/}
                {/*    </a>*/}
                {/*  </div>*/}
                {/*  <div className="col-lg-4 col-6 mt-3">*/}
                {/*    <a href="#">*/}
                {/*      <img src={ChooseImg} />*/}
                {/*      <p className="sml_txt">Easiness of uploading documentation only one time with approval process </p>*/}
                {/*    </a>*/}
                {/*  </div>*/}
                  {/*<div className="col-lg-3 col-6 mt-3">*/}
                  {/*  <a href="#">*/}
                  {/*    <img src={AlertsImg} />*/}
                  {/*    <p className="sml_txt">Choose Your Own Dealers</p>*/}
                  {/*  </a>*/}
                  {/*</div>*/}
                  {/*<div className="col-lg-3 col-6 mt-3">*/}
                  {/*  <a href="#">*/}
                  {/*    <img src={CompanyImg} />*/}
                  {/*    <p className="sml_txt">Company Direcct Deals</p>*/}
                  {/*  </a>*/}
                  {/*</div>*/}
                </div>
              </div>
              {/*<div className="col-lg-6 mt-5">*/}
              {/*  <h3 className="about_heading">Our Goal</h3>*/}
              {/*  <p className="about_p">*/}
              {/*    Our Goal text required...*/}
              {/*  </p>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="body_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mt-4">
                  <img src={GregLowe} className="img_about" />
                  <div className="name_about">
                    Greg Lowe{" "}
                    <a
                      href="https://www.linkedin.com/in/greg-lowe-b301b683/"
                      target="_blank"
                    >
                      <span className="linkedin__iconOuter">
                        <Icon name="linkedin" className="linkedin__icon"></Icon>
                      </span>
                    </a>
                  </div>
                  <a
                    href="https://www.linkedin.com/in/greg-lowe-b301b683/"
                    target="_blank"
                    className="a_design"
                  >
                    CEO and Co-Founder
                  </a>

                  <p className="about_p">
                    Greg has spent over 20 years as a professional hedge fund
                    investor most notably as a partner at $3bn fund P Schoenfeld
                    Asset Management both in the U.S. and Australia.
                    <br /> <br /> Greg has actively invested across wide range
                    of products including listed equities, unlisted equities,
                    bonds, banks debt, distressed debt, foreclosure loan notes,
                    physical real estate, and various property deal structures.{" "}
                    <br /> <br /> In particular, Greg created a Special Purpose
                    Vehicle to purchase Australian distressed bank debt in 2009
                    and created a single family home portfolio in Los Angeles.
                  </p>
                </div>
                <div className="col-lg-6 mt-4">
                  <img src={ShaunFactor} className="img_about" />
                  <div className="name_about">
                    Shaun Factor{" "}
                    <a
                      href="https://www.linkedin.com/in/shaun-factor-b737084b/"
                      target="_blank"
                    >
                      <span className="linkedin__iconOuter">
                        <Icon name="linkedin" className="linkedin__icon"></Icon>
                      </span>
                    </a>
                  </div>
                  <a
                    href="https://www.linkedin.com/in/shaun-factor-b737084b/"
                    target="_blank"
                    className="a_design"
                  >
                    Co-Founder
                  </a>
                  <p className="about_p">
                    Shaun Co-founded 180 Markets with a mission of equalising access to investing in all Asset Classes.<br /> <br />
                    Shaun invests full time in ASX listed stocks but also has a passion for many other asset classes.
                    One of those is Property. Having invested in many different structures in Australia and New York,
                    Property is an area where Shaun is positive the 180 Markets philosophy will facilitate thousands
                    of investors to gain access to many deals across the globe.
                  </p>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-lg-6 mt-4">
                  <img src={SimonSzwarc} className="img_about" />
                  <div className="name_about">
                    Simon Szwarc{" "}
                    <a
                      href="https://www.linkedin.com/in/simon-szwarc-103a27b/"
                      target="_blank"
                    >
                      <span className="linkedin__iconOuter">
                        <Icon name="linkedin" className="linkedin__icon"></Icon>
                      </span>
                    </a>
                  </div>
                  <a
                    href="https://www.linkedin.com/in/simon-szwarc-103a27b/"
                    target="_blank"
                    className="a_design"
                  >
                    Advisor
                  </a>

                  <p className="about_p">
                    Simon is the CEO & Founder of Guava Insights, an end-to-end
                    innovation and technology business delivering
                    industry-changing solutions to Australia's largest
                    organizations. <br /> <br />
                    Simon is known as one of Australia's leading evangelists on
                    innovative change, having delivered keynotes, workshops and
                    collaboration programs to leadership teams across a range of
                    industries. He has over 20 years’ experience working in the
                    identification, adoption and integration of new and
                    leading-edge technologies for Australia's largest
                    organizations.
                  </p>
                </div>

              </div>

              {/*<div className="row mt-5">*/}
              {/*  <div className="col-lg-6 mt-4">*/}
              {/*    <img src={Jake} className="img_about" />*/}
              {/*    <div className="name_about">*/}
              {/*      Jake Givoni{" "}*/}
              {/*      <a*/}
              {/*          href="https://www.linkedin.com/in/jake-givoni-b5804a1b3/"*/}
              {/*          target="_blank"*/}
              {/*      >*/}
              {/*        <span className="linkedin__iconOuter">*/}
              {/*          <Icon name="linkedin" className="linkedin__icon"></Icon>*/}
              {/*        </span>*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*    <a*/}
              {/*        href="https://www.linkedin.com/in/simon-szwarc-103a27b/"*/}
              {/*        target="_blank"*/}
              {/*        className="a_design"*/}
              {/*    >*/}
              {/*      Analyst*/}
              {/*    </a>*/}

              {/*    <p className="about_p">*/}
              {/*      Jake joined the 180 markets teams in October 2020. He is responsible for undertaking research tasks and investor relations. <br/><br/>*/}

              {/*      Jake has a passion for investing. His experiences at university and fascination for data analysis has provoked his key interest in the equity markets.<br/><br/>*/}

              {/*      Jake’s prior experience in working in sales and marketing enables him to build strong rapport with investors and companies. Outside of 180 markets, Jake has a key interest in sport, keeping active and travel.*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </main>


        <div className="side_img"></div>


        <div class="top_bg_body">
          <img class="img_fttr" src={NewsletterImg} />
        </div>


        <div className=" main_bg_about">
          <h3 className="about_heading text-center">Sign up for updates</h3>
          <p className="text-center about__singUpPara p-2">
            We’ll keep you up to date with our latest news, announcements, and
            development plans. No-spam policy!
          </p>
          <div className="container">
            <div className="row">
              <div className="col-lg-7 offset-lg-2 col-8 offset-1">
                <div className="form-group mt-4">
                  <input
                    type="email"
                    className="form-control about__email"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter your email address"
                  />
                </div>
              </div>
              <div className="col-lg-2 col-3">
                <img src={IconImg} className="img-fluid btn_image" />
              </div>
            </div>
          </div>
        </div>

        {/*<div className="top_footer_about">*/}
        {/*  <div className="container">*/}
        {/*    <div className="row about__footer">*/}
        {/*      <div className="col-lg-8 mt_7em">*/}
        {/*        <h3 className="top_footer_heading_about">Join Our Community</h3>*/}
        {/*        <p className="top_footer_p_about">*/}
        {/*          Join the discussion of the latest news in the world finance*/}
        {/*          industry. Meet like-minded enthusiasts here.*/}
        {/*        </p>*/}
        {/*        <Link to="/auth/register">*/}
        {/*          <button*/}
        {/*            type="button"*/}
        {/*            className="btn btn-primary about_ftr_btn rounded-0"*/}
        {/*          >*/}
        {/*            JOIN NOW*/}
        {/*          </button>*/}
        {/*        </Link>*/}
        {/*      </div>*/}
        {/*      <div className="col-lg-4">*/}
        {/*        <img src={ContactFooterImg} className="img-fluid" />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}


      </>
    );
  }
}
