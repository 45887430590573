import React from "react";

const Backdrop = {
  width: "100%",
  height: "100%",
  position: "fixed",
  zIndex: "100",
  left: "0",
  top: "0",
  right: "0",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
};

const backdrop = (props) => {
  return props.show ? (
    <div style={Backdrop} onClick={props.clicked}></div>
  ) : null;
};

export default backdrop;
