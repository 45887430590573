import axios from "axios";
import { BaseUrl, configMultipart } from "./settings";
import moment from "moment";

class NewsLetterService {
  subscribe = async (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      bodyFormData.append(key, payload[key]);
    }

    return await axios
      .post(BaseUrl + `realestate/v1/subscribe`, bodyFormData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  getAll = async (payload) => {
    return await axios
      .get(BaseUrl + `realestate/v1/newsletters`, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export default new NewsLetterService();
