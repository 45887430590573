import React, { useEffect } from "react";
import { FormField } from "../../../../layout/FormField";
import { required } from "redux-form-validators";
import DateDropdown from "../../../../common/DateDropdown";
import { Field } from "redux-form";
import DraggerComponent from "../../../../common/DraggerComponent/DraggerComponent";
import NewDropzone from "../../../../common/NewDropzone";

export const RenderDirectors = ({
  fields,
  prevProps,
  prefix,
  number_field_name,
  prevDirectors,
  fileList,
  fileName,
}) => {
  const handleArray = (value) => {
    let quantity = prevDirectors;

    if (fields.length < value) {
      quantity = value - fields.length;
      for (let i = 1; i <= quantity; i++) {
        fields.push({});
      }
    } else if (value && fields.length > value) {
      quantity = fields.length - value;
      for (let i = 1; i <= quantity; i++) {
        fields.remove({});
      }
    } else if (fields.length === value) {
      return;
    } else if (!value) {
      return fields.removeAll();
    }
  };

  useEffect(() => {
    if (prevDirectors) {
      let length;
      if (typeof prevDirectors !== "number") {
        length = prevDirectors.length;
      } else {
        length = prevDirectors;
      }
      if (!prevDirectors) {
        return handleArray;
      }
      handleArray(length);
    }
  }, []);

  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-6">
          <div className="field">
            <label className="title">Number of Directors *</label>
            <div class="field-input">
              <Field
                className="form-control entity__input"
                name={number_field_name}
                component={FormField}
                type="number"
                min="1"
                onChange={(e) => handleArray(e.target.value)}
                validate={[required()]}
              />
            </div>
          </div>
        </div>
      </div>

      {fields.map((member, index) => (
        <div className="row mb-3" key={index}>
          <div className="col-md-12">
            <h3>Director {index + 1}</h3>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Given Name(s) *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`${prefix}[${index}][given_name]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Surname *</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`${prefix}[${index}][surname]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-12">
                <div className="field">
                  <label className="title">Date of Birth *</label>
                  <div class="field-input">
                    <div className="form-group">
                      <Field
                        selectedDate={prevProps.handleDirectorsDob}
                        label="Date of Birth"
                        id="dob"
                        name={`${prefix}[${index}][dob]`}
                        component={DateDropdown}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Country of Birth *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`${prefix}[${index}][country_of_birth]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">
                    Country of Citizenship or lawful permanent residence *
                  </label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`${prefix}[${index}][country_of_citizenship]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-12">
                <div className="field">
                  <label className="title">ID Verification documents *</label>
                  <div className="field">
                    Acceptable forms of ID include: Australian passport,
                    Australian Drivers License, Birth certificate
                  </div>
                  <div class="field-input">
                    {/* <DraggerComponent
                      fileList={
                        fileList &&
                        fileList[index] &&
                        fileList[index]["verification"]
                      }
                      onDocumentDeleteHandler={
                        prevProps.onVerificationDeleteHandler
                      }
                      name={`${prefix}[${index}][verification]`}
                      handleIdUpload={prevProps.handleIdUpload}
                    /> */}
                    <NewDropzone
                      multiple={true}
                      buttonLabel="Choose document"
                      title={""}
                      multiDocuments={
                        fileList &&
                        fileList[index] &&
                        fileList[index]["verification"]
                      }
                      onUpload={(files) =>
                        prevProps.handleIdUpload({
                          [`${fileName}[${index}][verification]`]: files,
                        })
                      }
                      onDocumentDeleteHandler={
                        prevProps.onVerificationDeleteHandler
                      }
                      open={prevProps.open}
                      close={prevProps.close}
                      show={prevProps.show}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
