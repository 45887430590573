import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { hideUserMenu } from "../../redux/actions/appActions";
import { logoutUser, uploadAvatar } from "../../redux/actions/authActions";
import { Icon } from "antd";
import Backdrop from "../common/Backdrop/Backdrop";

class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.state = {
      isUpgraded: "",
    };
  }

  isInvestor = this.props.currentUser.role
    ? this.props.currentUser.role.some((role) => role.title === "Investor")
    : "";

  componentDidMount() {
    this.setState({ isUpgraded: this.props.currentUser.is_upgraded });
  }

  UserCard = () => {
    let { currentUser } = this.props;
    let isInvestor = this.props.currentUser.role
      ? this.props.currentUser.role.some((role) => role.title === "Investor")
      : "";
    return (
      <ul className="clearfix" onClick={() => this.props.hideUserMenu()}>
        <li>
          <Link to="/user/deals">All Deals</Link>
        </li>
        <li>
          <Link to="/user/profile">Profile</Link>
        </li>
        {isInvestor && (
          <>
            <li>
              <Link to="/user/preferences">Preferences</Link>
            </li>
          </>
        )}
        <li>
          <div className="nav-bottom logout">
            <Link onClick={() => this.props.logoutUser()}>Log Out</Link>
          </div>
        </li>
      </ul>
    );
  };

  triggerFile = () => {
    this.fileRef.current.click();
  };

  uploadAvatar = (e) => {
    this.props.uploadAvatar(e.target.files[0]);
  };

  render() {
    const loadingIcon = {
      right: "16px",
      top: "-37px",
      fontSize: "52px",
      position: "absolute",
    };

    let { loading, currentUser } = this.props;
    let { email, full_name } = currentUser;
    return !this.props.userMenu ? null : (
      <>
        <Backdrop
          show={this.props.userMenu}
          clicked={() => this.props.hideUserMenu()}
        />
        <div
          className="md-nav-sidebar profile__sidebar"
          style={{ zIndex: "9999" }}
        >
          <div className="ctn-inner">
            <div className="nav-title">
              <h3>Profile</h3>
              <span
                className="btn-close"
                onClick={() => this.props.hideUserMenu()}
              ></span>
            </div>
            <div className="nav-content">
              <div className="avatar">
                <div
                  className="picture"
                  style={{ opacity: loading ? "0.5" : 1 }}
                >
                  <img
                    style={{ height: "100%", objectFit: "contain" }}
                    src={
                      this.props.currentUser.photo_url ||
                      this.props.currentUser.logo_url
                    }
                    alt=""
                  />
                </div>
                <div className="upload-picture">
                  {loading && <Icon type="loading" style={loadingIcon} />}
                  <div className="file-upload">
                    <label
                      htmlFor="file-upload-01"
                      className="btn-upload"
                      onClick={this.triggerFile}
                    ></label>
                    <input
                      type="file"
                      className="input-file"
                      id="file-upload-04"
                      ref={this.fileRef}
                      accept="image/*"
                      onChange={this.uploadAvatar}
                    />
                  </div>
                </div>
              </div>

              <div className="information">
                <p>
                  <span className="name">{full_name}</span>
                  <span className="email">{email}</span>
                </p>
              </div>
              <div className="menu">{this.UserCard()}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ auth, app, loading }) => ({
  currentUser: auth.currentUser,
  userMenu: app.userMenu,
  loading: loading.buttonLoading,
});

const mapDispatchToProps = (dispatch) => ({
  hideUserMenu: () => dispatch(hideUserMenu),
  logoutUser: () => dispatch(logoutUser),
  uploadAvatar: (payload) => uploadAvatar(payload, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
