import React, { Component, Fragment } from "react";
import App from "./../App";
import { logoutUser, getCurrentUser } from "../redux/actions/authActions";
import { showUserMenu } from "../redux/actions/appActions";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ErrorPage from "./pages/ErrorPage";
import ProtectedRoute from "./ProtectedRoute";
import Application from "../Routes/Application";
import UserMenu from "./common/UserMenu";
import PublicRoute from "./PublicRoute";
import ProtectedTerms from "./ProtectedTerms";

class AppLayout extends Component {
  componentDidMount() {}

  render() {
    const { location, auth } = this.props;

    return (
      <Fragment>
        <UserMenu showUserMenu={showUserMenu} currentUser={auth.currentUser} />

        <Switch>
          <ProtectedRoute
            exact
            path="/user/dashboard"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/deals"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/add-entity"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/edit-entity/:id"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/deal-view/:uuid"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/ipo-view/:uuid"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/webinars"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/interviews"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/interview-view/:uuid"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/edit-ipo/:uuid"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/deals/edit/:id"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/deals/create"
            location={location}
            component={App}
          />
          <ProtectedRoute
            path="/user/bid/success/:uuid/:type"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/edit-bid/:uuid"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/newsletters"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/profile"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/preferences"
            location={location}
            component={App}
          />
         
          <ProtectedRoute
            exact
            path="/user/cornerstone"
            location={location}
            component={App}
          />
          <Route
            exact
            path="/user/pro-investor-series"
            location={location}
            component={App}
          />
          <PublicRoute
            exact
            path="/auth/login"
            location={location}
            component={Application}
          />
          <Route
            exact
            path="/auth/logout"
            location={location}
            component={(props) => {
              if (localStorage.getItem("auth_token")) {
                localStorage.removeItem("auth_token");
                return <Redirect to="/" />;
              }
              return null;
            }}
          />

          <Route
            exact
            path="/user/file-view"
            location={location}
            component={App}
          />
          <PublicRoute
            exact
            path="/auth/register"
            location={location}
            component={Application}
          />
          <PublicRoute
            exact
            path="/password/reset/:token"
            location={location}
            component={Application}
          />
          <PublicRoute
            exact
            path="/auth/register/investor"
            location={location}
            component={Application}
          />
          <PublicRoute
            exact
            path="/auth/register/broker"
            location={location}
            component={Application}
          />
          <Route
            path="/auth/profile-status"
            location={location}
            component={Application}
          />
          <PublicRoute
            exact
            path="/auth/register/company"
            location={location}
            component={Application}
          />
          <ProtectedRoute
            exact
            path="/allocation/:token"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/allocations/thanks/:type"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/director-shares"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/create-ipo"
            location={location}
            component={App}
          />
          <ProtectedRoute
            exact
            path="/user/update-ipo/:uuid"
            location={location}
            component={App}
          />
          <ProtectedRoute
            path="/user/deals/closed"
            location={location}
            component={App}
          />
          <ProtectedTerms
            path="/user/accept-terms"
            location={location}
            component={App}
          />
          <PublicRoute
            exact
            path="/auth/forgot-password"
            location={location}
            component={Application}
          />
           {/* <PublicRoute
            exact
            path="/unsubscribed/:uuid"
            location={location}
            component={Application}
          /> */}
          <Route path="/" component={Application} />

          <Route path="*" component={ErrorPage} />
        </Switch>
      </Fragment>
    );
    // );
  }
}

const mapStateToProps = ({ auth, app }) => ({ auth, app });

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser),
  showUserMenu: () => dispatch(showUserMenu),
  getCurrentUser: () => getCurrentUser(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
