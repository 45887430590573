import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { withRouter } from "react-router-dom";
import { Icon, Table } from "semantic-ui-react";
import { numberFormatter } from "../../../../../utils/constants";
import { BaseUrl } from "../../../../../services/settings";
import { customStyles, renderEntity } from "../../../../common/CommonMethods";

class Confirmation extends Component {
  onView = (token) => {
    const { history } = this.props;

    history.push(`/allocation/${token}`);
  };

  handleChange = (row) => {
    this.props.history.push(`/allocation/${row.unique_code}`);
  };

  render() {
    const columns = [
      {
        name: "Company",
        selector: "company_name",
        sortable: false,
        className: "company no-wrap",
        style: { minWidth: "130px" },
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              <span>
                {row.realestate_allocation &&
                  row.realestate_allocation.property &&
                  row.realestate_allocation.property.manager &&
                  row.realestate_allocation.property.manager.company_name}
              </span>
            </div>
          </>
        ),
      },
      {
        name: "Allocated",
        selector: "allocated",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.allocation_amount && numberFormatter(row.allocation_amount)}{" "}
          </div>
        ),
      },
      {
        name: "Entity ",
        selector: "entity ",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.bid && row.bid.entity && renderEntity(row.bid.entity)}
          </div>
        ),
      },
      {
        name: "Status",
        selector: "status",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.status && row.status}
          </div>
        ),
      },
      {
        name: "View",
        selector: "bid_now",
        sortable: false,
        left: true,
        cell: (row) => (
          <div
            className="tbl-title"
            onClick={() => this.onView(row.unique_code)}
          >
            <span className="bid-now">
              <Icon
                style={{ cursor: "pointer" }}
                color="blue"
                size="huge"
                name="arrow alternate circle right outline"
              />
            </span>
          </div>
        ),
      },
    ];

    return (
      <div className="md-table-normal table-responsive">
        <DataTable
          noHeader
          highlightOnHover
          columns={columns}
          data={this.props.deals}
          onRowClicked={this.handleChange}
          pointerOnHover={true}
          sortServer={true}
          customStyles={customStyles}
        />
      </div>
    );
  }
}

export default withRouter(Confirmation);
