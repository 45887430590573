import React, { Component } from "react";
// import logger from "logging-library";
// import FileViewer from "react-file-viewer";
// import { CustomErrorComponent } from "custom-error";

const file = "http://www.africau.edu/images/default/sample.pdf";
const type = "pdf";
class FileView extends Component {
  render() {
    return <div></div>;
  }
}

export default FileView;
