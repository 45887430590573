import React from "react";
import { Link } from "react-router-dom";
import FooterLogo from "../../assets/images/footer_logo_r.png";
import AppleStore from "../../assets/images/Group-7968.png";
import GooglePlay from "../../assets/images/Group7969.png";

const FooterBrokerNav = ({ logoutAction }) => {
  return (
    <ul classNameName="clearfix">
      <li>
        <Link to="/user/profile">Profile</Link>
      </li>
      <li>
        <Link to="/user/deals">Deals</Link>
      </li>
    </ul>
  );
};

const FooterInvestorNav = ({ logoutAction, currentUser }) => {
  return (
    <ul classNameName="clearfix">
      <li>
        <Link to="/user/profile">Profile</Link>
      </li>
      <li>
        <Link to="/user/preferences">Preferences</Link>
      </li>
      {currentUser.realestate_approved !== "Approved" && (
        <li>
          <Link to="/user/profile">Upgrade</Link>
        </li>
      )}
    </ul>
  );
};

const Footer = ({ currentUser, onLogout }) => {
  const inInvestor = currentUser.role
    ? currentUser.role.some((role) => role.title === "Investor")
    : false;
  return (
    <>
      <footer className="page-footer font-small indigo">
        <div className="container text-center text-md-left">
          <div
            className="row"
            // style={{
            //   padding: "0 80px",
            // }}
          >
            <div className="col-md-5 mx-auto">
              <h5 className="footer_head">
                <img
                  src={FooterLogo}
                  style={{
                    width: "310px",
                  }}
                />
              </h5>

              <p className="footer_p">
                180 Markets PTY LTD  ACN 638 381 129 trading as 180 Real Estate is an authorised
                representative (AR No. 001280199) of Pursuit Capital Pty Ltd ACN
                136 717 283 (AFSL No. 339211). Copyright © 2020 180 Markets. All
                rights reserved. <br /> <br /> Any advice on this website is
                general advice which does not take into account your
                circumstances and, before acting, you should consider its
                appropriateness having regard to your circumstances.
              </p>
            </div>

            <hr className="clearfix w-100 d-md-none" />

            <div className="col-md-3 mx-auto">
              <h5 className="footer_head">Links</h5>

              <ul className="list-unstyled ftr_link">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/who-we-are">Who We Are</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/auth/register/investor">Create an Account</Link>
                </li>
                <li>
                  <Link to="/auth/login">Login</Link>
                </li>
              </ul>
            </div>

            <hr className="clearfix w-100 d-md-none" />

            <hr className="clearfix w-100 d-md-none" />

            <div className="col-md-4 mx-auto">
              <h5
                className="footer_head"
                style={{
                  marginTop: "20px !important",
                }}
              >
                Stay In Touch
              </h5>
              <form>
                <div className="row">
                  <div className="col-5 pr-0
">
                    {" "}
                    <input
                      type="email"
                      className="form-control rounded-0 footer_input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter your email address"
                    />
                  </div>
                  <div className="col-6 p-0 text-left">
                    <button
                      type="submit"
                      className="btn btn-primary footer_btn"
                    >
                      Subscribe for Updates
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </footer>

      <div className="page-footer">
        <div className="container">
          <div
            className="row"
            // style={{
            //   padding: "0 80px",
            // }}
          >
            <div className="col-md-8">
              <div className="footer-copyright py-3">
                © {(new Date().getFullYear())} 180Realestate. All rights reserved
                <span className="footer_li">
                  <li>
                    <Link to={`/investor/terms&conditions`}>Terms of Use</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="smd-social">
                {/*<div className="social_med">*/}
                {/*  <a*/}
                {/*    href="https://www.linkedin.com/company/37502058/"*/}
                {/*    target="_blank"*/}
                {/*  >*/}
                {/*    <i class="linkedin alternate icon"></i>*/}
                {/*  </a>*/}
                {/*</div>*/}
                {/*<div className="social_med">*/}
                {/*  <a*/}
                {/*    href="https://www.facebook.com/180-Markets-104057701188064"*/}
                {/*    target="_blank"*/}
                {/*  >*/}
                {/*    <i class="facebook f icon"></i>*/}
                {/*  </a>*/}
                {/*</div>*/}
                {/*<div className="social_med">*/}
                {/*  <a href="https://twitter.com/180Markets" target="_blank">*/}
                {/*    <i class="twitter icon"></i>*/}
                {/*  </a>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
