import React, { Component } from "react";
import CreateIpoForm from "./CreateIpoForm/CreateIpoForm";
import { connect } from "react-redux";
import IpoService from "../../../redux/actions/ipoActions";
import { deleteMediaSuccess } from "../../../redux/actions/accountActions";
import moment from "moment";

class CreateIpo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      logo: null,
      term_sheets: null,
      presentation: null,
      information_overview: null,
      files: [],
      details: "",
      helpful_links: "",
      open: false,
    };
  }

  open = (id) => {
    this.setState({ open: true, documentID: id });
  };

  close = () => this.setState({ open: false });

  componentDidMount = () => {
    const { match, fetchSelectedIpoData } = this.props;
    if (match.params.uuid) {
      fetchSelectedIpoData(match.params.uuid);
    }
  };

  handleSubmit = (values) => {
    const { history, updateIpo, match, createIpo, ipo } = this.props;

    if (match.params.uuid) {
      let {
        logo,
        term_sheets,
        prospectus,
        presentation,
        details,
        helpful_links,
      } = this.state;

      return updateIpo(
        {
          ...values,
          logo,
          term_sheet: term_sheets,
          details,
          presentation,
          helpful_links,
          prospectus,
          id: ipo.id,
        },
        history
      );
    }
    let {
      logo,
      term_sheets,
      prospectus,
      presentation,
      details,
      helpful_links,
    } = this.state;
    createIpo(
      {
        ...values,
        logo,
        term_sheet: term_sheets,
        details,
        helpful_links,
        prospectus,
        presentation,
      },
      history
    );
  };

  handleUpload = (file) => {
    return this.setState({
      logo: file,
    });
  };

  handleMultipleFilesTermSheet = (files) => {
    return this.setState({
      term_sheets: files,
    });
  };

  prospectusUploadHandler = (files) => {
    return this.setState({
      prospectus: files,
    });
  };

  presentationUploadHandler = (files) => {
    return this.setState({
      presentation: files,
    });
  };

  editorValue = (value) => {
    this.setState({ details: value });
  };

  getHelpfulLinks = (value) => {
    this.setState({ helpful_links: value });
  };

  dateFormat = (inputDate) => {
    if (inputDate) {
      var date = moment(inputDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      return new Date(date);
    }
  };

  onDocumentDeleteHandler = () => {
    let { deleteMedia } = this.props;
    deleteMedia({ id: this.state.documentID });
    this.setState({ open: false });
  };

  render() {
    const { ipo, match } = this.props;
    return (
      <CreateIpoForm
        title={match.params.uuid ? "Update IPO" : "Add New IPO"}
        onSubmit={this.handleSubmit}
        handleUpload={this.handleUpload}
        {...this.props}
        handleMultipleFilesTermSheet={this.handleMultipleFilesTermSheet}
        prospectusUploadHandler={this.prospectusUploadHandler}
        presentationUploadHandler={this.presentationUploadHandler}
        paramsID={this.props.paramsID}
        editorValue={this.editorValue}
        getHelpfulLinks={this.getHelpfulLinks}
        ipo={ipo}
        initialValues={
          match.params.uuid &&
          ipo && {
            ...ipo,
            listing_date: this.dateFormat(ipo.listing_date),
            bids_due: this.dateFormat(ipo.bids_due),
          }
        }
        open={this.open}
        show={this.state.open}
        close={this.close}
        onDocumentDeleteHandler={this.onDocumentDeleteHandler}
      />
    );
  }
}

const { createNewIpo, fetchSelectedIpo, updateIpoById } = IpoService;
const mapDispatchToProps = (dispatch) => ({
  createIpo: (payload, history) => createNewIpo(dispatch, payload, history),
  fetchSelectedIpoData: (uuid) => fetchSelectedIpo(uuid, dispatch),
  deleteMedia: (payload) => deleteMediaSuccess(dispatch, payload),
  updateIpo: (payload, history) => updateIpoById(dispatch, payload, history),
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    ipo: state.ipos.selectedIpo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CreateIpo);
