import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormField } from "../../../layout/FormField";
import { required, email } from "redux-form-validators";
import { Field, reduxForm, Form } from "redux-form";

class ForgotPassword extends Component {
  render() {
    let { handleSubmit } = this.props;
    return (
      <div className="page-signup page-forgot-password">
        <div class="form-normal form-forgot-password">
          <h1>Forgot password</h1>
          <Form onSubmit={handleSubmit}>
            <div className="all-fields">
              <div className="field">
                <label className="title">Email</label>
                <div className="field-input">
                  <Field
                    className="form-control entity__input"
                    type="text"
                    name="email"
                    component={FormField}
                    validate={[required(), email()]}
                  />
                </div>
                <div className="text">
                  <p>We will send a link in your email to reset a password</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="button-group">
                <div className="col-sm-12">
                  <button className="btn btn-primary btn-medium">
                    Send reset link
                  </button>
                </div>
              </div>
            </div>
            <div className="text text-bottom">
              <p>
                <Link to="/auth/login">Back to Login</Link>
              </p>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: "ForgotPassword" })(ForgotPassword);
