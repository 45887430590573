import {
  START_BUTTON_LOADING,
  STOP_BUTTON_LOADING,
  START_LOADER,
  STOP_LOADER,
} from "../actions/constants";

const initialState = {
  buttonLoading: false,
  meta: {},
};

const loadingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case START_BUTTON_LOADING:
      return {
        ...state,
        buttonLoading: true,
      };

    case STOP_BUTTON_LOADING:
      return {
        ...state,
        buttonLoading: false,
      };

    case START_LOADER:
      return {
        ...state,
        meta: false,
      };

    case STOP_LOADER:
      return {
        ...state,
        meta: false,
      };

    default:
      return state;
  }
};

export default loadingReducer;
