import React, { Component } from "react";
import { FormField } from "../../../layout/FormField";
import { required, length } from "redux-form-validators";
import { Field, reduxForm, Form } from "redux-form";
import { matchPasswords } from "../../../../utils/customValidators";

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showConfirm: false,
    };
  }
  render() {
    let { handleSubmit } = this.props;

    return (
      <main className="page-main page-signup page-reset-password">
        <div className="container">
          <div className="form-normal form-reset-password">
            <h1>Reset password</h1>
            <Form onSubmit={handleSubmit}>
              <div className="all-fields">
                <div className="field">
                  <label className="title">New password</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      type={this.state.show ? "text" : "password"}
                      name="password"
                      component={FormField}
                      validate={[required(), length({ min: 6 })]}
                    />
                    <span
                      className="btn-toggle hide"
                      onClick={() => this.setState({ show: !this.state.show })}
                    ></span>
                  </div>
                </div>
                <div className="field hl-margin">
                  <label className="title">Confirm password</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      type={this.state.showConfirm ? "text" : "password"}
                      name="password_confirmation"
                      component={FormField}
                      validate={[
                        required(),
                        matchPasswords(),
                        length({ min: 6 }),
                      ]}
                    />
                    <span
                      className="btn-toggle hide"
                      onClick={() =>
                        this.setState({ showConfirm: !this.state.showConfirm })
                      }
                    ></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="button-group">
                  <div className="col-sm-12">
                    <button
                      className="btn btn-primary btn-medium"
                      type="submit"
                    >
                      Save new password
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </main>
    );
  }
}

export default reduxForm({ form: "ForgotPassword" })(ResetPasswordForm);
