import React from "react";

const ErrorPage = () => {
  return (
    <main className="page-main page-signup page-create-account brocker-account ">
      <div className="container">
        <div class="form-normal form-create-account">404 - Not found</div>
      </div>
    </main>
  );
};

export default ErrorPage;
