import React, { Component } from "react";
import { email, required } from "redux-form-validators";
import { Form, Field, reduxForm } from "redux-form";
import { FormField } from "../../../layout/FormField";

class NewsletterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { handleSubmit } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <Field
          className="form-control"
          type="email"
          name="email"
          placeholder="Enter your Email"
          component={FormField}
          validate={[required(), email()]}
        />

        <button type="submit" class="btn btn-normal btn-signup">
          Subscribe
        </button>
      </Form>
    );
  }
}

export default reduxForm({ form: "NewsletterForm" })(NewsletterForm);
