import React, { Component } from "react";
import CornerStoneForm from "./CornerStoneForm/CornerStoneForm";
import SuggestionsForm from "./SuggestionsForm/SuggestionsForm";
import CounterStoneActions from "../../../redux/actions/counterStoneActions";
import { connect } from "react-redux";
import { Modal, Tabs } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

class CornerStoneComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
  }

  handleSubmitCornerStone = (values) => {
    const { submitCounterStone, history } = this.props;
    Modal.confirm({
      title: "Confirm",
      width: 700,
      okButtonProps: {
        type: "primary",
        size: "large",
      },
      cancelButtonProps: {
        size: "large",
      },
      icon: <ExclamationCircleOutlined />,
      content: (
        <p className="pconfirm">
          By submitting a bid, you are making a binding and irrevocable offer to
          acquire the securities, at the price you entered. You confirm you make
          each of the representations and warranties specified in the Investor
          Terms. We will approach the Company with your bid and try and organise
          a Capital Raise.
        </p>
      ),
      okText: "Confirm",
      cancelText: "Cancel",
      async onOk() {
        await submitCounterStone(values, history);
      },
      onCancel() {},
    });
  };

  handleSubmitSuggestion = (values) => {
    const { submitSuggestions, history } = this.props;

    submitSuggestions(values, history);
  };

  callback = (key) => {
    this.setState({ activeTab: key });
  };

  render() {
    const { activeTab } = this.state;

    return (
      <main className="page-cornerstone page-deals page-all-deals">
        <div className="container">
          <div className="page-deals page-all-deals">
            <div className="title">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <SuggestionsForm onSubmit={this.handleSubmitSuggestion} />
                </div>

                <div className="col-md-6 col-sm-12">
                  <>
                    <div className="row">
                      <div className="col-sm-9">
                        <h1 style={{ fontSize: "40", fontWeight: "500" }}>
                          Suggestions
                        </h1>
                      </div>
                    </div>
                    <p>
                    Real estate text to go here....
                      {/* <b>
                        Suggest a Capital raise by letting us know a company in
                        need of Capital that we should contact.
                      </b>
                      <br />
                      <br /> We are happy to contact any listed Company and
                      propose a 180 Market Investor led raise.
                      <br /> <br />
                      Let us know why you think this Company would be suitable
                      for a 180 Markets Capital Raise! */}
                    </p>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const { postCounterStone, postSuggestion } = CounterStoneActions;
const mapDispatchToProps = (dispatch) => ({
  submitCounterStone: (payload, history) =>
    postCounterStone(dispatch, payload, history),
  submitSuggestions: (payload, history) =>
    postSuggestion(dispatch, payload, history),
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CornerStoneComponent);
