import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import interviewActions from "../../../redux/actions/interviewActions";
import InterviewsTable from "./InterviewsTable/InterviewsTable";
import { sortInterviewsAccToDate } from "../../../utils/constants";

const { TabPane } = Tabs;

class Interviews extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onView = (id) => {
    this.props.history.push(`/user/interview-view/${id}`);
  };

  componentWillMount = () => {
    const { getAll } = this.props;

    getAll();
  };

  render() {
    const { interviews } = this.props;

    return (
      <main className="page-main page-deals page-all-deals">
        <div className="container">
          <div className="page-deals page-all-deals">
            <div className="title">
              <div className="row">
                <div className="col-sm-9">
                  <h1>18 Minutes with 180</h1>
                </div>
              </div>
              <InterviewsTable
                interviews={sortInterviewsAccToDate(interviews)}
                onView={this.onView}
                history={this.props.history}
              />
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return { interviews: state.interviews.list };
};

const { getAllInterviews, getSelectedInterview } = interviewActions;

const mapDispatchToProps = (dispatch) => ({
  getAll: () => getAllInterviews(dispatch),
  getSelected: (id) => getSelectedInterview(dispatch, id),
});

export default connect(mapStateToProps, mapDispatchToProps)(Interviews);
