import {
  REGISTRATION_PROCESS_STARTED,
  REGISTRATION_VALIDATION_FAILED,
  REGISTRATION_PROCESS_ENDED,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILED,
  REGISTRATION_TYPE,
  CLEAR_REGISTRATON_ALERTS,
  CHANGE_EMAIL_PENDING_STATE,
  PROFILE_STATUS
} from "../actions/constants";

const initialState = {
  isProcessing: false,
  optedType: null,
  error: "",
  success: false,
  emailError: "",
  alert: {
    type: null,
    message: null
  },
  register: false,
  profilePending: false,
  profileStatusOk: true,
  profileStatusMessage: ""
};

const registerReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case REGISTRATION_PROCESS_STARTED:
      return {
        ...state,
        isProcessing: true
      };
    case REGISTRATION_PROCESS_ENDED:
      return {
        ...state,
        isProcessing: false
      };
    case REGISTRATION_SUCCESS:
      return {
        ...state,
        register: true,
        alert: {
          type: "success",
          message: "Registration Successful. Please login"
        },
        success: true
      };

    case "USER_LOGIN_PENDING":
      return {
        ...state,
        profilePending: true
      };

    case CHANGE_EMAIL_PENDING_STATE:
      return {
        ...state,
        profilePending: false
      };

    case "REGISTRATION_ERROR_MESSAGE":
      return {
        ...state,
        emailError: payload
      };
    case CLEAR_REGISTRATON_ALERTS:
      return {
        ...state,
        alert: {
          type: null,
          message: null
        },
        success: false
      };
    case REGISTRATION_FAILED:
      return {
        ...state,
        alert: {
          type: "error",
          message: payload
        }
      };
    case REGISTRATION_TYPE:
      return {
        ...state,
        optedType: payload
      };
    case REGISTRATION_VALIDATION_FAILED:
      return {
        ...state,
        alert: payload
      };
    case PROFILE_STATUS:
      return {
        ...state,
        profileStatusOk: !payload.error,
        profileStatusMessage: payload.message
      };
    default:
      return state;
  }
};

export default registerReducer;
