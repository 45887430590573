import React, { Component } from "react";
import { connect } from "react-redux";
import { changePendingMessage } from "../../redux/actions/authActions";

class Message extends Component {
  componentDidMount = () => {};
  render() {
    const { profileStatusMessage, profileStatusOk } = this.props;
    return (
      <main className="page-main page-signup page-create-account">
        <div className="container">
          <div class="form-normal form-create-account">
            <h3 style={{ textAlign: "center" }}>
              {!profileStatusOk && profileStatusMessage}
            </h3>
          </div>
        </div>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  // changePendingMessage: () => dispatch(changePendingMessage())
});

const mapStateToProps = state => {
  return {
    profileStatusOk: state.register.profileStatusOk,
    profileStatusMessage: state.register.profileStatusMessage
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);
