import React, { Component } from "react";
import ContactForm from "./ContactForm/ContactForm";
import { contactUs } from "../../../../redux/actions/authActions";
import { connect } from "react-redux";

class ContactContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  contactFormSubmitHandler = values => {
    let { contactUsSubmit } = this.props;
    contactUsSubmit(values);
  };

  render() {
    return <ContactForm onSubmit={this.contactFormSubmitHandler} />;
  }
}

const mapDispatchToProps = dispatch => ({
  contactUsSubmit: payload => contactUs({ payload, dispatch })
});

export default connect(null, mapDispatchToProps)(ContactContainer);
