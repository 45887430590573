import axios from "axios";
import { BaseUrl, configMultipart } from "./settings";

class InterviewsService {
  getAll = async () => {
    return await axios
      .get(BaseUrl + `realestate/v1/interviews`, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  getSelected = async ({ payload }) => {
    return await axios
      .get(BaseUrl + `realestate/v1/interviews/${payload.id}`, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export default new InterviewsService();
