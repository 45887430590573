import React, { Component, Fragment } from "react";
import { required } from "redux-form-validators";
import { Field, Form, reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";

import { FormField } from "../../../../layout/FormField";
import ButtonLoader from "../../../../common/ButtonLoader";
import EntityActions from "../../../../../redux/actions/entityActions";
import NewDropzone from "../../../../common/NewDropzone";
import { scrollToFirstError } from "../../../../../utils/scroller";
import { RenderPersonal } from "../RenderPersonal/renderPersonal";
import { RenderCompany } from "../RenderCompany/renderCompany";
import { withRouter } from "react-router-dom";

class TrustForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beneficial_owners: [],
      validation: false,
      dobStartDate: "",
    };
  }

  onBenificialChangeHandler = (fields, value) => {
    if (value > 0) {
      let obj = {};
      for (let i = 0; i < value; i++) {
        {
          obj[i] = i;
        }
      }

      this.setState({ beneficial_owners: [...Object.entries(obj)] });
    } else {
      this.setState({ beneficial_owners: [] });
    }
  };

  validationHandler = () => {
    this.setState({ validation: true });
  };

  componentDidMount() {
    this.onBenificialChangeHandler(null, this.props.beneficialOwners);
    let dobStartDate = new Date();
    dobStartDate.setFullYear(dobStartDate.getFullYear() - 18);
    this.setState({ dobStartDate });
  }

  statusOfApplicant = ["Yes", "No"];
  trusteeTypes = [
    { text: "Company", value: "company" },
    { text: "Individual", value: "individual" },
    { text: "Company/Individual", value: "company/individual" },
  ];

  render() {
    const { edit } = this.props;

    return (
      <div id="trust">
        <Form onSubmit={this.props.handleSubmit} style={{ marginTop: "40px" }}>
          <div className="all-fields">
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Name of Trust *</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="name"
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Trading Name (Optional)</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="trading_name"
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Type of Trust *</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="type_of_trust"
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">ABN / ARBN *</label>
                  <div className="field-input">
                    <Field
                        className="form-control entity__input"
                        name="abn"
                        component={FormField}
                        type="text"
                        validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/*<div className="row mb-3">*/}
            {/* */}
            {/*  /!*<div className="col-sm-6">*!/*/}
            {/*  /!*  <div className="field">*!/*/}
            {/*  /!*    <label className="title">*!/*/}
            {/*  /!*      Account Designation (Optional)*!/*/}
            {/*  /!*    </label>*!/*/}
            {/*  /!*    <div className="field-input">*!/*/}
            {/*  /!*      <Field*!/*/}
            {/*  /!*        className="form-control entity__input"*!/*/}
            {/*  /!*        name="account_designation"*!/*/}
            {/*  /!*        component={FormField}*!/*/}
            {/*  /!*        type="text"*!/*/}
            {/*  /!*      />*!/*/}
            {/*  /!*    </div>*!/*/}
            {/*  /!*  </div>*!/*/}
            {/*  /!*</div>*!/*/}
            {/*</div>*/}

            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Country of Establishment *</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="country_of_establishment"
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">
                    Tax File Number (TFN) (Optional)
                  </label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="tfn"
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              {/*<div className="col-sm-6">*/}
              {/*  <div className="field">*/}
              {/*    <label className="title">Business name of the trustee</label>*/}
              {/*    <div className="field-input">*/}
              {/*      <Field*/}
              {/*        className="form-control entity__input"*/}
              {/*        name="trustee_business_name"*/}
              {/*        component={FormField}*/}
              {/*        type="text"*/}
              {/*        validate={[required()]}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/* <div className="col-sm-6">
                <div className="field">
                  <label className="title">Name of the Settlor *</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="settlor_name"
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Address *</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="address"
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Suburb *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name="suburb"
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-3">
                <div className="field">
                  <label className="title">Postcode *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name="postcode"
                      component={FormField}
                      type="number"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="field">
                  <label className="title">State *</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      type="text"
                      name="state"
                      component={FormField}
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Country *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name="country"
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Fragment>
              <NewDropzone
                multiple={true}
                buttonLabel="Choose document"
                title={"Trust Deed"}
                onUpload={this.props.onDeedDocumentUpload}
                multiDocuments={this.props.trustDeedDocument}
                onDocumentDeleteHandler={this.props.onDocumentDeleteHandler}
                open={this.props.open}
                close={this.props.close}
                show={this.props.show}
              />
            </Fragment>

            <Fragment>
              <NewDropzone
                multiple={true}
                buttonLabel="Choose document"
                title={"Sophisticated Investor Form"}
                multiDocuments={this.props.investorForms}
                onUpload={(files) => {
                  this.props.onInvestorDocumentUpload(files);
                }}
                onDocumentDeleteHandler={this.props.onDocumentDeleteHandler}
                open={this.props.open}
                close={this.props.close}
                show={this.props.show}
              />
            </Fragment>

            {/*<div className="row mb-3">*/}
            {/*  <div className="col-sm-3">*/}
            {/*    <div className="field">*/}
            {/*      <label className="title">Status of Applicant</label>*/}
            {/*      <div class="field-input form-group row mb-3">*/}
            {/*        {this.statusOfApplicant.map(element => {*/}
            {/*          return (*/}
            {/*            <Field*/}
            {/*              name="status_of_applicant"*/}
            {/*              component={FormField}*/}
            {/*              type="radio"*/}
            {/*              value={element}*/}
            {/*              label={element}*/}
            {/*              validate={[required()]}*/}
            {/*              className={`radio-inline`}*/}
            {/*            />*/}
            {/*          );*/}
            {/*        })}*/}
            {/*      </div>*/}
            {/*      <p style={{ fontSize: "12px", color: "red " }}>*/}
            {/*        {this.state.validation &&*/}
            {/*          this.props.form.TrustForm &&*/}
            {/*          this.props.form.TrustForm.syncErrors &&*/}
            {/*          this.props.form.TrustForm.syncErrors*/}
            {/*            .status_of_applicant &&*/}
            {/*          `This field is  ${this.props.form.TrustForm.syncErrors.status_of_applicant}`}*/}
            {/*      </p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/* {this.props.form.TrustForm &&
            this.props.form.TrustForm.values &&
            this.props.form.TrustForm.values.status_of_applicant === "Yes" ? ( */}

            {/*<div className="row mb-3">*/}
            {/*  <div className="col">*/}
            {/*    <p>DVP Information (Optional)</p>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="row mb-3">*/}
            {/*  <div className="col-sm-4">*/}
            {/*    <div className="field">*/}
            {/*      <label className="title">Broker Name</label>*/}
            {/*      <div className="field-input">*/}
            {/*        <Field*/}
            {/*          className="form-control entity__input"*/}
            {/*          name="broker_name"*/}
            {/*          component={FormField}*/}
            {/*          type="text"*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="col-sm-4">*/}
            {/*    <div className="field">*/}
            {/*      <label className="title">Broker Phone Number</label>*/}
            {/*      <div className="field-input">*/}
            {/*        <Field*/}
            {/*          className="form-control entity__input"*/}
            {/*          name="broker_phone"*/}
            {/*          component={FormField}*/}
            {/*          type="text"*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="col-sm-4">*/}
            {/*    <div className="field">*/}
            {/*      <label className="title">Broker Email</label>*/}
            {/*      <div className="field-input">*/}
            {/*        <Field*/}
            {/*          className="form-control entity__input"*/}
            {/*          name="broker_email"*/}
            {/*          component={FormField}*/}
            {/*          type="text"*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="col-sm-4">*/}
            {/*    <div className="field">*/}
            {/*      <label className="title">PID</label>*/}
            {/*      <div className="field-input">*/}
            {/*        <Field*/}
            {/*          className="form-control entity__input"*/}
            {/*          name="pid"*/}
            {/*          component={FormField}*/}
            {/*          type="text"*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="col-sm-4">*/}
            {/*    <div className="field">*/}
            {/*      <label className="title">HIN</label>*/}
            {/*      <div className="field-input">*/}
            {/*        <Field*/}
            {/*          className="form-control entity__input"*/}
            {/*          name="hin"*/}
            {/*          component={FormField}*/}
            {/*          type="text"*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}


          </div>
          <div className="row mb-3">
            <div className="col-sm-12">
              <div className="field">
                <label className="title">Trustee Type *</label>
                <div className="field-input row mb-3">
                  {this.trusteeTypes.map((element) => (
                    <Field
                      name="trust_type"
                      component={FormField}
                      type="radio"
                      value={element.value}
                      label={element.text}
                      // validate={[required()]}
                      className={`radio-inline`}
                    />
                  ))}
                </div>
                <p style={{ fontSize: "12px", color: "red " }}>
                  {this.state.validation &&
                    this.props.form.TrustForm &&
                    this.props.form.TrustForm.syncErrors &&
                    this.props.form.TrustForm.syncErrors.trust_type &&
                    `This field is  ${this.props.form.TrustForm.syncErrors.trust_type}`}
                </p>
              </div>
            </div>
          </div>

          {this.props.form.TrustForm &&
            this.props.form.TrustForm.values &&
            this.props.form.TrustForm.values.trust_type === "individual" && (
              <FieldArray
                name="beneficial_personal"
                component={RenderPersonal}
                prevProps={this.props}
              />
            )}

          {this.props.form.TrustForm &&
            this.props.form.TrustForm.values &&
            this.props.form.TrustForm.values.trust_type === "company" && (
              <FieldArray
                name="beneficial_company"
                component={RenderCompany}
                prevProps={this.props}
              />
            )}

          {this.props.form.TrustForm &&
            this.props.form.TrustForm.values &&
            this.props.form.TrustForm.values.trust_type ===
              "company/individual" && (
              <>
                <FieldArray
                  name="beneficial_company"
                  component={RenderCompany}
                  prevProps={this.props}
                />
                <FieldArray
                  name="beneficial_personal"
                  component={RenderPersonal}
                  prevProps={this.props}
                />
              </>
            )}

          <div className="row mb-3">
            <div className="button-group">
              <div className="col-sm-6">
                <ButtonLoader
                  type="submit"
                  className="btn btn-primary btn-medium"
                >
                  Submit
                </ButtonLoader>
              </div>
              {edit && (
                <div className="col-sm-6">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-medium"
                    onClick={() => this.props.history.push("/user/profile")}
                  >
                    Back to Profile
                  </button>
                </div>
              )}
            </div>
          </div>
        </Form>
        {/* <button
          onClick={() =>
            this.myRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        >
          {" "}
          Click to scroll{" "}
        </button> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    form: state.form,
    initialValue: {
      number_of_beneficial_owners: 1,
    },
  };
};

const { unmountSingleEntity } = EntityActions;

const mapDispatchToProps = (dispatch) => ({
  fetchSingleEntityData: (payload, history) =>
    unmountSingleEntity(dispatch, null),
});

export default reduxForm({
  form: "TrustForm",
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(connect(mapStateToProps, mapDispatchToProps)(withRouter(TrustForm)));
