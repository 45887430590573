import React, { Component } from "react";
import { connect } from "react-redux";
import CreateDeals from "./CreateDeals";
import dealActions from "../../../../redux/actions/dealActions";
import { deleteMediaSuccess } from "../../../../redux/actions/accountActions";

var moment = require("moment");

class EditDealsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentID: "",
      open: false,
    };
  }

  open = (id) => {
    this.setState({ open: true, documentID: id });
  };

  close = () => this.setState({ open: false });

  onDocumentDeleteHandler = () => {
    let { deleteMedia } = this.props;
    deleteMedia({ id: this.state.documentID });
    this.setState({ open: false });
  };

  componentDidMount = () => {
    let { getPublicDealData, getSingleDealData, match } = this.props;
    getSingleDealData(match.params.id);
    getPublicDealData(match.params.id);
  };

  dateFormat = (inputDate) => {
    if (inputDate) {
      var date = moment(inputDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      return new Date(date);
    }
  };

  render() {
    let { singleDeal } = this.props;
    return (
      <CreateDeals
        initialValues={{
          name: singleDeal && singleDeal.name,
          is_lead_broker: singleDeal && singleDeal.is_lead_broker,
          last_price: singleDeal && singleDeal.last_price,
          market_capital: singleDeal && singleDeal.market_capital,
          discount: singleDeal && singleDeal.discount,
          capital_raise: singleDeal && singleDeal.capital_raise,
          details: singleDeal && singleDeal.details,
          bids_due: singleDeal && this.dateFormat(singleDeal.bids_due),
          settlement_date:
            singleDeal && this.dateFormat(singleDeal.settlement_date),
          information_overview: singleDeal && singleDeal.information_overview,
          deal_logo: singleDeal && singleDeal.deal_logo,
          term_sheets: singleDeal && singleDeal.term_sheets,
          company_name: singleDeal && singleDeal.company_name,
          asx_code: singleDeal && singleDeal.asx_code,
          free_option: singleDeal && singleDeal.free_option,
          additional_document: singleDeal && singleDeal.additional_document,
          raise_amount: singleDeal && singleDeal.raise_amount,
          additional_document_name:
            singleDeal && singleDeal.additional_document_name,
          helpful_links: singleDeal && singleDeal.helpful_links,
        }}
        {...this.props}
        open={this.open}
        show={this.state.open}
        close={this.close}
        onDocumentDeleteHandler={this.onDocumentDeleteHandler}
        paramsID={this.props.match.params.id}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    singleDeal: state.deals.singleDeal,
  };
};

let { getSingleDeal, singlePublicDeal } = dealActions;
const mapDispatchToProps = (dispatch) => ({
  getSingleDealData: (payload) => getSingleDeal(payload, dispatch),
  getPublicDealData: (payload) => singlePublicDeal(payload, dispatch),
  deleteMedia: (payload) => deleteMediaSuccess(dispatch, payload),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDealsContainer);
