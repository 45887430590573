import actionTypes from "../actions/webinarActions";

const initialState = {
  webinars: [],
  loading: false,
};

const webinars = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.GET_ALL_WEBINARS:
      return {
        ...state,
        webinars: payload.data.data,
      };

    default:
      return state;
  }
};

export default webinars;
