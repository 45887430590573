import React, { Component } from "react";
import { reduxForm, Field, Form } from "redux-form";
import { FormField } from "../../layout/FormField";
import { required, email, length } from "redux-form-validators";
import { connect } from "react-redux";
import { checkEmail } from "../../../redux/actions/authActions";
import ButtonLoader from "../../common/ButtonLoader";
import { Link } from "react-router-dom";
import {
  phoneNumber,
  minLength,
  matchPasswords,
} from "../../../utils/customValidators";
import NewDropzone from "../../common/NewDropzone";

class BrokerRegisterForm extends Component {
  onBlurHandler = (e) => {
    let { checkEmailExistence } = this.props;
    checkEmailExistence(e.target.value);
  };

  render() {
    const { handleSubmit, register, onLogoUpload } = this.props;
    return (
      <div class="form-normal form-register">
        <Form onSubmit={handleSubmit}>
          <div className="all-fields">
            <div className="row">
              <div className="col-sm-6">
                <div className="field">
                  <div className="field-input">
                    <Field
                      className="form-control broker__formInput"
                      name="first_name"
                      placeholder="First Name"
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <div className="field-input">
                    <Field
                      className="form-control broker__formInput"
                      name="last_name"
                      placeholder="Last Name"
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="field">
                  <div className="field-input">
                    <Field
                      className="form-control broker__formInput"
                      name="phone"
                      placeholder="Phone Number"
                      component={FormField}
                      type="number"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <div className="field-input">
                    <Field
                      className="form-control broker__formInput"
                      name="email"
                      placeholder="Email Address"
                      component={FormField}
                      onChange={(e) => this.onBlurHandler(e)}
                      type="text"
                      validate={[required(), email()]}
                    />
                    <div className="form__field-error">
                      {register.emailError ? register.emailError : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="field">
                  <div className="field-input">
                    <Field
                      className="form-control broker__formInput"
                      name="password"
                      placeholder="Password"
                      autoComplete="new-password"
                      component={FormField}
                      type="password"
                      validate={[required(), length({ min: 6 })]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <div className="field-input">
                    <Field
                      className="form-control broker__formInput"
                      name="password_confirmation"
                      component={FormField}
                      type="password"
                      placeholder="Confirm Password"
                      autoComplete="new-password"
                      validate={[
                        required(),
                        matchPasswords(),
                        length({ min: 6 }),
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="field">
                  <div className="field-input">
                    <Field
                      className="form-control broker__formInput"
                      name="company_name"
                      placeholder="Company Name"
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
                {this.props.role === "Broker" && (
                  <div className="field">
                    <div className="field-input">
                      <Field
                        className="form-control broker__formInput"
                        placeholder="AFSL or Authorised Representative Number"
                        name="afsl"
                        component={FormField}
                        type="text"
                        validate={[required()]}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="col-sm-6">
                <NewDropzone
                  multiple={false}
                  buttonLabel="Choose document"
                  title={"Upload Logo"}
                  accept="image/*"
                  onUpload={this.props.onLogoUpload}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="field">
                  <Field
                    className="form-control broker__formInput"
                    name="terms_conditions"
                    component={FormField}
                    type="checkbox"
                    label={
                      <p>
                        I accept the{" "}
                        <Link
                          to={"/broker/terms&conditions"}
                          target="_blank"
                        >{`Terms & Conditions`}</Link>{" "}
                        and{" "}
                        <Link
                          to={"/privacy-policy"}
                          target="_blank"
                        >{`Privacy Policy`}</Link>
                      </p>
                    }
                    requiredError="Accept our terms and conditions"
                    validate={[required()]}
                    htmlFor="any"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="button-group hl-button-group">
                <div class="col-sm-12">
                  <ButtonLoader
                    type="submit"
                    className="btn btn-primary btn-medium"
                  >
                    GET ACCESS TO OUR DEALS
                  </ButtonLoader>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    register: state.register,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkEmailExistence: (email) => checkEmail(dispatch, email),
});

export default reduxForm({ form: "BrokerRegisterForm" })(
  connect(mapStateToProps, mapDispatchToProps)(BrokerRegisterForm)
);
