import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { Field, Form, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { FormField } from "../../layout/FormField";
import ButtonLoader from "../../common/ButtonLoader";
import { requiredChecked } from "../../../utils/customValidators";
import { numberFormatter } from "../../../utils/constants";
import { renderEntity } from "../../common/CommonMethods";

class Allocations extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderIndividualName = (entity) => {
    let name = "";
    for (let key in entity.entity_individuals) {
      if (name.length > 0) {
        return (name +=
          " " +
          "&" +
          " " +
          entity.entity_individuals[key].given_name +
          " " +
          entity.entity_individuals[key].sur_name);
      }

      name +=
        entity.entity_individuals[key].given_name +
        " " +
        entity.entity_individuals[key].sur_name;
    }

    return name;
  };

  render() {
    const {
      allocations,
      entities,
      handleSubmit,
      onDeclineHandler,
      selectEntity,
      entity,
      splitAllocationSelect,
      split,
      handleSharesAmount,
    } = this.props;

    const entitiesOptions = entities.map((entity) => {
      if (entity.entity_company !== null)
        return {
          text: entity.entity_company.name,
          value: entity.id,
        };
      else if (entity.entity_trust !== null)
        return {
          text: entity.entity_trust.name,
          value: entity.id,
        };
      else if (entity.entity_individual !== null)
        return {
          text: this.renderIndividualName(entity),
          value: entity.id,
        };
    });
    console.log(allocations)
    return (
      <main className="page-signup page-create-account brocker-account ">
        <div className="container">
          {allocations && allocations.realestate_allocation && (
            <div class="form-normal form-create-account">
              <h2>Allocation</h2>
              <p>Allocation details go here</p>
              {/* 
              <p>
              Allocation D, <br />
                You have received the following allocation in{" "}
                {
                  allocations.realestate_allocation.property.manager
                    .company_name
                }{" "}
                <br />
                Please review and accept the allocation below.
              </p>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Offeror</Table.Cell>
                    <Table.Cell>
                      <strong>
                        {
                          allocations.realestate_allocation.property.manager
                            .company_name
                        }{" "}
                        <br />
                      </strong>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Structure</Table.Cell>
                    <Table.Cell>Property</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Securities</Table.Cell>
                    <Table.Cell>
                      Ordinary fully paid shares in{" "}
                      {
                        allocations.realestate_allocation.property.manager
                          .company_name
                      }{" "}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Entity</Table.Cell>
                    <Table.Cell>
                      {allocations.bid &&
                        allocations.bid.entity &&
                        renderEntity(allocations.bid.entity)}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      Number of securities allocated to you
                    </Table.Cell>
                    <Table.Cell>
                      {numberFormatter(allocations.allocation_amount)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <div className="allocation-details" style={{ marginBottom: 50 }}>
                <p>
                  We require you to acknowledge your allocation by{" "}
                  {allocations.realestate_allocation.acceptance_date}{" "}
                  {allocations.realestate_allocation.acceptance_time}. By doing
                  so, you acknowledge that you are required to acquire the above
                  securities accordance with the{" "}
                  <b>
                    <u>
                      <a
                        target="_blank"
                        href="https://180.instaging.net/investor/terms&conditions"
                      >
                        Investor Terms
                      </a>
                    </u>
                  </b>
                  , including any additional or varied terms specified by the
                  Broker in the{" "}
                  
                  . You are confirming that you have read and understood the
                  Investor Terms and agree to be bound by them.
                </p>

                <p>
                  By acknowledging your allocation you are also confirming that
                  you have read and understood the following additional
                  acknowledgements:
                  <ul style={{ padding: "12px 27px" }}>
                    <li style={{ listStyleType: "disc" }}>
                      No disclosure document has been lodged with ASIC.
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      Any other Additional Acknowledgements specified in the
                      Term Sheet.
                    </li>
                  </ul>
                </p>

                <p>
                  Any capitalised terms used but not defined have the meaning
                  given to them in the Investor Terms.
                </p>
              </div> */}
              {allocations && allocations.status === "Accepted" ? (
                <h3>
                  {/* This allocation was accepted on the {allocations.signed_date}. */}
                </h3>
              ) : (
                <Form
                  onSubmit={handleSubmit}
                  style={{
                    background: "transparent",
                    boxShadow: "none",
                    padding: 0,
                  }}
                >
                  {entities.length > 1 && (
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="field">
                          <Field
                            className="form-control"
                            onChange={selectEntity}
                            name="change_entity"
                            component={FormField}
                            type="switch"
                            id="change_entity"
                            label={<p>Change Entity</p>}
                            htmlFor="change_entity"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {entity && (
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="field">
                          <Field
                            className="form-control"
                            name="entity_id"
                            component={FormField}
                            type="select"
                            validate={[required()]}
                            options={entitiesOptions}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {entities.length > 1 && (
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="field">
                          <Field
                            className="form-control custom-control-input"
                            onChange={splitAllocationSelect}
                            name="split_allocation"
                            component={FormField}
                            type="switch"
                            id="split_allocation"
                            label={<p>Split Allocation</p>}
                            htmlFor="split_allocation"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {split && (
                    <div className="row">
                      {handleSharesAmount() && (
                        <div class="alert alert-danger col-sm-12" role="alert">
                          <p>
                            Total share amount must be equal to allocated amount{" "}
                            {allocations.realestate_allocation_amount}.
                          </p>
                        </div>
                      )}
                      <div className="col-sm-6"></div>
                      <div className="col-sm-6">
                        <p>Share Amount</p>
                      </div>
                      {entitiesOptions.map((entity) => {
                        return (
                          <>
                            <div className="col-sm-6 mt-2">
                              <div className="field">
                                <p>{entity.text}</p>
                              </div>
                            </div>
                            <div className="col-sm-6 mt-2">
                              <div className="field">
                                <Field
                                  className="form-control"
                                  name={`share_count-${entity.value}`}
                                  component={FormField}
                                  type="number"
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="field">
                        <Field
                          className="form-control"
                          name="status"
                          component={FormField}
                          type="checkbox"
                          label={<p>I accept the allocation.</p>}
                          requiredError="Please accept the allocation."
                          validate={[required(), requiredChecked()]}
                          htmlFor="status"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="button-group">
                      <div className="col-sm-6">
                        <ButtonLoader
                          type="submit"
                          className="btn btn-primary btn-medium"
                        >
                          Accept
                        </ButtonLoader>
                      </div>
                      <div className="col-sm-6">
                        {parseInt(
                          allocations.realestate_allocation.is_hot_deal
                        ) === 1 ? (
                          <ButtonLoader
                            style={{
                              backgroundColor: "white",
                              color: "red",
                              border: "1px red solid",
                            }}
                            type="button"
                            onClick={onDeclineHandler}
                            className="btn btn-outline-primary btn-medium "
                          >
                            Decline
                          </ButtonLoader>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </div>
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
});

export default reduxForm({ form: "Allocations", enableReinitialize: true })(
  connect(mapStateToProps)(Allocations)
);
