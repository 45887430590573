import MarketService from "../../services/marketService";

const actions = {
  FETCH_ALL_MARKETS: "FETCH_ALL_MARKETS",
  FETCH_ALL_MARKETS_ERROR: "FETCH_ALL_MARKETS_ERROR",
  FETCH_ALL_MARKETS_SUCCESS: "FETCH_ALL_MARKETS_SUCCESS",

  fetchMarkets: (dispatch) =>
    MarketService.getAll()
      .then(({ data }) => {
        dispatch({ type: actions.FETCH_ALL_MARKETS_SUCCESS, payload: data });
      })
      .catch(() => {}),
};
export default actions;
