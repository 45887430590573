import SuperFetch from './superfetch';

class MarketService {

  getAll = async formInputs => {
    return await SuperFetch.get('markets', formInputs).then(response => {
      return response;
    });
  };

}
export default new MarketService();