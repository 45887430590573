import React, { Component } from "react";
import InputFile from "./fields/InputFile";
import { connect } from "react-redux";
import { FormField } from "../layout/FormField";
import { required, email, length } from "redux-form-validators";
import { Field, reduxForm, Form } from "redux-form";
import ButtonLoader from "../common/ButtonLoader";
import { checkEmail } from "../../redux/actions/authActions";
import { matchPasswords } from "../../utils/customValidators";
import NewDropzone from "../common/NewDropzone";
import TermsModal from "../common/TermsModal";
import { Link } from "react-router-dom";
import { phoneNumber, minLength } from "../../utils/customValidators";

class BrokerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTerms: false,
    };
  }

  onBlurHandler = (e) => {
    let { checkEmailExistence } = this.props;
    checkEmailExistence(e.target.value);
  };

  openModal = () => {
    this.setState({
      showTerms: true,
    });
  };

  render() {
    let { handleSubmit, onLogoUpload, registerState, register } = this.props;
    return (
      <main className="page-main page-signup page-create-account">
        <div className="container">
          {registerState && registerState.register ? (
            <h3 style={{ textAlign: "center" }}>
              Thank you for your registration. We are currently reviewing your
              account. We will notify you by email when the account is approved
              and ready to upload deals.
            </h3>
          ) : (
            <div className="form-normal form-create-account">
              <h1>Create a {this.props.role} account</h1>
              <Form onSubmit={handleSubmit}>
                <div className="all-fields">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="field">
                        <label className="title">First Name</label>
                        <div className="field-input">
                          <Field
                            className="form-control entity__input"
                            name="first_name"
                            component={FormField}
                            type="text"
                            validate={[required()]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="field">
                        <label className="title">Last Name</label>
                        <div className="field-input">
                          <Field
                            className="form-control entity__input"
                            name="last_name"
                            component={FormField}
                            type="text"
                            validate={[required()]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="field">
                        <label className="title">Phone Number</label>
                        <div className="field-input">
                          <Field
                            className="form-control entity__input"
                            name="phone"
                            component={FormField}
                            type="number"
                            validate={[required()]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="field">
                        <label className="title">Email</label>
                        <div className="field-input">
                          <Field
                            className="form-control entity__input"
                            name="email"
                            component={FormField}
                            onChange={(e) => this.onBlurHandler(e)}
                            type="text"
                            validate={[required(), email()]}
                          />
                          <div className="form__field-error">
                            {register.emailError ? register.emailError : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="field">
                        <label className="title">Password</label>
                        <div className="field-input">
                          <Field
                            className="form-control entity__input"
                            name="password"
                            component={FormField}
                            type="password"
                            validate={[required(), length({ min: 6 })]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="field">
                        <label className="title">Confirm Password</label>
                        <div className="field-input">
                          <Field
                            className="form-control entity__input"
                            name="password_confirmation"
                            component={FormField}
                            type="password"
                            validate={[
                              required(),
                              matchPasswords(),
                              length({ min: 6 }),
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="field">
                        <label className="title">Company Name</label>
                        <div className="field-input">
                          <Field
                            className="form-control entity__input"
                            name="company_name"
                            component={FormField}
                            type="text"
                            validate={[required()]}
                          />
                        </div>
                      </div>
                      {this.props.role === "Broker" && (
                        <div className="field">
                          <label className="title">
                            AFSL or Authorised Representative Number
                          </label>
                          <div className="field-input">
                            <Field
                              className="form-control entity__input"
                              name="afsl"
                              component={FormField}
                              type="text"
                              validate={[required()]}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6">
                      <NewDropzone
                        multiple={false}
                        buttonLabel="Choose document"
                        title={"Upload Logo"}
                        accept="image/*"
                        // singleDocuments={this.props.wholeSaleDocument}
                        onUpload={this.props.onLogoUpload}
                        // onDocumentDeleteHandler={
                        //   this.props.onDocumentDeleteHandler
                        // }
                        // open={this.props.open}
                        // close={this.props.close}
                        // show={this.props.show}
                      />
                      {/* <InputFile onUpload={onLogoUpload} accept="image/*" /> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="field">
                        <Field
                          className="form-control entity__input"
                          name="terms_conditions"
                          component={FormField}
                          type="checkbox"
                          label={
                            <p>
                              I accept the{" "}
                              <Link
                                to={"/broker/terms&conditions"}
                                target="_blank"
                              >{`Terms & Conditions`}</Link>{" "}
                              and{" "}
                              <Link
                                to={"/privacy-policy"}
                                target="_blank"
                              >{`Privacy Policy`}</Link>
                            </p>
                          }
                          requiredError="Accept our terms and conditions"
                          validate={[required()]}
                          htmlFor="any"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="button-group">
                      <div className="col-xs-12 col-sm-6 col-md-6">
                        <ButtonLoader className="btn btn-primary btn-medium">
                          Create Account
                        </ButtonLoader>
                      </div>
                      {localStorage.getItem("auth_token") && (
                        <div className="col-xs-12 col-sm-6 col-md-6">
                          <button className="btn btn-outline-primary btn-medium">
                            Go to Main Page
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    register: state.register,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkEmailExistence: (email) => checkEmail(dispatch, email),
});

export default reduxForm({ form: "BrokerForm" })(
  connect(mapStateToProps, mapDispatchToProps)(BrokerForm)
);
