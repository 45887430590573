import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";

// import { Select } from "components/Select/Select";
// import "./FormField.scss";
const TextField = (props) => {
  const { meta = {} } = props;
  const inputProps = {
    type: props.type || "text",
    className: props.className,
    name: props.input.name,
    id: props.input.name,
    readOnly: props.readOnly,
    autoFocus: props.autoFocus,
    autoComplete: props.autoComplete,
    placeholder: props.placeholder,
    maxLength: props.maxLength,
    value: meta.uncontrolled ? undefined : props.input.value,
    defaultValue: meta.uncontrolled ? props.defaultValue : undefined,
    onChange: props.input.onChange,
    onKeyUp: props.onKeyUp,
    onBlur: props.onBlur,
    step: props.step || "",
    min: props.min || "",
  };

  let styles =
    meta.touched && meta.error
      ? {
          borderWidth: "3px",
          borderColor: "#f5c6cb",
          backgroundColor: "#f5c6cb",
        }
      : {};

  return (
    <React.Fragment>
      <div
        name={`position-${props.input.name}`}
        className={`position-${props.input.name}`}
      >
        <input
          style={styles}
          {...inputProps}
          {...props}
          onBlur={props.onBlurHandler}
        />
        {meta.touched && meta.error ? (
          <div className="form__field-error">{`This field  ${meta.error}`}</div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

const DatePickerField = (props) => {
  const {
    meta: { touched, error },
  } = props;
  const datefieldProps = {
    className: "form-control",
    name: props.input.name,
    id: props.input.name,
    maxDate: props.endDate,
    // value: props.input.value ? props.input.value : props.input.name,
    onChange: props.input.onChange,
    selected: props.input.value,
    dateFormat: "dd-MM-yyyy",
    autoComplete: "off",
  };
  return (
    <div>
      <DatePicker {...datefieldProps} />
      {touched && error ? (
        <div className="form__field-error">{`This field  ${error}`}</div>
      ) : null}
    </div>
  );
};

const MaskedPriceFormat = (props) => {
  const { meta = {} } = props;
  const inputProps = {
    className: props.className,
    name: props.input.name,
    id: props.input.name,
    readOnly: props.readOnly,
    autoFocus: props.autoFocus,
    autoComplete: props.autoComplete,
    placeholder: props.placeholder,
    maxLength: props.maxLength,
    value: meta.uncontrolled ? undefined : props.input.value,
    defaultValue: meta.uncontrolled ? props.defaultValue : undefined,
    // onChange: props.input.onChange,
    onKeyUp: props.onKeyUp,
    onBlur: props.onBlur,
    step: props.step || "",
    min: props.min || "",
  };

  return (
    <div>
      <NumberFormat
        {...inputProps}
        thousandSeparator={true}
        prefix={"$"}
        onValueChange={(values) => {
          const { formattedValue, value } = values;
          props.input.onChange(value);
        }}
      />
      {meta.touched && meta.error ? (
        <div className="form__field-error">{`${meta.error}`}</div>
      ) : null}
    </div>
  );
};

const CheckBox = (props) => {
  const { meta = {} } = props;

  const checboxProps = {
    type: "checkbox",
    className: "form__checkbox",
    name: props.input.name,
    id: props.input.name,
    value: props.input.value ? props.input.value : props.input.name,
    defaultChecked: meta.uncontrolled ? props.defaultChecked : undefined,
    onChange: props.input.onChange,
    checked: props.input.checked,
  };

  return (
    <React.Fragment>
      <input {...checboxProps} />
      <label
        className="form__checkbox-label ml-2"
        htmlFor={props.htmlFor || props.input.name}
      >
        {props.label}
      </label>
      {meta.touched && meta.error ? (
        <div className="form__field-error">
          {props.requiredError || `This field` + meta.error}
        </div>
      ) : null}
    </React.Fragment>
  );
};

const Radio = (props) => {
  return (
    <div className="col">
      <React.Fragment>
        <div>
          <input {...props.input} id={props.label} type={"radio"} />
          <label className="ml-2" htmlFor={props.label}>
            {props.label}
          </label>
          {/* {props.meta.touched && props.meta.error && (
            <div className="form__field-error">{props.meta.error}</div>
          )} */}
        </div>
      </React.Fragment>
    </div>
  );
};

const SelectField = ({
  input,
  meta: { touched, error },
  options,
  values,
  defaultOpen,
}) => {
  // input["value"] = ;
  return (
    <React.Fragment>
      <select {...input} placeholder="Please Select" className="form-control">
        <option value="">Select</option>
        {options.map((element) => (
          <option key={element.value} value={element.value}>
            {element.text}
          </option>
        ))}
      </select>
      {touched && error && (
        <div className="form__field-error">{`This field is ${error}`}</div>
      )}
    </React.Fragment>
  );
};

// renderField
const Textarea = ({ rows, className, input, meta: { touched, error } }) => (
  <div>
    <div>
      <textarea {...input} className={className} rows={rows} cols="40" />
      {touched && error && (
        <div className="form__field-error">{`This field is ${error}`}</div>
      )}
    </div>
  </div>
);

// renderField
const File = (input) => {
  return (
    <div>
      <label>{input.label}</label>
      <div>
        <input {...input} />
      </div>
    </div>
  );
};

// switchButton
const Switch = (props) => {
  const { meta = {} } = props;

  const checboxProps = {
    name: props.input.name,
    value: props.input.value ? props.input.value : props.input.name,
    onChange: props.input.onChange,
  };

  return (
    <React.Fragment>
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          id={props.id}
          {...checboxProps}
        />
        <label class="custom-control-label" htmlFor={props.id}>
          {props.label}
        </label>

        {meta.touched && meta.error ? (
          <div className="form__field-error">
            {props.requiredError || `This field` + meta.error}
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

const FormField = (props) => {
  switch (props.type) {
    case "checkbox":
      return <CheckBox {...props} />;
    case "select":
      return <SelectField {...props} />;
    case "textarea":
      return <Textarea {...props} />;
    case "radio":
      return <Radio {...props} />;
    case "switch":
      return <Switch {...props} />;
    case "input":
    default:
      return <TextField {...props} />;
  }
};

export { TextField, CheckBox, FormField, DatePickerField, MaskedPriceFormat };
