import React, { Fragment, Component } from "react";
import { Modal } from "react-bootstrap";
import EntitySingleForm from "./AddEntity/AddEntityContainer";
import Confirm from "../../common/Confirm";
import { withRouter, Link } from "react-router-dom";

class Entities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      heading: "Create Entity",
      create: true,
      uploaded: {
        statement: "",
      },
      open: false,
      entityId: "",
    };
  }

  open = (id) => {
    this.setState({ open: true, entityId: id });
  };

  close = () => this.setState({ open: false });

  uploadFile = (file) => {
    this.setState({
      uploaded: {
        statement: file,
      },
    });
  };

  createEntity = () => {
    this.setState({
      show: true,
      heading: "Create Entity",
      create: true,
    });
    this.props.fetchSingleEntity({});
  };

  handleSubmit = async (values) => {
    this.setState({
      show: true,
      heading: "Create Entity",
    });
    let { statement } = this.state.uploaded;
    if (statement) {
      values.statement = statement;
    }
    if (this.state.create) {
      await this.props.createEntity(values);
    } else {
      await this.props.updateEntity(values);
    }

    this.handleClose();
  };

  editEntity = (entity) => {
    this.setState({
      show: true,
      heading: "Edit Entity",
      create: false,
    });
    this.props.fetchSingleEntity(entity);
  };

  deleteEntity = (entity) => {
    this.props.deleteEntity({ id: this.state.entityId });
    this.setState({ open: false });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    const { entities, singleEntity } = this.props;
    const { show, heading } = this.state;
    return (
      <Fragment>
        <div className="row">
          <div class="col-md-6">
            <h3>Entities</h3>
          </div>
          <div class="col-md-6">
            <button
              type="button"
              className="btn btn-outline-info btn-sm mb-2 float-right entity__buttonAdd"
              onClick={() => this.props.history.push("/user/add-entity")}
            >
              Add New
            </button>
          </div>
        </div>

        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              {/* <th>Type</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {entities &&
              entities.map((entity, key) => {
                return (
                  <tr key={key}>
                    <td>
                      {entity.entity_type.id === 2 &&
                        entity.entity_company &&
                        entity.entity_company.name}
                      {entity.entity_type.id === 3 &&
                        entity.entity_trust &&
                        entity.entity_trust.name}
                      {entity.entity_type.id === 1 && "Individual / Joint"}
                    </td>
                    {/* <td>{entity.entity_type.type}</td> */}
                    <td className="text-right">
                      <div className="btn-group">
                        <Link
                          to={`/user/edit-entity/${entity.uuid}`}
                          className="btn entity__buttonEdit"
                        >
                          Edit
                        </Link>

                        <button
                          type="button"
                          className="btn btn-outline-warning btn-sm entity__buttonDelete"
                          onClick={() => this.open(entity.id)}
                        >
                          Delete
                        </button>
                        <Confirm
                          message="Are you sure you want to cancel this entity?"
                          show={this.state.open}
                          handleDelete={() => this.deleteEntity(entity.id)}
                          handleClose={this.close}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            {!entities && (
              <tr>
                <td colspan="3">No Entity Associated</td>
              </tr>
            )}
          </tbody>
        </table>

        <Modal show={show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EntitySingleForm
              initialValues={{ ...singleEntity }}
              onSubmit={this.handleSubmit}
              onUploadWholeSaleDocument={this.uploadFile}
            />
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

export default withRouter(Entities);
