import React, { Component } from "react";

import finalArt from "../../../assets/images/180.png";
import marketLogo from "../../../assets/images/180M-logo.png";
import marketLogoIcon from "../../../assets/images/180markets.png";
import HomeRegister from "../../../components/forms/Home/HomeRegisterContainer";
import { Link } from "react-router-dom";
import ClosedDeals from "./ClosedDeals/closedDeals";
import EaseImg from "../../../assets/images/Group 7841.png";
import FairImg from "../../../assets/images/Group 7843.png";
import AnalyseImg from "../../../assets/images/Group 7844.png";
import HomeImg from "../../../assets/images/circle2.png";
import HomeVideo from "../../../assets/videos/home.mp4";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.brokerRef = React.createRef();

    this.state = {
      selectedTab: "Investors",
    };
  }

  setSelectedTab = (selected) => {
    this.setState({
      selectedTab: selected,
    });
  };

  brokerRegisterHandler = (values) => {
    const { history } = this.props;
    let { uploaded } = this.state;
    if (uploaded) {
      values.logo = uploaded;
    }
    this.props.register({ ...values, role: "Broker" }, history);
  };
  render() {
    return (
      <main className="page-main home-page pt-5">
        <div className="container-fluid home_bg">
          <div className="row">
            <div className="col-lg-5 pl-0">
              <img
                src={HomeImg}
                className="img-fluid"
                style={{
                  marginTop: "-42px",
                }}
              />{" "}
            </div>
            <div className="col-lg-6 mt-5" id="investorBroker__register">
              <h3 className="hero_hedading">
                Easy, tailored access to Australian Property and Development
                opportunities is a click away
              </h3>
              <div className="nav-tab-bg">
                {/*<ul className="nav nav-tabs" id="myTab" role="tablist">*/}
                {/*  <li className="nav-item col p-0">*/}
                {/*    <a*/}
                {/*      className="nav-link active text-center"*/}
                {/*      id="home-tab"*/}
                {/*      data-toggle="tab"*/}
                {/*      href="#home"*/}
                {/*      role="tab"*/}
                {/*      aria-controls="home"*/}
                {/*      aria-selected="false"*/}
                {/*    >*/}
                {/*      INVESTORS*/}
                {/*    </a>*/}
                {/*  </li>*/}
                {/*  <li className="nav-item col p-0">*/}
                {/*    <a*/}
                {/*      className="nav-link text-center"*/}
                {/*      id="profile-tab"*/}
                {/*      data-toggle="tab"*/}
                {/*      href="#profile"*/}
                {/*      role="tab"*/}
                {/*      aria-controls="profile"*/}
                {/*      aria-selected="true"*/}
                {/*    >*/}
                {/*      BROKERS*/}
                {/*    </a>*/}
                {/*  </li>*/}
                {/*</ul>*/}
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <HomeRegister role={`Investor`} {...this.props} />
                  </div>
                  {/*<div*/}
                  {/*  className="tab-pane fade"*/}
                  {/*  id="profile"*/}
                  {/*  role="tabpanel"*/}
                  {/*  aria-labelledby="profile-tab"*/}
                  {/*>*/}
                  {/*  <HomeRegister role={`Broker`} {...this.props} />*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<div>*/}
        {/*  <div*/}
        {/*    className="container-fluid"*/}
        {/*    style={{*/}
        {/*      padding: "110px 0 0 0",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <h3 className="hero_hedading text-center pb-5">*/}
        {/*      Previous Placements*/}
        {/*    </h3>*/}
        {/*    <div*/}
        {/*      id="multi-item-example"*/}
        {/*      className="carousel slide carousel-multi-item"*/}
        {/*      data-ride="carousel"*/}
        {/*    >*/}
        {/*      <div className="closed-deals carousel-inner" role="listbox">*/}
        {/*        <div className="col-12 carousel-item active">*/}
        {/*          <div className="row">*/}
        {/*            <div*/}
        {/*              className="col-md-12"*/}
        {/*              style={{*/}
        {/*                padding: "130px 50px 130px 50px !important",*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              <ClosedDeals />*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div class="main_bg">
          <div className="container">
            <div className="nav-tab-bg how-it-works" id="how-it-works">
              <h3 className="hero_hedading text-center how_it_work">
                How It Works
              </h3>
              <div
                className="container-fluid"
                style={{
                  padding: "50px 0 0 0",
                }}
              >
                <div className="d-flex justify-content-center">
                  <video
                    src={HomeVideo}
                    width="800"
                    height="500"
                    controls="controls"
                    autoplay={false}
                  />
                </div>
              </div>

              {/*<ul className="nav nav-tabs" id="myTab" role="tablist">*/}
              {/*  <li className="nav-item col p-0">*/}
              {/*    <a*/}
              {/*      className="nav-link active text-center"*/}
              {/*      id="home-tab"*/}
              {/*      data-toggle="tab"*/}
              {/*      href="#investors"*/}
              {/*      role="tab"*/}
              {/*      aria-controls="home"*/}
              {/*      aria-selected="true"*/}
              {/*    >*/}
              {/*      <span className="tab__transition">INVESTORS</span>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*  <li className="nav-item col p-0">*/}
              {/*    <a*/}
              {/*      className="nav-link text-center"*/}
              {/*      id="profile-tab"*/}
              {/*      data-toggle="tab"*/}
              {/*      href="#brokers"*/}
              {/*      role="tab"*/}
              {/*      aria-controls="profile"*/}
              {/*      aria-selected="false"*/}
              {/*    >*/}
              {/*      <span className="tab__transition">BROKERS</span>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*  <li className="nav-item col p-0">*/}
              {/*    <a*/}
              {/*      className="nav-link text-center"*/}
              {/*      id="companies-tab"*/}
              {/*      data-toggle="tab"*/}
              {/*      href="#companies"*/}
              {/*      role="tab"*/}
              {/*      aria-controls="profile"*/}
              {/*      aria-selected="false"*/}
              {/*    >*/}
              {/*      <span className="tab__transition">LISTED COMPANIES</span>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*</ul>*/}

              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="investors"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div
                    className="row"
                    style={{
                      marginTop: "15px",
                    }}
                  >
                    <div className="col-lg-5 offset-1 padd bgtext">
                      <h3 className="hiw_heading">
                        <a
                          href="/auth/register/investor"
                          style={{ color: "inherit" }}
                        >
                          1. Create an Account
                        </a>
                      </h3>
                      <p className="hiw_p">
                        Upload one ID document and a proof of Sophisticated
                        Investor form from your accountant.
                      </p>
                    </div>
                    <div className="col-lg-5 offset-1 padd bgtext_2">
                      <h3 className="hiw_heading">2. Verify</h3>
                      <p className="hiw_p">
                        We will verify your account within MINUTES and you can
                        start investing.
                      </p>
                    </div>
                  </div>
                </div>
                {/*<div*/}
                {/*  className="tab-pane fade"*/}
                {/*  id="brokers"*/}
                {/*  role="tabpanel"*/}
                {/*  aria-labelledby="profile-tab"*/}
                {/*>*/}
                {/*  <div className="row">*/}
                {/*    <div className="col-lg-3 offset-1 padd bgtext">*/}
                {/*      <h3 className="hiw_heading"><a href="/auth/register/broker" style={{color: 'inherit'}}>1. Create an Account</a></h3>*/}
                {/*      <p className="hiw_p">Easy and quick account creation.</p>*/}
                {/*    </div>*/}
                {/*    <div className="col-lg-3 offset-1 padd bgtext_2">*/}
                {/*      <h3 className="hiw_heading">2. Verify</h3>*/}
                {/*      <p className="hiw_p">*/}
                {/*        We will verify your account within MINUTES!*/}
                {/*      </p>*/}
                {/*    </div>*/}
                {/*    <div className="col-lg-3 offset-1 padd bgtext_3">*/}
                {/*      <h3 className="hiw_heading">3. Upload Investments</h3>*/}
                {/*      <p className="hiw_p">*/}
                {/*        Upload your Capital Raises in a few minutes and see live*/}
                {/*        bids from our Investors on your Portal.*/}
                {/*      </p>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  className="tab-pane fade"*/}
                {/*  id="companies"*/}
                {/*  role="tabpanel"*/}
                {/*  aria-labelledby="companies-tab"*/}
                {/*>*/}
                {/*  <div className="row">*/}
                {/*    <div className="col-lg-3 offset-1 padd bgtext">*/}
                {/*      <h3 className="hiw_heading">1. 180 Advantage</h3>*/}
                {/*      <p className="hiw_p">*/}
                {/*        Raise money cheaper and faster with 180 Markets.*/}
                {/*      </p>*/}
                {/*    </div>*/}
                {/*    <div className="col-lg-3 offset-1 padd bgtext_2">*/}
                {/*      <h3 className="hiw_heading">2. Access</h3>*/}
                {/*      <p className="hiw_p">*/}
                {/*        As long as your company is listed on the ASX our*/}
                {/*        Investors will have access to your Company.*/}
                {/*      </p>*/}
                {/*    </div>*/}
                {/*    <div className="col-lg-3 offset-1 padd bgtext_3">*/}
                {/*      <h3 className="hiw_heading">3. Upload Information</h3>*/}
                {/*      <p className="hiw_p">*/}
                {/*        Upload a term sheet and some basic company information and watch*/}
                {/*        the bids come in!*/}
                {/*      </p>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          {/*<div className="container-fluid">*/}
          {/*  <div className="row mt-12em">*/}
          {/*    <div className="col-lg-4 padd_new new__padding">*/}
          {/*      <img src={EaseImg} className="logo__img" />*/}
          {/*      <h3 className="heading_new">Ease of one account</h3>*/}
          {/*      <p className="p_txt">*/}
          {/*        Text required....*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*    <div className="col-lg-4 padd_new  new__padding">*/}
          {/*      <img src={FairImg} className="logo__img" />*/}
          {/*      <h3 className="heading_new">Fairer allocations</h3>*/}
          {/*      <p className="p_txt">*/}
          {/*        Text required....*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*    <div className="col-lg-4 padd_new new__padding">*/}
          {/*      <img src={AnalyseImg} className="logo__img" />*/}
          {/*      <h3 className="heading_new">In depth analysis</h3>*/}
          {/*      <p className="p_txt">*/}
          {/*        Text required....*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </main>
    );
  }
}
