import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class ThankUMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { type } = this.props.match.params;

    return (
      <div>
        <main className="page-main page-signup page-create-account">
          <div className="container">
            <div class="form-normal form-create-account">
              <div style={{ textAlign: "center" }}>
                {type === "accept" ? (
                  <h3>Thank you for accepting the allocation.</h3>
                ) : (
                  type === "decline" && (
                    <h3>Your allocation has been cancelled.</h3>
                  )
                )}
                <Link to="/user/deals#confirmation">Back to Allocations</Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default withRouter(ThankUMessage);
