import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Dashboard extends Component {
  componentDidMount() {}

  render() {
    return (
      <main className="page-main page-signup page-create-account brocker-account ">
        <div className="container">
          <div>Dashboard Page</div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
});

export default connect(mapStateToProps, null)(withRouter(Dashboard));
