import React, { Component } from "react";
import { reduxForm, Field, Form } from "redux-form";
import { connect } from "react-redux";
import { FormField, DatePickerField } from "../../../layout/FormField";
import NewDropzone from "../../../common/NewDropzone";
import ButtonLoader from "../../../common/ButtonLoader";
import { required } from "redux-form-validators";
import DraftComponent from "../../../common/DraftComponent";
import { scrollToFirstError } from "../../../../utils/scroller";

const brokerOptions = ["Yes", "No"];

class CreateIpoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let {
      handleSubmit,
      handleUpload,
      paramsID,
      form,
      editorValue,
      getHelpfulLinks,
      title,
    } = this.props;
    return (
      <main className="page-ipos page-add-new-deal">
        <div className="container">
          <div className="form-normal">
            <h1>{title}</h1>
            <Form onSubmit={handleSubmit}>
              <div className="all-fields">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Company Name</label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="company_name"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="title">ASX Code</label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="asx_code"
                          component={FormField}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <NewDropzone
                      multiple={false}
                      buttonLabel="Upload IPO logo"
                      title={"Upload IPO logo"}
                      accept="image/*"
                      onUpload={(file) => handleUpload(file)}
                      singleDocuments={
                        this.props.initialValues &&
                        this.props.initialValues.logo && [
                          this.props.initialValues.logo,
                        ]
                      }
                      onDocumentDeleteHandler={
                        this.props.onDocumentDeleteHandler
                      }
                      open={this.props.open}
                      close={this.props.close}
                      show={this.props.show}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-md-6">
                    <div className="field">
                      <label
                        // className="title is-tooltip"
                        // data-tooltip="Add your tooltip here"
                        // data-position="right center"
                        className="title"
                      >
                        IPO Price
                      </label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="ipo_price"
                          component={FormField}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <div className="field">
                      <label
                        className="title"
                        // className="title is-tooltip"
                        // data-tooltip="Add your tooltip here"
                        // data-position="right center"
                      >
                        Industry
                      </label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="industry"
                          component={FormField}
                          type="text"
                          step="0.01"
                          min="0"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <div className="field">
                      <label className="title">Raise Amount </label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="raise_amount"
                          component={FormField}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <div className="field">
                      <label className="title">Free Option </label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="free_options"
                          component={FormField}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row hl-margin">
                  <div className="col-sm-6">
                    <div className="field">
                      <label
                        className="title"
                        // className="title is-tooltip"
                        // data-tooltip="Add your tooltip here"
                        // data-position="right center"
                      >
                        Listing Date
                      </label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="listing_date"
                          component={DatePickerField}
                          type="text"
                          placeholder="DD/MM/YYYY"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <div className="field">
                      <label className="title">Market Capitalisation</label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="market_cap"
                          component={FormField}
                          type="text"
                          step="0.01"
                          min="0"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Bids Due Date</label>
                      <div className="field-input">
                        <Field
                          className="form-control"
                          name="bids_due"
                          component={DatePickerField}
                          type="text"
                          placeholder="DD/MM/YYYY"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="field">
                      <label className="title">Helpful Links</label>
                      <div className="field-input">
                        <DraftComponent
                          initialValue={
                            this.props.initialValues &&
                            this.props.initialValues.helpful_links
                          }
                          getValue={getHelpfulLinks}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="field">
                      <label className="title">Summary of Deal</label>
                      <div className="field-input">
                        <DraftComponent
                          initialValue={
                            this.props.initialValues &&
                            this.props.initialValues.details
                          }
                          getValue={editorValue}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <NewDropzone
                      multiple={false}
                      buttonLabel="Choose document"
                      title={"Upload Term sheet"}
                      onUpload={this.props.handleMultipleFilesTermSheet}
                      singleDocuments={
                        this.props.initialValues &&
                        this.props.initialValues.term_sheet && [
                          this.props.initialValues.term_sheet,
                        ]
                      }
                      onDocumentDeleteHandler={
                        this.props.onDocumentDeleteHandler
                      }
                      open={this.props.open}
                      close={this.props.close}
                      show={this.props.show}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <NewDropzone
                      multiple={false}
                      buttonLabel="Choose document"
                      title={"Prospectus"}
                      onUpload={this.props.prospectusUploadHandler}
                      singleDocuments={
                        this.props.initialValues &&
                        this.props.initialValues.prospectus && [
                          this.props.initialValues.prospectus,
                        ]
                      }
                      onDocumentDeleteHandler={
                        this.props.onDocumentDeleteHandler
                      }
                      open={this.props.open}
                      close={this.props.close}
                      show={this.props.show}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <NewDropzone
                      multiple={false}
                      buttonLabel="Choose document"
                      title={"Presentation"}
                      onUpload={this.props.presentationUploadHandler}
                      singleDocuments={
                        this.props.initialValues &&
                        this.props.initialValues.presentation && [
                          this.props.initialValues.presentation,
                        ]
                      }
                      onDocumentDeleteHandler={
                        this.props.onDocumentDeleteHandler
                      }
                      open={this.props.open}
                      close={this.props.close}
                      show={this.props.show}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-8">
                    <div className="field">
                      <label className="title">Are you the lead broker?</label>
                      <div className="field-input form-group row">
                        {brokerOptions.map((element, key) => {
                          return (
                            <Field
                              key={key}
                              name="lead_broker"
                              component={FormField}
                              type="radio"
                              value={element}
                              label={element}
                              className={`radio-inline`}
                            />
                          );
                        })}
                      </div>
                      <p style={{ fontSize: "12px", color: "red " }}>
                        {this.state.validate &&
                          form &&
                          form.CreateDealForm &&
                          form.CreateDealForm.syncErrors &&
                          form.CreateDealForm.syncErrors.is_lead_broker &&
                          `This field is ${form.CreateDealForm.syncErrors.is_lead_broker}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="button-group w-small">
                  <div className="col-xs-12 col-sm-6 col-md-6">
                    <ButtonLoader
                      className="btn btn-primary btn-medium"
                      onClick={this.validate}
                    >
                      {paramsID ? "Update" : "Save"}
                    </ButtonLoader>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6">
                    <button
                      type="button"
                      onClick={() => this.props.history.push("/user/deals")}
                      className="btn btn-outline-primary btn-medium"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form,
  };
};

export default reduxForm({
  form: "CreateIpoForm",
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(connect(mapStateToProps, null)(CreateIpoForm));
