import React, { Component } from "react";
import { FileUpload } from "../../common/Graphics";
import IpoForm from "./IpoForm/IpoForm";
import { connect } from "react-redux";
import IpoActions from "../../../redux/actions/ipoActions";
import { withRouter } from "react-router-dom";
import { BaseUrl } from "../../../services/settings";
import ModalComponent from "../../common/Modal";
import moment from "moment";
import EntityActions from "../../../redux/actions/entityActions";

class EditIpoContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectEntityOptions: [],
      open: false,
      openLightBox: false,
      modalOpen: false,
      file: "",
    };
  }

  openLightBox = (file) => {
    this.setState({ openLightBox: true, file });
  };

  closeLightBox = () => {
    this.setState({ openLightBox: false });
  };

  modalOpen = () => {
    this.setState({ modalOpen: true });
  };

  modalClose = () => {
    this.setState({ modalOpen: false });
  };

  componentDidMount = () => {
    let { match, fetchSelectedIpoData, fetchAllEntities } = this.props;
    fetchAllEntities();
    fetchSelectedIpoData(match.params.uuid);
  };

  renderIndividualName = (entity) => {
    let name = "";
    for (let key in entity.entity_individuals) {
      if (name.length > 0) {
        return (name +=
          " " +
          "&" +
          " " +
          entity.entity_individuals[key].given_name +
          " " +
          entity.entity_individuals[key].sur_name);
      }

      name +=
        entity.entity_individuals[key].given_name +
        " " +
        entity.entity_individuals[key].sur_name;
    }

    return name;
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.existingEntity !== nextProps.existingEntity &&
      nextProps.currentUser.id
    ) {
      this.setState({
        selectEntityOptions:
          nextProps.existingEntity &&
          nextProps.existingEntity.map((entity) => {
            if (entity.entity_company !== null)
              return {
                value: entity.id,
                text: entity.entity_company.name,
                value: entity.id,
              };
            else if (entity.entity_trust !== null)
              return {
                value: entity.id,
                text: entity.entity_trust.name,
                value: entity.id,
              };
            else if (entity.entity_individual !== null)
              return {
                value: entity.id,
                text: this.renderIndividualName(entity),
                value: entity.id,
              };
          }),
      });
    }
  };

  cancelBidHanler = (id) => {
    this.setState({ open: false });
    let { cancelBid, history } = this.props;
    cancelBid({ bidId: id }, history);
  };

  editBidHandler = (id) => {
    this.props.history.push(`/user/edit-bid/${id}`);
  };

  onSubmitBid = async (values) => {
    let { updateIpoIndication, updateIpoBid, ipo, history } = this.props;

    if (ipo.myindication) {
      updateIpoIndication(
        { ...values, id: ipo.id, inidicationUuid: ipo.myindication.uuid },
        history
      );
    } else if (ipo.mybid) {
      updateIpoBid({ ...values, id: ipo.id, bidId: ipo.mybid.uuid }, history);
    }
  };

  render() {
    let { ipo, currentUser, bidStatus } = this.props;
    return (
      <main className="page-deal-add-doc">
        <div className="md-top-deal">
          <div className="container">
            <div className="deal-inner">
              <div className="row">
                <div className="col-md-2">
                  <div className="logo">
                    <div className="inner">
                      <img
                        src={
                          ipo &&
                          ipo.logo &&
                          `${BaseUrl}/storage/${ipo.logo.id}/${ipo.logo.file_name}`
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="information">
                    <h1>{ipo && ipo.company_name}</h1>
                    <p className="sub-title">
                      ASX:
                      {ipo && ipo.asx_code && ipo.asx_code}
                    </p>
                    <div className="detail">
                      <div className="row">
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">IPO price</p>
                            <p className="number">
                              {ipo && ipo.ipo_price && `$${ipo.ipo_price}`}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Raise Amount</p>
                            <p className="number">
                              {ipo &&
                                ipo.raise_amount &&
                                `$${ipo.raise_amount}`}
                            </p>
                          </div>
                        </div>
                        {/* <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Discount </p>
                            <p className="number">
                              {ipo && ipo.discount ? ipo.discount + " %" : ""}
                            </p>
                          </div>
                        </div> */}
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Market Capitalisation</p>
                            <p className="price">
                              {ipo && ipo.market_cap && `$${ipo.market_cap}`}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Free Options</p>
                            <p className="price">
                              {ipo && ipo.free_options && ipo.free_options}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md-tabs tab-deal-add-doc">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <p>
                  <b>Listing Date: </b>{" "}
                  {moment(ipo.listing_date, "DD/MM/YYYY").format(
                    "MMM. DD, YYYY"
                  )}
                </p>

                <p>
                  <b>Industry: </b> {ipo && ipo.industry && `${ipo.industry}`}
                </p>

                <div className="row mt-5">
                  {ipo && ipo.presentation && (
                    <div className="col-md-12 mb-4">
                      <div className="smd-file-detail clearfix">
                        <div className="file-image">
                          <img src={FileUpload} alt="" width={42} />
                        </div>
                        <div className="file-meta">
                          <span className="name">Company Presentation</span>
                          <div className="view clearfix">
                            <a
                              target="_blank"
                              href={`${BaseUrl}storage/${ipo.presentation.id}/${ipo.presentation.file_name}`}
                            >
                              View Document
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.props.ipo && (
                    <div className="col-md-12 mb-4">
                      {ipo && ipo.term_sheet && (
                        <div className="smd-file-detail clearfix">
                          <div className="file-image">
                            <img src={FileUpload} alt="" width={42} />
                          </div>
                          <div className="file-meta">
                            <span className="name">Term Sheet</span>
                            <div className="view clearfix">
                              <a
                                target="_blank"
                                href={`${BaseUrl}storage/${ipo.term_sheet.id}/${ipo.term_sheet.file_name}`}
                              >
                                View document
                              </a>
                            </div>
                            <ModalComponent
                              fileName={ipo.term_sheet.file_name}
                              modalOpen={this.state.modalOpen}
                              handleClose={this.modalClose}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {ipo && ipo.prospectus && (
                    <div className="col-md-12 mb-4">
                      <div className="smd-file-detail clearfix">
                        <div className="file-image">
                          <img src={FileUpload} alt="" width={42} />
                        </div>
                        <div className="file-meta">
                          <span className="name">Prospectus</span>
                          <div className="view clearfix">
                            <a
                              target="_blank"
                              href={`${BaseUrl}storage/${ipo.prospectus.id}/${ipo.prospectus.file_name}`}
                            >
                              View Document
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <p style={{ marginTop: 10 }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: ipo && ipo.details,
                    }}
                  />
                  <b>Helpful Links: </b>{" "}
                  {ipo && ipo.helpful_links && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ipo.helpful_links,
                      }}
                    />
                  )}
                </p>
                <p style={{ marginTop: 30 }}>
                  {ipo && ipo.summary && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ipo.summary,
                      }}
                    />
                  )}
                  <br />
                  {ipo && ipo.type === "180" && (
                    <i>
                      180 Markets PTY LTD  the right to close this deal early.
                      <br />
                      180 Markets PTY LTD  or its associated entities may invest
                      in this deal.
                    </i>
                  )}
                </p>
              </div>
              <div className="col-md-5">
                {currentUser &&
                  currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.realestate_approved === "Approved" &&
                  ipo &&
                  ipo.type === "market" &&
                  ipo.myindication && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <IpoForm
                          currentUser={currentUser}
                          onSubmit={this.onSubmitBid}
                          selectEntityOptions={this.state.selectEntityOptions}
                          capRaisePrice={ipo && ipo.capital_raise}
                          currentDeal={ipo}
                          ipo={ipo}
                          bidStatus={bidStatus}
                          initialValues={
                            ipo && ipo.type === "market" && ipo.myindication
                              ? {
                                  investment_amount:
                                    ipo.myindication.investment_amount,
                                }
                              : {
                                  investment_amount:
                                    ipo.mybid.investment_amount,
                                  share_count: ipo.mybid.share_count,
                                  entity_id: ipo.mybid.entity_id,
                                }
                          }
                        />
                      </div>
                    </div>
                  )}
                {currentUser &&
                  currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.realestate_approved === "Approved" &&
                  ipo &&
                  ipo.type === "180" &&
                  ipo.mybid && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <IpoForm
                          currentUser={currentUser}
                          onSubmit={this.onSubmitBid}
                          selectEntityOptions={this.state.selectEntityOptions}
                          capRaisePrice={ipo && ipo.capital_raise}
                          currentDeal={ipo}
                          ipo={ipo}
                          bidStatus={bidStatus}
                          initialValues={
                            ipo && ipo.type === "market" && ipo.myindication
                              ? {
                                  investment_amount:
                                    ipo.myindication.investment_amount,
                                }
                              : {
                                  investment_amount:
                                    ipo.mybid.investment_amount,
                                  share_count: ipo.mybid.share_count,
                                  entity_id: ipo.mybid.entity_id,
                                }
                          }
                        />
                      </div>
                    </div>
                  )}
                {currentUser &&
                  currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.realestate_approved === "Approved" &&
                  bidStatus &&
                  bidStatus.myBid && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <button
                          type="button"
                          // className="btn btn-outline-primary btn-medium"
                          className="btn btn-outline-primary btn-medium btn-block"
                          onClick={() => this.editBidHandler(bidStatus.uuid)}
                        >
                          Change Bid
                        </button>
                        {/* <button
                      type="button"
                      className="btn btn-outline-warning btn-medium"
                      onClick={() => this.setState({ open: true })}
                    >
                      Cancel Bid
                    </button>
                    <Confirm
                      show={this.state.open}
                      handleDelete={() =>
                        this.cancelBidHanler(bidStatus.myBid.uuid)
                      }
                      handleClose={() => {
                        this.setState({ open: false });
                      }}
                    /> */}
                      </div>
                    </div>
                  )}

                {/* {currentUser &&
                  currentUser.role &&
                  currentUser.role.some(role => role.title === "Investor") &&
                  currentUser.realestate_approved === "Approved" &&
                  ipo &&
                  ipo.myindication && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-medium btn-block"
                          onClick={() =>
                            this.editBidHandler(ipo.myindication.uuid)
                          }
                        >
                          Change Indication
                        </button>
                      </div>
                    </div>
                  )} */}

                {currentUser &&
                  currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.realestate_approved === "Pending" && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div className="deal-form-content">
                          <p className="bidMessage">
                            Complete your profile to bid for deals
                          </p>
                          {/*<button*/}
                          {/*  className="btn btn-outline-primary btn-medium"*/}
                          {/*  onClick={() => this.props.history.push("/user/profile")}*/}
                          {/*>*/}
                          {/*  Profile*/}
                          {/*</button>*/}
                        </div>
                      </div>
                    </div>
                  )}
                {currentUser &&
                  currentUser.role &&
                  currentUser.role.some(
                    (role) => role.title !== "Investor"
                  ) && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div className="deal-form-content">
                          <p className="bidMessage">
                            Register as an investor to bid for deal
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                {!currentUser ||
                  (!localStorage.getItem("auth_token") && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div
                          className="deal-form-content"
                          style={{ textAlign: "center" }}
                        >
                          <p className="bidMessage">Login to bid for deals</p>
                          <div className="row" style={{ padding: "20px 20px" }}>
                            <div className="col-sm-12">
                              <button
                                className="btn btn-primary btn-medium btn-block"
                                onClick={() =>
                                  this.props.history.push("/auth/login")
                                }
                              >
                                Login
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ipo: state.ipos.selectedIpo,
    currentUser: state.auth.currentUser,
    existingEntity: state.entities.entities,
  };
};

const { fetchSelectedIpo, editIpoIndication, editIpoBid } = IpoActions;
const { fetchEntities } = EntityActions;

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllEntities: (payload, history) =>
      fetchEntities({ dispatch, payload, history }),
    fetchSelectedIpoData: (uuid, history) => fetchSelectedIpo(uuid, dispatch),
    updateIpoIndication: (payload, history) =>
      editIpoIndication(payload, history, dispatch),
    updateIpoBid: (payload, history) => editIpoBid(payload, history, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withRouter(EditIpoContainer));
