import React, { Component, Fragment } from "react";
import { Icon } from "antd";
import { connect } from "react-redux";

class ButtonLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { loading } = this.props;
    return (
      <Fragment>
        {loading ? (
          <button {...this.props} className={this.props.className} disabled>
            <Icon type="loading" /> {this.props.children}
          </button>
        ) : (
          <button {...this.props}>{this.props.children}</button>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading.buttonLoading,
  };
};

export default connect(mapStateToProps, null)(ButtonLoader);
