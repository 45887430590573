import React, { Component, Fragment } from "react";
import { Tabs, Modal, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import DealActions from "../../../../redux/actions/dealActions";
import IpoActions from "../../../../redux/actions/ipoActions";
import EntityActions from "../../../../redux/actions/entityActions";
import BidActions from "../../../../redux/actions/bids.Actions";
import CurrentBid from "./InvestorDeals/CurrentBid";
import Confirmation from "./InvestorDeals/Confirmation";
import Deals180 from "./PublicDeals/deals_180";
import BidNowModal from "../../../common/BidNowModal";
import BidIpoModal from "../../../common/BidIpoModal";
import Loader from "../../../Loader/Loader";
import { showRefreshButton } from "../../../../utils/constants";
import { BaseUrl } from "../../../../services/settings";
import ClosedDeals from "./ClosedDeals/ClosedDeals";
import Logo from "../../../../assets/images/180M-logo.png";
import RealestateLogo from "../../../../assets/images/180R-logo.png";


const { TabPane } = Tabs;

const dealAccessDenialMessage = `Please complete your profile to get approved.
  Only approved investors can access deal information.`;

class DealsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      selectEntityOptions: [],
      showEditForm: false,
      showEditIpoForm: false,
      showIpo: false,
      activeTab: "1",
      reload: false,
    };
  }

  onBidNow = (id) => {
    const { getDealStatus, currentUser, history } = this.props;
    const isEnitityExist =
      currentUser.realestate_approved === "Approved" ? true : false;
    if (isEnitityExist) {
      getDealStatus(id);
      this.setState((prevState) => {
        return { show: !prevState.show };
      });
    } else {
      Modal.confirm({
        width: 600,
        okButtonProps: {
          type: "primary",
          size: "large",
        },
        cancelButtonProps: {
          size: "large",
        },

        content: <p className="pconfirm">{dealAccessDenialMessage}</p>,
        okText: "My Profile",
        cancelText: "Cancel",
        onOk() {
          history.push("/user/profile");
        },
        onCancel() {},
      });
    }
  };

  onBidIpoNow = (id) => {
    const { fetchSelectedIpoData, currentUser, history } = this.props;
    const isEnitityExist =
      currentUser.realestate_approved === "Approved" ? true : false;
    if (isEnitityExist) {
      fetchSelectedIpoData(id);
      this.setState((prevState) => {
        return { showIpo: !prevState.showIpo };
      });
    } else {
      Modal.confirm({
        width: 600,
        okButtonProps: {
          type: "primary",
          size: "large",
        },
        cancelButtonProps: {
          size: "large",
        },

        content: <p className="pconfirm">{dealAccessDenialMessage}</p>,
        okText: "My Profile",
        cancelText: "Cancel",
        onOk() {
          history.push("/user/profile");
        },
        onCancel() {},
      });
    }
  };

  handleCancelIpo = () => {
    this.setState({ showIpo: false, showEditIpoForm: false });
  };

  handleCancel = () => {
    this.setState({ show: false, showEditForm: false });
  };

  onSubmitBid = async (values) => {
    let {
      createBidSubmit,
      currentDeal,
      updateBidSubmit,
      history,
    } = this.props;

    if (!currentDeal.myBid) {
      this.setState({ show: false });
      Modal.confirm({
        title: "Confirm",
        width: 700,
        okButtonProps: {
          type: "primary",
          size: "large",
        },
        cancelButtonProps: {
          size: "large",
        },
        icon: <ExclamationCircleOutlined />,
        content: (
          <p className="pconfirm">
            {" "}
            By submitting a bid, you are making a binding and irrevocable offer
            to acquire the securities, on the terms set out in the information
            available here, in the{" "}
            {currentDeal && currentDeal.term_sheets ? (
              <a
                target="_blank"
                href={`${BaseUrl}storage/${currentDeal.term_sheets.id}/${currentDeal.term_sheets.file_name}`}
              >
                term sheet
              </a>
            ) : (
              "term sheet"
            )}{" "}
            and in the Investor Terms{" "}
            {
              <a
                target="_blank"
                href="https://180.instaging.net/investor/terms&conditions"
              >
                Investor Terms
              </a>
            }
            . You confirm you make each of the representations and warranties
            specified in the Investor Terms.Your bid does not guarantee that you
            will receive an allocation. If your bid is successful, you will be
            required to pay to acquire the securities in accordance with your
            bid and the Investor Terms, subject to any scale back.
          </p>
        ),
        okText: "Confirm",
        cancelText: "Cancel",
        async onOk() {
          await createBidSubmit(
            { ...values, property_id: currentDeal.id },
            history
          );
        },
        onCancel() {},
      });
    } else {
      await updateBidSubmit(
        {
          ...values,
          property_id: currentDeal.id,
          bidId: currentDeal.myBid.uuid,
        },
        history
      );
      this.setState({ show: false });
    }
  };

  componentDidMount = () => {
    this.fetchAllInitialData();
  };

  fetchAllInitialData = () => {
    const {
      fetchAllEntities,
      fetchAllDEALS,
      fetchAllPublic180Deals,
      getAllAllocationsData,
      getCurrentBids,
    } = this.props;

    fetchAllEntities();
    fetchAllDEALS();
    getCurrentBids();
    fetchAllPublic180Deals();
    getAllAllocationsData();
  };

  onReload = () => {
    this.setState({ reload: true });
    this.fetchAllInitialData();
    setTimeout(() => {
      this.setState({ reload: false });
    }, 500);
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.existingEntity !== nextProps.existingEntity &&
      nextProps.currentUser &&
      nextProps.currentUser.id
    ) {
      this.setState({
        selectEntityOptions:
          nextProps.existingEntity &&
          nextProps.existingEntity.map((entity) => {
            if (entity.entity_company !== null)
              return {
                value: entity.id,
                text: entity.entity_company.name,
              };
            else if (entity.entity_trust !== null)
              return {
                value: entity.id,
                text: entity.entity_trust.name,
              };
            else if (entity.entity_individual !== null)
              return {
                value: entity.id,
                text: "Individual / Joint",
              };
          }),
      });
    }
  };

  editBidHandler = (uuid) => {
    this.setState((prevState) => {
      return {
        showEditForm: !prevState.showEditForm,
      };
    });
  };

  editIpoBidHandler = (uuid) => {
    this.setState((prevState) => {
      return {
        showEditIpoForm: !prevState.showEditIpoForm,
      };
    });
  };

  callback = (key) => {
    this.setState({ activeTab: key });
  };

  render() {
    const {
      currentUser,
      allocations,
      allIpos,
      currentDeal,
      ipo,
      getAllIposData,
    } = this.props;

    let isInvestor = currentUser.role.some((role) => role.title === "Investor");
    const isEnitityExist =
      currentUser.realestate_approved === "Approved" ? true : false;

    return (
      <Fragment>
        <main className="page-main page-deals page-all-deals">
          <Loader>
            <div className="container">
              <div className="page-deals page-all-deals">
                <div className="title">
                  <div className="row">
                    <div className="col-sm-6">


                      <h5>Switch To:</h5>
                      <div className="logo mb-4">
                        <a href="https://180markets.com.au" class=" mt-1">
                          <img
                              src={Logo}
                              alt="Home"
                              className="switch"
                              height="20"
                          />
                        </a>
                      </div>
                      {/* <div className="logo mt-4 mb-4">
                        <a href="https://180realestate.com.au"  class="mt-4 ">
                          <img
                            src={RealestateLogo}
                            alt="Home"
                            className="logo"
                          />
                        </a>
                      </div> */}


                      <h1>
                        {isInvestor ? "All Deals" : "Your Deals"}{" "}
                        {/* <ReloadOutlined
                            style={{ fontSize: "20px" }}
                            onClick={this.onReload}
                            spin={this.state.reload}
                          /> */}
                      </h1>
                      {/* <Button
                        type="primary"
                        size="small"
                        onClick={() =>
                          this.props.history.push("/user/cornerstone")
                        }
                      >
                        Cornerstone
                      </Button> */}


                      {showRefreshButton(this.props.deals) ? (
                        <Button
                          className="ml-2"
                          primary
                          onClick={this.onReload}
                          size="small"
                          style={{ width: "90px" }}
                          loading={this.state.reload}
                        >
                          Refresh
                        </Button>
                      ) : null}
                      <br />
                      {!isInvestor && (
                        <strong style={{ fontSize: "17px" }}>
                          If you want access to All deals you will need to open
                          an Investor account.
                        </strong>
                      )}
                    </div>

                    <BidNowModal
                      title="Bid Now"
                      showEditForm={this.state.showEditForm}
                      visible={this.state.show}
                      editBidHandler={this.editBidHandler}
                      handleCancel={this.handleCancel}
                      currentUser={currentUser}
                      onSubmitBid={this.onSubmitBid}
                      selectEntityOptions={this.state.selectEntityOptions}
                      currentDeal={currentDeal}
                    />
                  </div>
                </div>
                {isInvestor && (
                  <Tabs
                    defaultActiveKey={
                      this.props.location.hash === "#currentBids"
                        ? "3"
                        : this.props.location.hash === "#confirmation"
                        ? "4"
                        : "1"
                    }
                    onChange={this.callback}
                  >
                    <TabPane
                      tab={<span className="nav-link-top ">Live Deals</span>}
                      key="1"
                    >
                      <Deals180
                        {...this.props}
                        dealAccessDenialMessage={dealAccessDenialMessage}
                        isEnitityExist={isEnitityExist}
                        onBidNow={this.onBidNow}
                        deals={
                          this.props.deals &&
                          this.props.deals.filter(
                            (deal) =>
                              deal.status === "Active" ||
                              deal.status === "Finished"
                          )
                        }
                      />
                    </TabPane>
                    <TabPane
                      tab={<span className="nav-link-top ">Closed Deals</span>}
                      key="2"
                    >
                      <ClosedDeals />
                    </TabPane>
                    <TabPane
                      tab={<span className="nav-link-top ">Current Bids</span>}
                      key="3"
                    >
                      <CurrentBid
                        {...this.props}
                        deals={
                          this.props.currentDeals && this.props.currentDeals
                        }
                      />
                    </TabPane>
                    <TabPane
                      tab={<span className="nav-link-top ">Allocations</span>}
                      key="4"
                    >
                      <Confirmation deals={allocations} />
                    </TabPane>
                  </Tabs>
                )}
              </div>
            </div>
          </Loader>
        </main>
      </Fragment>
    );
  }
}
const {
  fetchDEALS,
  fetchCurrentDeals,
  fetchAllPublic180Deals,
  fetchAllPublicMarketDeals,
  getAllAllocations,
  getSingleDeal,
} = DealActions;
const { fetchEntities } = EntityActions;
const {
  getAllIpos,
  fetchSelectedIpo,
  submitBid,
  editIpoBid,
  submitIndication,
  editIpoIndication,
} = IpoActions;
let { createBid, updateBid, createIndication, updateIndication } = BidActions;

const mapDispatchToProps = (dispatch) => ({
  fetchAllEntities: (payload, history) =>
    fetchEntities({ dispatch, payload, history }),
  fetchAllDEALS: (payload, history) =>
    fetchDEALS({ dispatch, payload, history }),
  getCurrentBids: (payload, history) =>
    fetchCurrentDeals({ dispatch, payload, history }),
  fetchAllPublic180Deals: (payload, history) =>
    fetchAllPublic180Deals({ dispatch, payload, history }),
  fetchAllPublicMarketDeals: (payload, history) =>
    fetchAllPublicMarketDeals({ dispatch, payload, history }),
  getAllAllocationsData: () => getAllAllocations(dispatch),
  getAllIposData: () => getAllIpos(dispatch),
  getDealStatus: (payload) => getSingleDeal(payload, dispatch),
  createBidSubmit: (payload, history) => createBid(payload, dispatch, history),
  createIndicationSubmit: (payload, history) =>
    createIndication(payload, dispatch, history),
  updateBidSubmit: (payload, history) => updateBid(payload, dispatch, history),
  fetchSelectedIpoData: (uuid, history) => fetchSelectedIpo(uuid, dispatch),
  submitIpoBid: (payload, history) => submitBid(payload, history, dispatch),
  updateIpoBid: (payload, history) => editIpoBid(payload, history, dispatch),
  updateIndicationSubmit: (payload, history) =>
    updateIndication(payload, dispatch, history),
  submitIpoIndication: (payload, history) =>
    submitIndication(payload, history, dispatch),
  updateIpoIndication: (payload, history) =>
    editIpoIndication(payload, history, dispatch),
});

const mapStateToProps = (state) => {
  return {
    currentDeal: state.deals.bidStatus,
    currentUser: state.auth.currentUser,
    deals: state.deals.deals,
    allocations: state.deals.allAllocations,
    currentDeals: state.deals.currentDeals,
    public180Deals: state.deals.public180Deals,
    loading: state.deals.loading,
    publicMarketDeals: state.deals.publicMarketDeals,
    allIpos: state.ipos.ipos,
    existingEntity: state.entities.entities,
    ipo: state.ipos.selectedIpo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealsPage);
