import React, { Component } from "react";
import NewsletterForm from "./NewsletterForm/NewsletterForm";
import newsLetterActions from "../../../redux/actions/newsLetterActions";
import { connect } from "react-redux";

class NewsletterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  newsLetterSubmitHandler = async (values) => {
    const { subscribe } = this.props;

    await subscribe(values);
  };

  render() {
    return <NewsletterForm onSubmit={this.newsLetterSubmitHandler} />;
  }
}

const { subscribeNewsletter } = newsLetterActions;

const mapDispatchToProps = (dispatch) => ({
  subscribe: (data, history) => subscribeNewsletter(dispatch, data, history),
});

export default connect(null, mapDispatchToProps)(NewsletterContainer);
