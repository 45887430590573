export function scrollToFirstError(errors) {
  let el;

  for (let key in errors) {
    if (typeof errors[key] === "object") {
      for (let m in errors[key]) {
        for (let n in { ...errors[key][m] }) {
          if (typeof errors[key][m][n] === "string") {
            const firstError = Object.keys({ ...errors[key][m] })[0];

            el = document.querySelector(`[name="${key}.${m}.${firstError}"]`);
          } else if (typeof errors[key][m][n] === "object") {
            const firstError = Object.keys({ ...errors[key][m][n] })[0];

            el = document.querySelector(
              `[name="${key}[${m}][${n}].[${firstError}]"]`
            );
          }
        }
      }
    } else {
      const firstError = Object.keys(errors)[0];
      el = document.querySelector(`[name="${firstError}"]`);
    }

    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }
}
