import React, { Component } from "react";
import { connect } from "react-redux";
import InvestorForm from "../../forms/InvestorForm";
import {
  registerUser,
  registerFor,
  changePendingMessage
} from "../../../redux/actions/authActions";
import RegisterType from "./RegisterType";
import BrokerForm from "../../forms/BrokerForm";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploaded: []
    };
  }

  componentWillUnmount = () => {
    let { changePendingMessageStatus } = this.props;
    changePendingMessageStatus();
  };

  onLogoUpload = files => {
    this.setState({ uploaded: files });
  };

  investorRegisterHandler = values => {
    const { history } = this.props;
    this.props.register({ ...values, role: "Investor" }, history);
  };

  brokerRegisterHandler = values => {
    const { history } = this.props;
    let { uploaded } = this.state;
    if (uploaded) {
      values.logo = uploaded;
    }
    this.props.register({ ...values, role: "Broker" }, history);
  };

  companyRegisterHandler = values => {
    const { history } = this.props;
    let { uploaded } = this.state;
    if (uploaded) {
      values.logo = uploaded;
    }
    this.props.register({ ...values, role: "Company" }, history);
  };

  render() {
    let { match, regState } = this.props;

    switch (match.path) {
      case "/auth/register/investor":
        return <InvestorForm onSubmit={this.investorRegisterHandler} />;
      case "/auth/register/broker":
        return (
          <BrokerForm
            onSubmit={this.brokerRegisterHandler}
            onLogoUpload={this.onLogoUpload}
            registerState={regState}
            role={"Broker"}
          />
        );

      case "/auth/register/company":
        return (
          <BrokerForm
            onSubmit={this.companyRegisterHandler}
            onLogoUpload={this.onLogoUpload}
            registerState={regState}
            role={"Company"}
          />
        );

      default:
        return <RegisterType onSelect={this.props.registerFor} />;
    }
  }
}

const mapStateToProps = state => ({
  optedType: state.register.optedType,
  regState: state.register
});

const mapDispatchToProps = dispatch => ({
  register: (payload, history) => registerUser(dispatch, payload, history),
  registerFor: payload => registerFor(dispatch, payload),
  changePendingMessageStatus: () => dispatch(changePendingMessage())
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
