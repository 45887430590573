import React, {Component} from "react";


export default class PrivacyPolicy extends Component {
    render() {
        return (
            <main class="page-main">
                <div class="bl-about">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="text-about">
                                    <h1>{`Privacy Policy`}</h1>
                                    <div class="excerpt">

                                        <ol>
                                            <li><strong>Introduction<br/><br/></strong>
                                                <ol>
                                                    <li>180 Markets PTY LTD  ACN 638 381 129 ('<strong>180 Markets</strong>', '<strong>Company</strong>',
                                                        '<strong>we</strong>', '<strong>us</strong>',
                                                        '<strong>our, 180 Real Estate</strong>') respects the privacy rights of persons
                                                        with whom we dea 180 Markets is committed to complying with
                                                        all applicable privacy laws including the <em>Privacy Act 1988
                                                            (Cth)</em> (the '<strong>Privacy Act'</strong>) and the
                                                        Australian Privacy Principles (the '<strong>APPs'</strong>).
                                                    </li>
                                                    <li>The Company is related to other companies and entities
                                                        ('<strong>related entities</strong>'). This Privacy Policy
                                                        applies to all Personal Information collected by the Company and
                                                        those related entities. Those related entities may also have
                                                        their own privacy policies which set out additional detail or
                                                        differences in their privacy practices. To the extent that those
                                                        privacy policies are inconsistent with this Privacy Policy,
                                                        those privacy policies will prevail over this Privacy Policy in
                                                        relation to the actions of those related entities. A reference
                                                        in this Privacy Policy to '<strong>180 Markets</strong>', '<strong>Company</strong>',
                                                        '<strong>we</strong>', '<strong>us</strong>' or
                                                        '<strong>our, 180 Real Estate</strong>' is also a reference to those related
                                                        entities.
                                                    </li>
                                                    <li>Personal Information may be collected by us and by our third
                                                        party service providers who assist us in operating our website,
                                                        including its subdomains and any other website we operate from
                                                        time to time (collectively, the '<strong>Website</strong>'). The
                                                        Website is a platform for viewing and bidding on ASX listed
                                                        investment opportunities and the provision of related services
                                                        ('the <strong>Services</strong>').
                                                    </li>
                                                    <li>All natural persons or other entities who view or log on to this
                                                        Website are considered users of this Website. For convenience,
                                                        the users are referred to as '<strong>you</strong>'.   <br/><br/>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li><strong>Personal information<br/><br/> </strong>
                                                <ol>
                                                    <li>'Personal Information' is information or an opinion, whether
                                                        true or not, and whether recorded in a material form or not,
                                                        about an identified individual or an individual who is
                                                        reasonably identifiable.   <br/><br/>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li><strong>Purposes of this Privacy Policy<br/><br/></strong>
                                                <ol>
                                                    <li>The purpose of this Privacy Policy is to inform you about the
                                                        kinds of Personal Information that we may collect and retain
                                                        about you by your use of this Website and the Services, how we
                                                        collect, retain, use and disclose that Personal Information,
                                                        choices you have regarding our use of that Personal Information,
                                                        and your ability to access or correct that Personal Information.   <br/><br/>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li><strong>Your consent<br/><br/></strong>
                                                <ol>
                                                    <li>To ensure that you have full confidence in our handling of
                                                        Personal Information, you are advised to read and understand the
                                                        terms of this Privacy Policy in detail. In particular, when you
                                                        read, browse or download information from our Website or use the
                                                        Services, regardless of whether you register with this Website,
                                                        you will be deemed to have provided your consent to the
                                                        following:

                                                        <ol>
                                                            <li>you consent to us using your Personal Information as set
                                                                out in this Privacy Policy;
                                                            </li>
                                                            <li>you consent to the collection, use, storage and
                                                                processing of your Personal Information through the use
                                                                of our Website, your registration with this Website, and
                                                                your use of the Services offered by us;
                                                            </li>
                                                            <li>you agree to any changes and modifications that we may
                                                                make to our Privacy Policy in the future; and
                                                            </li>
                                                            <li>you consent to our branches, affiliates and employees
                                                                using your Personal Information for sending you
                                                                information, including promotional material, and that
                                                                they may contact you in connection with our products and
                                                                services that you may be interested in, now and in the
                                                                future (unless you have indicated that you do not want
                                                                to receive such information). You also consent to us
                                                                sending you such information by means of direct mail,
                                                                email, SMS and MMS messages.   <br/><br/>
                                                            </li>
                                                        </ol>

                                                    </li>
                                                </ol>
                                            </li>

                                            <li><strong>What Personal Information do we collect?</strong>   <br/><br/>
                                                <ol>
                                                    <li>We only collect Personal Information necessary to manage our
                                                        relationship with you, administer the products and services you
                                                        request, notify you about our products and services and to
                                                        comply with applicable la Information collected is only used and
                                                        disclosed in a manner consistent with applicable laws and this
                                                        Privacy Policy.
                                                    </li>
                                                    <li>The Personal Information we may collect, hold and/or use about
                                                        you will depend on the type of product and service you Examples
                                                        of Personal Information we may collect include, but are not
                                                        limited to:

                                                        <ol>
                                                            <li>personal identification and contact details (including
                                                                name, postal or email address, telephone numbers and
                                                                date of birth);
                                                            </li>
                                                            <li>your IP address, location, analytics and remarketing
                                                                collected through the use of our Website;
                                                            </li>
                                                            <li>transaction history;</li>
                                                            <li>banking and payment details (including your bank account
                                                                details, brokerage account details, tax file number);
                                                                and
                                                            </li>
                                                            <li>any other information that we deem necessary.
                                                                <ol>
                                                                    <li>We will only collect, maintain and use Personal
                                                                        Information about you if it is necessary for us
                                                                        to adequately provide you the Services you have
                                                                        requested, provide you with information about
                                                                        other products and services offered by related
                                                                        entities or to meet any obligations we may have
                                                                        under applicable Laws (e.g. the <em>Anti-Money
                                                                            Laundering and Counter-Terrorism Financing
                                                                            Act (2006) (Cth)</em> (the '<strong>AML
                                                                            Act'</strong>)).
                                                                    </li>
                                                                    <li>We will at all times take reasonable steps to
                                                                        ensure that the Personal Information collected
                                                                        and held by us is protected from misuse, loss,
                                                                        unauthorised access and disc You have the right,
                                                                        where it is lawful and practicable, of remaining
                                                                        anonymous when entering into transactions with
                                                                        180 Markets. However, should you wish to
                                                                        remain anonymous, we may not be able to provide
                                                                        you with our Services.
                                                                    </li>
                                                                    <li>Where you provide us with unsolicited Personal
                                                                        Information which we do not require to deliver
                                                                        the Services to you, such information will be
                                                                        subject to our normal security arrangements for
                                                                        client information. Where reasonable to do so,
                                                                        we will not hold unsolicited Personal
                                                                        Information we do not require, however often we
                                                                        are unable to delete such information,
                                                                        particularly where it forms part of
                                                                        correspondence or other interactions that we do
                                                                        need to reta Deletion in such circumstances is
                                                                        generally not reasonable or practicable.
                                                                    </li>
                                                                    <li>Personal Information records are held for a
                                                                        period considered appropriate to provide you
                                                                        with the Services you require and consistent
                                                                        with applicable laws, including the Privacy Ac
                                                                    </li>
                                                                    <li>Should you cease to be a client of 180 Markets, any Personal Information which we hold
                                                                        about you will be maintained for the relevant
                                                                        periods required by applicable laws.   <br/><br/>
                                                                    </li>
                                                                </ol>
                                                            </li>
                                                        </ol>

                                                    </li>
                                                </ol>
                                            </li>

                                            <li><strong>Information collected via our Website</strong>   <br/><br/>
                                                <ol>
                                                    <li>We may use various technological methods from time to time to
                                                        track the visiting patterns of individuals accessing our
                                                        Website,
                                                        including but not limited to the methods set out below.
                                                        <br/><br/>

                                                        <strong>Click Stream Data</strong>

                                                    </li>

                                                    <li>When you visit our Website, our Website host and other third
                                                        parties engaged by us may collect Personal Information for
                                                        statistical, reporting and maintenance purposes.
                                                    </li>

                                                    <li>The information collected may include:
                                                        <ol>
                                                            <li>the number of users visiting our Website and the number
                                                                of pages viewed;
                                                            </li>
                                                            <li>the date, time and duration of a visit;</li>
                                                            <li>the IP address of your computer; or</li>
                                                            <li>the path taken through our Website.
                                                            </li>
                                                        </ol>
                                                    </li>

                                                    <li>Our Website host uses this information to administer and improve
                                                        the performance of our Website.
                                                        <br/><br/>

                                                        <strong>Cookies</strong>
                                                    </li>

                                                    <li>If you access our Website, we may collect Personal Information
                                                        electronically. For example, we may collect information about
                                                        your visits by using &lsquo;cookies&rsquo;. A cookie is a packet
                                                        of information which is sent by our Website to your computer to
                                                        track your use of our Website and to allow you to efficiently
                                                        access your account information. Generally, we use cookies for
                                                        security purposes and to assess the usage and improve the
                                                        functionality of our website.
                                                    </li>
                                                    <li>We use both session cookies and persistent cookies. A session
                                                        cookie is temporary and is automatically destroyed once your web
                                                        browser is closed. All of the transaction functions of our
                                                        Website rely on session cookies. These web pages will not work
                                                        if session cookies are explicitly blocked or disabled within a
                                                        web browser. A persistent cookie is a small text file stored on
                                                        the user&rsquo;s computer for an extended period of time.
                                                    </li>
                                                    <li>You can opt to set your browser to reject cookies, or to notify
                                                        you as soon as possible if you are loaded on cookies. If you
                                                        reject persistent cookies you will still be able to access our
                                                        Website, but may not be able to use certain features or the web
                                                        service may not recognise you.
                                                    </li>
                                                    <li>We take care to ensure that the Personal Information you give us
                                                        on our Website is protected. 180 Markets uses a range of
                                                        security measures to help ensure this security. Our Website
                                                        contains more detailed information on specific privacy and
                                                        security procedures.

                                                    <br/><br/>

                                                    <strong>Google Analytics</strong>

                                                    </li>

                                                    <li>We may use Google Analytics to help analyse how you use our Website.
                                                        Google Analytics generates statistical and other information about
                                                        website use by means of cookies, which are stored on users'
                                                        computers. The information generated is used to create reports about
                                                        the use of our Website. Google will store this information.
                                                    </li>
                                                    <li>If you do not want our Website visit data reported by Google
                                                        Analytics, you can install the Google Analytics opt-out browser
                                                        add-on. For more details on installing and uninstalling the add-on,
                                                        please visit the Google Analytics opt-out page at <a
                                                            href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>.

                                                      <br/><br/>
                                                      <strong>Third party content (e.g. social media links)</strong>

                                                    </li>



                                                    <li>Some of the content used on our Website may include applications
                                                        made available by third parties, such as social media buttons or
                                                        links that allow you to share content or links to our Website
                                                        through the relevant third party platforms. These third party
                                                        applications themselves may facilitate collection of information by
                                                        those third parties, through your interactions with the applications
                                                        and sometimes even if you do not interact directly with them. We are
                                                        not responsible for the technical operation of these applications or
                                                        the collection and use practices of the relevant third parties.
                                                        Please visit the relevant third party websites to understand their
                                                        privacy practices and options they may make available to you in
                                                        relation to their collection of your Personal Information.
                                                      <br/><br/>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li><strong>How do we collect and hold your Personal Information? <br/><br/></strong>
                                                <ol>
                                                    <li>In most cases, we collect your Personal Information directly
                                                        from you, online, in face to face meetings, application forms or
                                                        correspondence (written and verbal). However, if this is not
                                                        practical, we may collect information about you from another
                                                        person or entity &ndash; for example, receiving information from
                                                        a person to whom you have granted a delegated authority or power
                                                        of attorney.
                                                    </li>
                                                    <li>When you use this Website or the Services, we may collect more
                                                        information necessary in any manner that we consider as in
                                                        compliance with relevant requirements, for the purpose of
                                                        improving the functions of this Website or the Services,
                                                        enhancing your experience of using this Website and the Services
                                                        as well as the security thereof, or as is required by any court
                                                        order, any applicable law, administrative regulation or any
                                                        order of any other competent government agency.   <br/><br/>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li><strong>How do we use your Personal Information?</strong>   <br/><br/>
                                                <ol>
                                                    <li>We use your Personal Information that we collect about you to
                                                        primarily manage, deliver and administer the Services to you.
                                                        Personal Information may also be used so that we can offer
                                                        products and services to you including special offers (with your
                                                        consent, and subject to your right to unsubscribe or opt-out, as
                                                        described in paragraph 2 below) to extend our relationship with
                                                        you and to develop products and services better suited to your
                                                        needs.
                                                    </li>
                                                    <li>We also use your Personal Information for the following
                                                        purposes:
                                                        <ol>
                                                            <li>to identify and confirm your identity when you use our Services;</li>
                                                            <li>to handle/route your customer service or technical support questions or
                                                                issues;
                                                            </li>
                                                            <li>to help us manage our business operations;</li>
                                                            <li>for business support purposes, including maintenance, backup and
                                                                audit;
                                                            </li>
                                                            <li>to improve our customer service; and</li>
                                                            <li>to collect and process payments.
                                                            </li>
                                                        </ol>
                                                    </li>

                                                    <li>We may also use and analyse your information to meet our
                                                        obligations, and our service providers' obligations (if
                                                        applicable), under applicable laws, for example the Know Your
                                                        Customer requirements of the AML Act.
                                                    </li>
                                                    <li>We reserve the right at all times to monitor, review, retain or
                                                        disclose any information as necessary to satisfy any applicable
                                                        law, but we have no obligation to monitor the use of the Website
                                                        or to retain the content of any user session.
                                                    </li>
                                                    <li>We may occasionally hire other companies to provide services on
                                                        our behalf, including but not limited to handling customer
                                                        support enquiries and processing transactions. Those companies
                                                        will be permitted to obtain only the Personal Information they
                                                        need to deliver the Services. We take reasonable steps to ensure
                                                        that these organisations are bound by confidentiality and
                                                        privacy obligations in relation to the protection of your
                                                        Personal Information.
                                                    </li>
                                                    <li>We do not sell, trade or rent your Personal Information to
                                                        others. We may share generic aggregated demographic information
                                                        not linked to any Personal Information regarding visitors and
                                                        users with our business partners, trusted affiliates and
                                                        advertisers for the purposes outlined in paragraph 1 below.
                                                    </li>
                                                    <li>We may otherwise collect, use or disclose your Personal
                                                        Information where the collection, use or disclosure is in
                                                        accordance with this Privacy Policy or any agreement you enter
                                                        into with us, or required or authorised by law, including
                                                        without limitation the APPs under the Privacy Act.   <br/><br/>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li><strong>Who do we disclose information to?</strong>   <br/><br/>
                                                <ol>
                                                    <li>180 Markets will only disclose Personal Information to
                                                        third parties for the purposes of managing, delivering and
                                                        administering the Service you require and in accordance with
                                                        this Privacy Policy and applicable laws. For example, we may
                                                        disclose relevant Personal Information to:

                                                              <ol>
                                                                  <li>agents and brokers (intermediaries) to enable them to efficiently answer
                                                                      enquiries and process transactions;
                                                                  </li>
                                                                  <li>for legal reasons, disclosure may need to be made to law enforcement
                                                                      agencies, government agencies, courts or external advisers, for example
                                                                      to meet our obligations under the AML Act;
                                                                  </li>
                                                                  <li>a person acting on your behalf including a financial adviser, trustee,
                                                                      attorney or person to whom you have granted a delegated authority;
                                                                  </li>
                                                                  <li>service providers engaged to carry out functions on our behalf (e.g.
                                                                      other financial institutions as required, solicitors, software or IT
                                                                      service vendors and auditors/investigators);
                                                                  </li>
                                                                  <li>some of these service providers may be located oversea Your Personal
                                                                      Information will only be disclosed to one of our service providers, or
                                                                      stored overseas in accordance with this Privacy Policy (as set out in
                                                                      section 11 below entitled 'Disclosure of Personal Information overseas')
                                                                      and applicable laws;
                                                                  </li>
                                                                  <li>if you have acquired a product or service through a related entity, your
                                                                      Personal Information may be shared with that related entity on a
                                                                      confidential basis;
                                                                  </li>
                                                                  <li>your financial adviser/consultant who in turn may disclose information
                                                                      to superannuation funds, trustees, insurance providers and product
                                                                      issuers for the purpose of giving effect to your financial plan/strategy
                                                                      and recommendations made by them;
                                                                  </li>
                                                                  <li>if a financial adviser/consultant has referred you to our products and
                                                                      services then your details (including ongoing investment and transaction
                                                                      details) may be provided to that financial adviser/consultant, the
                                                                      dealer group, the broker or the organisation for whom they work; and
                                                                  </li>
                                                                  <li>other third parties with your consent or direction to do so. <br/><br/></li>
                                                              </ol>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li><strong>Third party websites</strong>   <br/><br/>
                                                <ol>
                                                    <li>You may find advertising or other content on our Website that
                                                        link to the websites of our partners, suppliers, advertisers,
                                                        sponsors, licensors and other third parties. We do not control
                                                        the content or links that appear on these websites and are not
                                                        responsible for the practices employed by websites linked to or
                                                        from our Website. In addition, these websites or services,
                                                        including their content and links, may be constantly changing.
                                                        These websites and services may have their own privacy policies
                                                        and customer service policies. Browsing and interacting on any
                                                        other website, including websites which have a link to our
                                                        Website, is subject to that website's own terms and policies.   <br/><br/>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li><strong>Disclosure of Personal Information overseas</strong>   <br/><br/>
                                                <ol>
                                                    <li>Some of your Personal Information may be disclosed, transferred,
                                                        stored, processed or used overseas by us, or by third party
                                                        service providers. This may happen if:

                                                        <ol>
                                                            <li>our offices are or related entities are located overseas;</li>
                                                            <li>we outsource certain activities overseas;</li>
                                                            <li>transactions, information, services or products have an overseas
                                                                connection; or
                                                            </li>
                                                            <li>our computer systems, IT services are located overseas.
                                                            </li>
                                                        </ol>
                                                    </li>
                                                    <li>You consent to the collection, use storage, and processing of
                                                        Personal Information outside of Australia as set out in this
                                                        Privacy Policy.
                                                    </li>
                                                    <li>In particular, your Personal Information may be disclosed to
                                                        third parties in such other countries in which those parties or
                                                        their, or our, computer systems may be located from time to
                                                        time, where it may be used for the purposes described in this
                                                        Privacy Policy. In these circumstances, you consent to the
                                                        collection, use, storage and processing of your Personal
                                                        Information in those countries, without us being responsible
                                                        under the Privacy Act for such use (or for any breach). Where
                                                        such parties are located overseas, you may have rights to
                                                        enforce such parties' compliance with the applicable data
                                                        protection laws, but you might not have recourse against those
                                                        parties under the Australian Privacy Act in relation to how
                                                        those parties treat your Personal Information. <br/><br/>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li><strong>Marketing</strong> <br/><br/>
                                                <ol>
                                                    <li>180 Markets may use your Personal Information for direct
                                                        marketing purposes in accordance with paragraph 4.
                                                    </li>
                                                    <li>If you do not want to receive marketing information from us, you
                                                        can unsubscribe in any of the following ways: <br/><br/>
                                                        <ol>
                                                            <li>clicking on the 'Unsubscribe' or subscription preferences link in a
                                                                direct marketing email that you have received from us;
                                                            </li>
                                                            <li>logging into your account on the Website and editing your communication
                                                                preferences; or
                                                            </li>
                                                            <li>contacting us using the contact details specified in section 15 <br/><br/></li>
                                                        </ol>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li><strong>How do we protect your Personal Information? <br/><br/></strong>
                                                <ol>
                                                    <li>We have systems and processes in place designed to hold your
                                                        information securely. Only authorised personnel are granted
                                                        access your information. We also have in place processes
                                                        designed to identify you when you deal with us by phone, online
                                                        or face to face. These processes are designed to ensure we only
                                                        disclose your information to you, or someone properly authorised
                                                        by you. You need to keep access details like user names and
                                                        passwords confidential and not share them or leave them
                                                        somewhere that is easy for others to access or find.
                                                    </li>
                                                    <li>The platform for our Services is built on international security
                                                        standards.
                                                    </li>
                                                    <li>Despite the above-mentioned technical and security measures, we
                                                        cannot guarantee that information transmitted via the Internet
                                                        is absolutely safe, so we cannot absolutely guarantee that the
                                                        Personal Information that you provide to us through our Website
                                                        or the use of the Services will be safe at all times. We will
                                                        not be held liable for any loss or damage arising from or caused
                                                        by any event that may occur in connection with unauthorised
                                                        access to your personal data, and we shall not be held liable in
                                                        compensation for such loss or damage. <br/><br/>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li><strong>Access and Correction</strong> <br/><br/>
                                                <ol>
                                                    <li>You have the right to correct your Personal Information held by
                                                        us if you believe it to be inaccurate or out of date. We will
                                                        then amend your records accordingly. If we disagree with the
                                                        correction, we will advise you of the reasons for doing so and
                                                        will make a note on your record of this.
                                                    </li>
                                                    <li>You are generally entitled to access your Personal Information.
                                                        This can be arranged so by calling or writing to our Privacy
                                                        Officer. The details of the Privacy Officer's contact
                                                        information are listed in the section 15 entitled 'Privacy
                                                        Enquiries and Complaints' below. Your request should include a
                                                        detailed description of the information required. To ensure
                                                        information is only disclosed to those entitled to it you may be
                                                        asked for identification, or in the case of a telephone call
                                                        asked to answer a series of questions to verify your identity.
                                                    </li>
                                                    <li>In some circumstances, there may be some legal or administrative
                                                        reasons to deny access to your Personal Information. If we are
                                                        unable to provide you with access to your information, we will
                                                        provide you with reasons for the refusal where we are required
                                                        by law to give those reasons. <br/><br/>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li><strong>Privacy Enquiries and Complaints</strong> <br/><br/>
                                                <ol>
                                                    <li>If you have any requests, comments, enquiries or complaints
                                                        about our information handling practices, please contact us by
                                                        calling 1300 373 522 or alternatively contact the Privacy Officer
                                                        (either via email or via mail):<br/><br/>

                                                        Privacy Officer<br/>
                                                        180 Markets Operating Pty Ltd<br/>
                                                        <a href="mailto:info@180realestate.com.au">info@180realestate.com.au</a><br/>
                                                        84 Glen Eira Road, Ripponlea<br/><br/>

                                                        You can also contact us if you believe that the privacy of your Personal
                                                        Information has been compromised or not adequately protected. <br/><br/>
                                                    </li>

                                                    <li>It is our intention to resolve any complaint as quickly as possible and
                                                        to your satisfaction. If you are not satisfied with the response
                                                        provided by us, please let us know and our Privacy Officer will
                                                        investigate further and respond to you. If you are still not satisfied
                                                        or if we do not respond to your complaint within 30 days, you can
                                                        contact external bodies that deal with privacy complaints such as the
                                                        Office of the Australian Information Commissioner. This body may forward
                                                        your complaint to another external dispute resolution body if it
                                                        considers the complaint would be better handled by that body.<br/><br/>


                                                      Office of the Australian Information Commissioner<br/>
                                                      GPO Box 5218<br/>Sydney NSW 2001<br/>Telephone: 1300 363 992<br/>Website: <a
                                                          href="http://www.oaic.gov.au">www.oaic.gov.au</a> <br/><br/>
                                                    </li>
                                            <li>This Privacy Policy is provided for the purposes of information only.
                                                While we have taken care to ensure that it is accurate and current, we
                                                provide no guarantee as to its accuracy or currency. We accept no
                                                liability for loss or damage suffered as a result of reliance on the
                                                information provided in this Privacy Policy. <br/><br/>
                                            </li>
                                                </ol>
                                            </li>

                                            <li><strong>Modification to the Privacy Policy <br/><br/></strong>
                                                <ol>
                                                    <li>We reserve the right to amend the Privacy Policy from time to
                                                        time and at any time. We will inform you of the modifications
                                                        made to the Privacy Policy by updating and publishing the
                                                        effective date of the release of new versions hereof. We may
                                                        issue a notice to inform you of any modifications made to the
                                                        Privacy Policy, but we are not obliged to do so.
                                                    </li>
                                                    <li>You are required to regularly review the Privacy Policy and
                                                        focus on its modifications, and if you do not agree to such
                                                        modifications, you shall immediately stop accessing this
                                                        Website. When an updated version of this Privacy Policy is
                                                        released, your continued visit to this Website will indicate and
                                                        show that you agree to the update and agree to comply with the
                                                        updated Privacy Policy. <br/><br/>
                                                    </li>
                                                </ol>
                                            </li>

                                        </ol>

                                        <p>This Privacy Policy was last updated on 1 February 2020.</p>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
