import React, { useEffect } from "react";
import { FormField } from "../../../../layout/FormField";
import { required } from "redux-form-validators";
import { Field, FieldArray } from "redux-form";
import { RenderDirectors } from "../RenderDirector/RenderDirector";

export const RenderCompany = ({ fields, prevProps }) => {
  const handleArray = (value) => {
    let quantity;
    if (fields.length < value) {
      quantity = value - fields.length;
      for (let i = 1; i <= quantity; i++) {
        fields.push({});
      }
    } else if (value && fields.length > value) {
      quantity = fields.length - value;
      for (let i = 1; i <= quantity; i++) {
        fields.pop({});
      }
    } else if (fields.length === value) {
      return;
    } else if (!value) {
      return fields.removeAll();
    }
  };

  useEffect(() => {
    if (
      prevProps.initialValues &&
      prevProps.initialValues.number_of_companies
    ) {
      let length = prevProps.initialValues.number_of_companies;
      if (!prevProps.initialValues.number_of_companies) {
        return handleArray;
      }

      handleArray(length);
    }
  }, []);

  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-6">
          <div className="field">
            <label className="title">Number of Companies *</label>
            <div class="field-input">
              <Field
                className="form-control entity__input"
                name="number_of_companies"
                component={FormField}
                type="number"
                min="1"
                onChange={(e) => handleArray(e.target.value)}
                validate={[required()]}
              />
            </div>
          </div>
        </div>
      </div>

      {fields.map((member, index) => (
        <div className="row mb-3" key={index}>
          <div className="col-md-12">
            <h2>Company {index + 1}</h2>
            <div className="row mb-3">
              <div className="col-sm-12">
                <div className="field">
                  <label className="title">Company Name *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[companies][${index}][company_name]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              {/*<div className="col-sm-6">*/}
              {/*  <div className="field">*/}
              {/*    <label className="title">*/}
              {/*      Account Designation (Optional)*/}
              {/*    </label>*/}
              {/*    <div class="field-input">*/}
              {/*      <Field*/}
              {/*        className="form-control entity__input"*/}
              {/*        type="text"*/}
              {/*        name={`beneficial[companies][${index}][account_designation]`}*/}
              {/*        component={FormField}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">TFN *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[companies][${index}][tfn]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Address *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      type="text"
                      name={`beneficial[companies][${index}][address]`}
                      component={FormField}
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Suburb *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[companies][${index}][suburb]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Postcode *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[companies][${index}][postcode]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">State *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[companies][${index}][state]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Country *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[companies][${index}][country]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">ABN *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[companies][${index}][abn]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Country of Incorporation *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[companies][${index}][country_of_incroporation]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-8">
                <div className="field">
                  <label className="title">Company Type *</label>
                  <div class="field-input form-group row mb-3">
                    {prevProps.companyType.map((element) => {
                      return (
                        <Field
                          name={`beneficial[companies][${index}][company_type]`}
                          component={FormField}
                          type="radio"
                          value={element}
                          label={element}
                          validate={[required()]}
                          className={`radio-inline`}
                        />
                      );
                    })}
                  </div>
                  {/* <p style={{ fontSize: "12px", color: "red " }}>
                  {this.state.validation &&
                    this.props.form.CompanyForm &&
                    this.props.form.CompanyForm.syncErrors &&
                    this.props.form.CompanyForm.syncErrors.company_type &&
                    `This field is  ${this.props.form.CompanyForm.syncErrors.company_type}`}
                </p> */}
                </div>
              </div>
            </div>
            <FieldArray
              name={`directors[${index}]`}
              component={RenderDirectors}
              companyindex={index}
              prevProps={prevProps}
              prefix={`beneficial[companies][${index}][directors]`}
              number_field_name={`beneficial[companies][${index}][number_of_directors]`}
              numberOfDirectors={
                prevProps.initialValues &&
                prevProps.initialValues.beneficial &&
                prevProps.initialValues.beneficial.companies &&
                prevProps.initialValues.beneficial.companies[index] &&
                prevProps.initialValues.beneficial.companies[index].directors
              }
              prevDirectors={
                prevProps.initialValues &&
                prevProps.initialValues.beneficial &&
                prevProps.initialValues.beneficial.companies &&
                prevProps.initialValues.beneficial.companies[index] &&
                prevProps.initialValues.beneficial.companies[index].directors
              }
              fileList={
                prevProps.initialValues &&
                prevProps.initialValues.beneficial &&
                prevProps.initialValues.beneficial.companies &&
                prevProps.initialValues.beneficial.companies[index] &&
                prevProps.initialValues.beneficial.companies[index].directors
              }
              fileName={`beneficial[companies][${index}][directors]`}
            />
          </div>
        </div>
      ))}
    </>
  );
};
