import React, { useEffect } from "react";
import { FormField } from "../../../../layout/FormField";
import { required } from "redux-form-validators";
import DateDropdown from "../../../../common/DateDropdown";
import { Field } from "redux-form";
import DraggerComponent from "../../../../common/DraggerComponent/DraggerComponent";
import NewDropzone from "../../../../common/NewDropzone";

export const RenderPersonal = ({ fields, prevProps }) => {
  const handleArray = (value) => {
    let quantity;
    if (fields.length < value) {
      quantity = value - fields.length;
      for (let i = 1; i <= quantity; i++) {
        fields.push({});
      }
    } else if (value && fields.length > value) {
      quantity = fields.length - value;
      for (let i = 1; i <= quantity; i++) {
        fields.pop({});
      }
    } else if (fields.length === value) {
      return;
    } else if (!value) {
      return fields.removeAll();
    }
  };

  useEffect(() => {
    if (
      prevProps.initialValues &&
      prevProps.initialValues.number_of_personals
    ) {
      let length = prevProps.initialValues.number_of_personals;

      if (!prevProps.initialValues.number_of_personals) {
        return handleArray;
      }
      handleArray(length);
    }
  }, []);

  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-6">
          <div className="field">
            <label className="title">Number of Personals *</label>
            <div class="field-input">
              <Field
                className="form-control entity__input"
                name="number_of_personals"
                component={FormField}
                type="number"
                min="1"
                onChange={(e) => handleArray(e.target.value)}
                validate={[required()]}
              />
            </div>
          </div>
        </div>
      </div>

      {fields.map((member, index) => (
        <div className="row mb-3" key={index}>
          <div className="col-md-12">
            <h2>Personal {index + 1}</h2>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Full Name *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[individuals][${index}].[full_name]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">DOB *</label>
                  <div class="field-input">
                    <Field
                      selectedDate={prevProps.handleIndividaulDob}
                      label="Date of Birth"
                      id="dob"
                      name={`beneficial[individuals][${index}][dob]`}
                      autoComplete="off"
                      autoCorrect="off"
                      spellCheck="off"
                      component={DateDropdown}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Address *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[individuals][${index}].[address]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Suburb *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      type="text"
                      name={`beneficial[individuals][${index}].[suburb]`}
                      component={FormField}
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Postcode *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[individuals][${index}].[postcode]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">State *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[individuals][${index}].[state]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Country *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[individuals][${index}].[country]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Country of Birth *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[individuals][${index}].[country_of_birth]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Country of Citizenship *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[individuals][${index}].[country_of_citizenship]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Tax file number*</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`beneficial[individuals][${index}].[tfn]`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-12">
                <div className="field">
                  <label className="title">ID Verification documents *</label>
                  <div className="field">
                    Acceptable forms of ID include: Australian passport,
                    Australian Drivers License, Birth certificate
                  </div>
                  <div class="field-input">
                    {/* <DraggerComponent
                      fileList={
                        prevProps.initialValues &&
                        prevProps.initialValues.beneficial.individuals[index] &&
                        prevProps.verification[index].verification
                      }
                      onDocumentDeleteHandler={
                        prevProps.onVerificationDeleteHandler
                      }
                      name={`beneficial[individuals][${index}][verification]`}
                      handleIdUpload={prevProps.handleIdUpload}
                    /> */}
                    <NewDropzone
                      multiple={true}
                      buttonLabel="Choose document"
                      title={""}
                      multiDocuments={
                        prevProps.initialValues &&
                        prevProps.initialValues.beneficial.individuals[index] &&
                        prevProps.verification[index].verification
                      }
                      onUpload={(files) =>
                        prevProps.handleIdUpload({
                          [`beneficial[individuals][${index}][verification]`]: files,
                        })
                      }
                      onDocumentDeleteHandler={
                        prevProps.onVerificationDeleteHandler
                      }
                      open={prevProps.open}
                      close={prevProps.close}
                      show={prevProps.show}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
