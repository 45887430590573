import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Icon } from "semantic-ui-react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const toolbar = {
  options: [
    "inline",
    // "blockType",
    // "fontSize",
    // "fontFamily",
    // "list",
    // "textAlign",
    // "colorPicker",
    "link",
    // "embedded",
    // "emoji",
    // "image",
    // "remove",
    // "history"
  ],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: [
      "bold",
      "italic",
      "underline",
      // "strikethrough",
      // "monospace",
      // "superscript",
      // "subscript"
    ],
    bold: <Icon loading name="bold" />,
    italic: <Icon loading name="italic" />,
    underline: <Icon loading name="underline" />,
    // strikethrough: <Icon loading name="strikethrough" />,
    // monospace: <Icon loading name="monospace" />,
    // superscript: <Icon loading name="superscript" />,
    // subscript: <Icon loading name="subscript" />
  },
  //   blockType: {
  //     inDropdown: true,
  //     options: [
  //       "Normal",
  //       "H1",
  //       "H2",
  //       "H3",
  //       "H4",
  //       "H5",
  //       "H6",
  //       "Blockquote",
  //       "Code"
  //     ],
  //     className: undefined,
  //     component: undefined,
  //     dropdownClassName: undefined
  //   },
  //   fontSize: {
  //     icon: <Icon loading name="fontSize" />,
  //     options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
  //     className: undefined,
  //     component: undefined,
  //     dropdownClassName: undefined
  //   },
  //   fontFamily: {
  //     options: [
  //       "Arial",
  //       "Georgia",
  //       "Impact",
  //       "Tahoma",
  //       "Times New Roman",
  //       "Verdana"
  //     ],
  //     className: undefined,
  //     component: undefined,
  //     dropdownClassName: undefined
  //   },
  //   list: {
  //     inDropdown: false,
  //     className: undefined,
  //     component: undefined,
  //     dropdownClassName: undefined,
  //     options: ["unordered", "ordered", "indent", "outdent"],
  //     unordered: <Icon loading name="unordered" />,
  //     ordered: <Icon loading name="ordered" />,
  //     indent: <Icon loading name="indent" />,
  //     outdent: <Icon loading name="outdent" />
  //   },
  //   textAlign: {
  //     inDropdown: false,
  //     className: undefined,
  //     component: undefined,
  //     dropdownClassName: undefined,
  //     options: ["left", "center", "right", "justify"],
  //     left: <Icon loading name="left" />,
  //     center: <Icon loading name="center" />,
  //     right: <Icon loading name="right" />,
  //     justify: <Icon loading name="justify" />
  //   },
  //   colorPicker: <Icon loading name="color" />,
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: "_self",
    options: ["link", "unlink"],
    link: <Icon loading name="link" />,
    unlink: <Icon loading name="unlink" />,
    linkCallback: undefined,
  },
  //   emoji: <Icon loading name="emoji" />,
  //   embedded: <Icon loading name="embedded" />,

  //   image: <Icon loading name="image" />,
  //   remove: <Icon loading name="eraser" />,

  //   history: {
  //     inDropdown: false,
  //     className: undefined,
  //     component: undefined,
  //     dropdownClassName: undefined,
  //     options: ["undo", "redo"],
  //     undo: <Icon loading name="undo" />,
  //     redo: <Icon loading name="redo" />
  //   }
};

class DraftComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      contentState: {},
    };
  }

  onEditorStateChange = (editorState) => {
    const { getValue } = this.props;
    this.setState({
      editorState,
    });

    getValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.initialValue !== nextProps.initialValue) {
      if (nextProps.initialValue) {
        const blocksFromHtml = htmlToDraft(nextProps.initialValue);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
          editorState,
        });
      }
    }
  };

  render() {
    const { editorState, contentState } = this.state;
    return (
      <div style={{ background: "#F4F5F6" }}>
        <Editor
          initialContentState={contentState}
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          toolbar={toolbar}
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}

export default DraftComponent;
