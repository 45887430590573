import React, { Component } from "react";
import { connect } from "react-redux";
import {
  clearWarning,
  clearNotification,
  loginUser,
} from "../../../redux/actions/authActions";
import Alert from "../../common/Alert";
import LoginForm from "../../forms/LoginForm";
import Message from "../../common/Message";
import { Redirect } from "react-router-dom";

class Login extends Component {
  clearNotification = () => {
    this.props.clearNotification();
  };

  loginHandler = (values) => {
    this.props.loginUser(values, this.props.history);
  };

  render() {
    const { notification, currentUser, profilePending } = this.props;
    const { history } = this.props;
    if (!!Object.keys(currentUser).length) history.push("/user/deals");

    return (
      <main className="page-main page-signup page-login-account mt-4">
        {/* {profilePending ? (
          <Redirect to="/auth/email-pending-message" />
        ) : ( */}
        <div className="form-normal form-login-account" style={{marginTop: 30}}>
          {this.props.location.search === "?redirect=/user/deals" ? (
            <h2>You must login to view deals</h2>
          ) : (
            <h2>Login</h2>
          )}
          <Alert
            type="success"
            message={notification}
            action={this.clearNotification}
          />
          <LoginForm onSubmit={this.loginHandler} />
        </div>
        {/* )} */}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  notification: state.auth.notification,
  loginError: state.auth.loginError,
  profilePending: state.register.profilePending,
});

const mapDispatchToProps = (dispatch) => ({
  clearNotification: () => dispatch(clearNotification),
  clearWarning: () => dispatch(clearWarning),
  loginUser: (payload, history) => loginUser(dispatch, payload, history),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
