import WebinarService from "../../services/webinarService";
import { toast } from "../../components/common/Toast";
// import { startButtonLoading, stopButtonLoading } from "./loadingActions";

const actions = {
  GET_ALL_WEBINARS: "GET_ALL_WEBINARS",

  getAllWebinarsData: (dispatch, payload) => {
    return dispatch({
      type: actions.GET_ALL_WEBINARS,
      payload,
    });
  },

  getAllWebinars: (dispatch) =>
    WebinarService.getWebinars()
      .then((res) => {
        dispatch(actions.getAllWebinarsData(dispatch, res));
      })
      .catch((error) => {
        if (error) toast.error("Something went wrong.");
      }),
};

export default actions;
