import { message } from "antd";
import account from "../../services/accountService";
import { toast } from "../../components/common/Toast";
import { getCurrentUser } from "./authActions";

const actionsTypes = {
  DELETE_MEDIA: "DELETE_MEDIA",
};

export const deleteMediaSuccess = (dispatch, payload) => {
  account
    .deleteMedia(payload)
    .then(async (data) => {
      await message.success("Document successfully deleted.");

      dispatch(() => getCurrentUser(dispatch));
    })
    .catch((error) => {
      toast.error("Something went wrong");
    });
};
