import React, { Component } from "react";
import { connect } from "react-redux";
import { updateSinglePreference } from "../../../redux/actions/profileActions";

class InvestorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { updateSinglePreference, match } = this.props;
    updateSinglePreference({
      realestate_notifications: "No Emails",
      uuid: match.params.uuid,
    });
  }

  render() {
    return (
      <div className="page-create-account investor-account investor-account-01">
        <div className="form-normal form-create-account p-100 text-center">
          <h1>You Have Been Unsubscribed</h1>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateSinglePreference: (payload) =>
    updateSinglePreference(dispatch, payload),
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorProfile);
