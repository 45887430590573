import React, { Fragment } from "react";
import Dropdown from "./Dropdown";

const AssetsDropdown = ({ label, data, onChange, ...rest }) => (
  <Fragment>
    <label className="hl-title">{label}</label>
    <Dropdown data={data} handleChange={onChange} {...rest} />
  </Fragment>
);

export default AssetsDropdown;
