import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { FormField } from "../layout/FormField";
import { required, email, length } from "redux-form-validators";
import { Field, reduxForm, Form } from "redux-form";
import ButtonLoader from "../common/ButtonLoader";
import { minLength } from "../../utils/customValidators";

class LoginForm extends Component {
  state = {
    password: false,
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <div className="all-fields">
          <div className="field">
            <label className="title">Email</label>
            <div className="field-input">
              <Field
                className="form-control entity__input"
                type="text"
                name="email"
                component={FormField}
                validate={[required(), email()]}
              />
            </div>
          </div>
          <div className="field hl-margin">
            <label className="title">Password</label>
            <div className="field-input">
              <Field
                className="form-control entity__input"
                name="password"
                component={FormField}
                type={this.state.password ? "text" : "password"}
                validate={[required(), length({ min: 6 })]}
              />
              <span
                className={`btn-toggle ${!this.state.password ? "hide" : ""}`}
                onClick={(e) => {
                  this.setState({ password: !this.state.password });
                }}
              ></span>
            </div>
            <div className="text">
              <p>
                <Link to="/auth/forgot-password">Forgot password?</Link>
              </p>
            </div>
          </div>
          <div className="field hl-margin">
            <div className="is-checkbox">
              <div className="ui checkbox">
                <input type="checkbox" name="example" />
                <label className="title title-checkbox">Remember me</label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="button-group">
            <div className="col-sm-12">
              <ButtonLoader className="btn btn-primary btn-medium">
                Login
              </ButtonLoader>
            </div>
          </div>
        </div>
        <div className="text text-bottom">
          <p>
            Don’t have an account?{" "}
            <Link to="/auth/register/investor" params={{ testvalue: "hello" }}>
              Registration
            </Link>
          </p>
        </div>
      </Form>
    );
  }
}

export default reduxForm({ form: "LoginForm" })(withRouter(LoginForm));
