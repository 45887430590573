import CounterStoneService from "../../services/counterStoneService";
import { toast } from "../../components/common/Toast";
import { reset } from "redux-form";

const actions = {
  POST_COUNTER_STONE: "POST_COUNTER_STONE",
  POST_SUGGESTION: "POST_SUGGESTION",

  //   getAllWebinarsData: (dispatch, payload) => {
  //     return dispatch({
  //       type: actions.GET_ALL_WEBINARS,
  //       payload,
  //     });
  //   },

  postCounterStone: (dispatch, data, history) =>
    CounterStoneService.postCounterStone(data)
      .then((res) => {
        toast.success("Cornerstone Successfully Submitted.");
        dispatch(reset("CornerStoneForm"));
        // dispatch(actions.getAllWebinarsData(dispatch, res));
      })
      .catch((error) => {
        if (error) toast.error("Something went wrong.");
      }),

  postSuggestion: (dispatch, data, history) =>
    CounterStoneService.postSuggestion(data)
      .then((res) => {
        toast.success("Suggestion Successfully Submitted.");
        dispatch(reset("SuggestionsForm"));
        // dispatch(actions.getAllWebinarsData(dispatch, res));
      })
      .catch((error) => {
        if (error) toast.error("Something went wrong.");
      }),
};

export default actions;
