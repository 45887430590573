import React, { useState } from "react";
import { Spin } from "antd";

const Loader = ({ children }) => {
  const [load, startLoad] = useState(false);
  return (
    <div>
      {load ? (
        <Spin tip="Loading..." size="large">
          {children}
        </Spin>
      ) : (
        children
      )}
    </div>
  );
};

export default Loader;
