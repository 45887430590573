import actionTypes from "../actions/assetActions";

const initialState = {
  assets: [],
  loading: false
};

const asset = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.FETCH_ALL_ASSETS_SUCCESS:
      return {
        ...state,
        assets: payload
      };
    default:
      return state;
  }
};

export default asset;
