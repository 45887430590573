import React from "react";
import Logo from "../../assets/images/180R-logo.png";
import { LogoUnlocked, UserIcon } from "../common/Graphics";
import { Link } from "react-router-dom";
import { MenuOutline } from "@ant-design/icons";
import { Icon } from "semantic-ui-react";
import { CloseOutlined } from "@ant-design/icons";

const BrandLogo = ({ auth = true }) => (
  <div className="logo">
    <Link to={"/"}>
      <img src={auth ? LogoUnlocked : Logo} alt="Home" className="logo" />
    </Link>
  </div>
);

const Search = () => {
  return (
    <form>
      <div className="field-input">
        <input type="text" className="form-control" />
        <button className="btn-search"></button>
      </div>
    </form>
  );
};

const UserNotification = () => (
  <div className="notification">
    <div className="toggle-notification">
      <span></span>
    </div>
  </div>
);

const LiveDealsButton = ({ handleClick }) => {
  return (
    <Link
      to="/user/deals"
      onClick={handleClick}
      className="btn btn-outline-primary liveDeals__button"
    >
      LIVE DEALS
    </Link>
  );
};

const LoginButton = () => {
  return (
    <Link to="/auth/login" className="btn btn-outline-primary">
      Login
    </Link>
  );
};
const UpgradeAccountButton = () => {
  return (
    <Link
      to="/user/profile"
      className="btn btn-outline-primary float-left mr-4"
    >
      Upgrade account
    </Link>
  );
};

const MenuToggle = (props) => {
  return (
    <>
      {!props.visible ? (
        <div
          className="toggle-menu"
          onClick={() => {
            props.changeSidebar();
            props.handleClick();
          }}
        >
          {/* <Icon
        type={!props.showMobileMenu ? "menu" : "close"}
        style={{ fontSize: "20px", verticalAlign: "1.875em !important" }}
      /> */}
          <div class="icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      ) : (
        <div
          className="toggle-menu"
          onClick={() => {
            props.changeSidebar();
            props.handleClick();
          }}
        >
          {/* <Icon
  type={!props.showMobileMenu ? "menu" : "close"}
  style={{ fontSize: "20px", verticalAlign: "1.875em !important" }}
/> */}
          <div className="sidebar__closeIcon">
            <span className="sidebar__close">
              {/* <Icon name="close" className="sidebar__closeIcon" /> */}
              <CloseOutlined name="close" className="sidebar__closeIcon" />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

const Avator = ({ url = null, name, showUserMenu }) => {
  return (
    <div className="avatar" onClick={() => showUserMenu()}>
      <img src={url ? url : UserIcon} alt={name} />
    </div>
  );
};

export {
  BrandLogo,
  Search,
  LoginButton,
  MenuToggle,
  UserNotification,
  Avator,
  UpgradeAccountButton,
  LiveDealsButton,
};
